import classNames from "classnames";
import { HTMLProps, useCallback, useEffect, useState } from "react";
import { AnswerQuestion } from "../redux/reduxSlice/answerGPTSlice";
import { Keys } from "../styles/keys";

interface Options {
  items: unknown[];
  role?: string;
  ref: React.RefObject<HTMLElement>;
  listBoxRef: React.RefObject<HTMLElement>;
  onKeyDown: (highlightedIndex: number, key: Keys) => void;
  onMouseClick?: (highlightedIndex: number, question?: AnswerQuestion) => void;
}

interface Result {
  highlighted: number;
  setHighlighted: (newIdx: number) => void;
  getListBoxProps: () => HTMLProps<HTMLDivElement>;
  getListItemProps: (
    idx: number,
    question?: AnswerQuestion,
    className?: string
  ) => HTMLProps<HTMLDivElement>;
}

function useArrowDown(opts: Options): Result {
  const { items, ref, listBoxRef, onKeyDown, onMouseClick, role } = opts;

  const [highlighted, setHighlighted] = useState(0);

  const totalItems = items.length;

  const setIndex = useCallback(
    (newIndex: number): void => {
      if (newIndex < 0) {
        newIndex = totalItems - 1;
      } else if (newIndex > totalItems - 1) {
        newIndex = 0;
      }

      setHighlighted(newIndex);
    },
    [totalItems]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      const key = e.key;

      if (key === Keys.ARROW_DOWN) {
        e.preventDefault();
        setIndex(highlighted + 1);
        onKeyDown(highlighted, Keys.ARROW_DOWN);
      }

      if (key === Keys.ARROW_UP) {
        e.preventDefault();
        setIndex(highlighted - 1);
        onKeyDown(highlighted, Keys.ARROW_UP);
      }

      if (key === Keys.ENTER) {
        onKeyDown(highlighted, Keys.ENTER);
      }
    },
    [highlighted, onKeyDown, setIndex]
  );

  const handleOnClick = (idx: number, question?: AnswerQuestion): void => {
    setIndex(idx);
    if (onMouseClick) onMouseClick(idx, question);
  };

  useEffect(() => {
    if (!ref.current) return;

    const elem = ref.current;
    elem.addEventListener("keydown", handleKeyDown);

    return () => {
      elem.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, ref]);

  // scroll to new highlighted item on change.
  useEffect(() => {
    const option = listBoxRef.current?.querySelector(
      `#option-${highlighted}`
    ) as HTMLDivElement | undefined;

    option?.scrollIntoView({ block: "nearest" });
  }, [highlighted, listBoxRef]);

  return {
    highlighted,
    setHighlighted: setIndex,
    getListBoxProps: () => ({
      role: "listbox",
      tabIndex: 0
    }),
    getListItemProps: (idx, question, className) => ({
      id: `option-${idx}`,
      tabIndex: -1,
      role: role ?? "option",
      "aria-selected": highlighted === idx,
      className: classNames("option", className, {
        highlighted: highlighted === idx
      }),
      "data-option-index": idx,
      onClick: () => {
        handleOnClick(idx, question);
      }
    })
  };
}

export { useArrowDown };
