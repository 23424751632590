import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { useHighlightByOffset } from "@aptedge/lib-ui/src/hooks/useHighlightByOffset";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateAnswer,
  updateAnswerAttempt,
  updateAnswerLoadingState,
  updateAnswerLoadingStartTimeMillis,
  updateAnswerCardVisibility,
  updateIsAnswerLoaded,
  updateSuggestedFollowupQuestions
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  setIsSearchLoading,
  updateSearchCardVisibility,
  updateSearchResultOffsets,
  addSearchResults,
  updateSearchResultsExpanded,
  updateSearchResultsInfo,
  updateTotalSearchResults,
  updateSelectedSearchResultsQuestionText
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  GQADocSearchResult,
  ICompositeResult,
  QUERY_PARAMS,
  SearchFilterSubType,
  ISearchResultSortingModes,
  ISearchFilter,
  ApiAction,
  ApiAnswer,
  ApiQuestion
} from "@aptedge/lib-ui/src/types/entities";
import { ConfigResponse } from "@aptedge/lib-ui/src/types/selfService";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { UseInfiniteQueryResult } from "react-query";
import {
  actionToString,
  responseToDisplayAnswer,
  triggerQuestionAskedEvent,
  triggerSearchEvent
} from "../utils/answerQuery";
import { useAiAnswerQueryCall } from "./useAiAnswerQueryCall";

export interface UseAiAnswerQueryReturnType {
  gptAnswer: UseInfiniteQueryResult<TimedResponse<GQADocSearchResult>>;
  refetchAnswer: () => void;
}

type makeAnswerAPICallArgs = {
  q?: string;
  page?: number;
  perPage: number;
  limit: number;
  sort?: ISearchResultSortingModes;
  filter?: ISearchFilter;
  filterSubType?: SearchFilterSubType;
  format: string;
  additionalInstructions: string;
  answerId?: string;
  dropExternalId?: string;
  quickFilters?: string;
  productFilters?: string;
  // the backend detects whether this field is set to true and samples all search requests in
  // Sentry
  firstRequest: boolean;
  pageHtml?: string;
  previousPageHtml?: string;
  nextStep?: Record<string, unknown>;
  plan: string | null;
  answerSteps?: Record<string, unknown>[];
  previews?: boolean;
  answerMode: string;
};

// WARNING: This function can only be safely invoked once per answer component. (All of our apps
// currently have one answer component, so this is fine.) Otherwise, multiple onSuccess() callbacks
// are registered to the same underlying query, which causes duplicate PostHog events to be
// triggered. The "right" way to do this *was* to register a global onSuccess() callback in
// QueryClient, but doing so doesn't invoke the callback for this query for some reason. Also,
// react-query has deprecated that global callback
// (https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose). So I think the way to fix it is
// to call useAiAnswerQuery() high enough in the component stack that we can simply pass its return
// values anywhere they're needed. That's basically what we have as of this writing, but future
// users should be warned that it is not safe to consider this a singleton.
export const useAiAnswerQueryWithResults = (
  enabled: boolean,
  makeAnswerAPICall: (
    args: makeAnswerAPICallArgs
  ) => Promise<TimedResponse<GQADocSearchResult>>,
  answerMode: string,
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false,
  highlightOffsets = true
): UseAiAnswerQueryReturnType => {
  const dispatch = useAppDispatch();
  const { removeQueryParams } = useQueryParams();
  const { user } = useAppSelector((state) => state.ssApp);
  const {
    aiModel,
    answerLoadingStartTimeMillis,
    answerAttempt
  } = useAppSelector((state) => state.answerGPT);

  const { flags } = useFlags();

  const {
    searchQuery,
    searchFilter,
    searchFilterSubType,
    page,
    pageHtml,
    sortMode,
    searchResults,
    totalSearchResults,
    answerId,
    autoSearchActive,
    productFilters,
    quickFilters,
    initialSearchQuery
  } = useAppSelector((state) => state.search);

  const perPage = 30;

  const productFiltersSerialized = productFilters?.length
    ? productFilters.map((pf) => pf.name).join(",")
    : "";
  const quickFiltersSerialized = quickFilters?.length
    ? quickFilters.map((qf) => qf.name).join(",")
    : "";

  const { getHighlightedContentByOffset } = useHighlightByOffset();
  const createCacheKey = (): string[] => {
    // TODO: useMemo() can technically expire its own cache, which would cause a duplicate query
    return [
      CACHE_KEY.COMPOSITE_SEARCH_AI,
      searchQuery,
      searchFilter,
      searchFilterSubType,
      sortMode,
      aiModel,
      answerAttempt.toString(),
      page.toString(),
      answerId,
      answerMode,
      productFiltersSerialized,
      quickFiltersSerialized
    ];
  };

  const createSearchParams = (
    pageParam: number,
    answerRequested: boolean
  ): makeAnswerAPICallArgs => {
    const q = !pageParam ? searchQuery : undefined;

    return {
      q,
      page: page,
      limit: 500,
      filter: searchFilter,
      filterSubType: searchFilterSubType,
      perPage: perPage,
      sort: sortMode,
      format: aiModel,
      plan: null,
      answerId: answerRequested ? answerId : undefined,
      additionalInstructions: "",
      firstRequest: !pageParam && initialSearchQuery,
      previews: true,
      pageHtml: !pageParam ? pageHtml : undefined,
      answerMode: "",
      productFilters: productFiltersSerialized,
      quickFilters: quickFiltersSerialized
    };
  };

  const onSearchStarted = (answerRequested: boolean): void => {
    dispatch(setIsSearchLoading(true));
    dispatch(updateAnswerLoadingState(answerRequested));
    dispatch(updateIsAnswerLoaded(!answerRequested));
    dispatch(updateSuggestedFollowupQuestions([]));
  };

  const onAnswerStarted = (): void => {
    dispatch(updateAnswerLoadingStartTimeMillis(Date.now()));
    if (!flags.unifiedUi) {
      dispatch(updateAnswerCardVisibility(true));
      dispatch(updateSearchCardVisibility(false));
    }
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
  };

  const handleSearchResultsWithOffset = (items: ICompositeResult[]): void => {
    const searchResultOffsets = items.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result: any) => result.offset
    );
    dispatch(updateSearchResultOffsets(searchResultOffsets));
    const newItems = items.map((item: ICompositeResult) =>
      highlightOffsets ? getHighlightedContentByOffset(item) : item
    );
    dispatch(updateSearchResultsExpanded(newItems));
    dispatch(addSearchResults(newItems));
  };

  const onSearchResultsReceived = (
    lastPage: TimedResponse<GQADocSearchResult>
  ): void => {
    const {
      data: { items, total, timers }
    } = lastPage;

    dispatch(setIsSearchLoading(false));
    dispatch(updateSearchResultsInfo(lastPage.data));
    handleSearchResultsWithOffset(items);
    dispatch(updateTotalSearchResults(total));
    triggerSearchEvent(
      lastPage,
      searchQuery,
      searchFilterSubType,
      page,
      timers,
      autoSearchActive,
      cssConfig,
      isProactiveAnswer,
      user
    );
  };

  const getIsMessageLoading = (answer: ApiAnswer): boolean => {
    let isMessageLoading = true;
    if (answer) {
      const { questions } = answer;

      questions.forEach((question: ApiQuestion) => {
        const { actions } = question;
        isMessageLoading = actions.length === 0;

        for (let i = 0; i < actions.length; i++) {
          const action = actions[i];
          const answerText = actionToString(action);

          const done = action.status === "SUCCESS" || action.status === "ERROR";
          if (!done && i === actions.length - 1) {
            isMessageLoading = isMessageLoading || !answerText;
          }
        }
      });
    }

    return isMessageLoading;
  };

  const onAnswerUpdated = (answer: ApiAnswer): void => {
    const answerQuestions = responseToDisplayAnswer(answer);
    const isMessageLoading = getIsMessageLoading(answer);
    dispatch(updateAnswer(answerQuestions));
    dispatch(updateAnswerLoadingState(isMessageLoading));
  };

  const onAnswerFinished = (answer: ApiAnswer): void => {
    const answerQuestions = responseToDisplayAnswer(answer);

    triggerQuestionAskedEvent(
      searchQuery,
      answerQuestions,
      searchResults,
      totalSearchResults,
      answerLoadingStartTimeMillis,
      answerId,
      answer.timers,
      user,
      cssConfig,
      isProactiveAnswer,
      flags.limitedAnswerRetention
    );
    dispatch(updateIsAnswerLoaded(true));

    let followupQuestions: string[] = [];
    if (answer) {
      const { questions } = answer;
      questions.forEach((question: ApiQuestion, index: number) => {
        const { actions } = question;
        if (index === questions.length - 1)
          dispatch(updateSelectedSearchResultsQuestionText(actions[0].query));

        actions.forEach((action: ApiAction) => {
          followupQuestions = action.content.followups ?? [];
        });
      });
    }

    if (followupQuestions) {
      dispatch(updateSuggestedFollowupQuestions(followupQuestions));
    }
    dispatch(setIsSearchLoading(false));
  };

  const refetchAnswer = (): void => {
    dispatch(updateAnswerAttempt(answerAttempt + 1));
  };

  const gptAnswer = useAiAnswerQueryCall(
    enabled,
    answerId,
    true,
    makeAnswerAPICall,
    createCacheKey,
    createSearchParams,
    onSearchStarted,
    onAnswerStarted,
    onSearchResultsReceived,
    onAnswerUpdated,
    onAnswerFinished
  );

  return { gptAnswer, refetchAnswer };
};

export type { makeAnswerAPICallArgs };
