import {
  IIssueInfo,
  SEARCH_RESULT_TYPE
} from "@aptedge/lib-ui/src/types/entities";
import { useQuery, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchIssueById } from "../../clients/InternalIssueSources/fetchIssueById";

function useIssueQuery(
  issueId?: string,
  sourceType?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): UseQueryResult<IIssueInfo> {
  return useQuery(
    [WebCacheKey.ISSUE_JIRA_COMMENT, issueId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetchIssueById(issueId!),
    {
      enabled: !!issueId && sourceType === SEARCH_RESULT_TYPE.ISSUE
    }
  );
}

export { useIssueQuery };
