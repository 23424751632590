import {
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  useTheme,
  Button,
  Popper,
  PopperPlacementType,
  Badge
} from "@mui/material";
import {
  KeyboardEvent,
  ReactNode,
  SyntheticEvent,
  useRef,
  useState
} from "react";
import { SubMenuButton } from "./components/SubMenuButton";

type Props = {
  title: string;
  startIcon: ReactNode;
  endIcon?: ReactNode;
  placement?: PopperPlacementType;
  multiLevel?: boolean;
  children: ReactNode;
  isSelected?: boolean;
};

export const MultiLevelDropdown = ({
  title,
  startIcon,
  endIcon,
  placement,
  multiLevel,
  children,
  isSelected
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();

  const handleToggle = (): void => setOpen((prev) => !prev);
  const handleClose = (event: Event | SyntheticEvent): void => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;
    setOpen(false);
  };

  const handleListKeyDown = (event: KeyboardEvent<HTMLUListElement>): void => {
    if (event.key === "Tab" || event.key === "Escape") setOpen(false);
  };

  return (
    <>
      {multiLevel ? (
        <SubMenuButton
          handleToggle={handleToggle}
          startIcon={startIcon}
          endIcon={endIcon}
          title={title}
          ref={anchorRef}
        />
      ) : (
        <Badge
          color="warning"
          variant="dot"
          invisible={!isSelected}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": {
              top: "50%", // Move to vertical center
              right: "-10%",
              transform: "translate(-50%, -50%)"
            },
            "&:hover .material-symbols-outlined": {
              color: `${theme.palette.slate.slate80} !important`
            }
          }}
        >
          <Button
            onClick={handleToggle}
            ref={anchorRef}
            startIcon={startIcon}
            variant="text"
            sx={{
              fontSize: theme.typography.body2
            }}
            disableFocusRipple
          >
            {title}
          </Button>
        </Badge>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={placement || "bottom-start"}
        transition
        modifiers={[{ name: "offset", options: { offset: [0, 20] } }]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper
              sx={{
                background: theme.palette.slate.slate00,
                borderRadius: 0.5,
                border: "1px solid rgba(0, 0, 0, 0.1)"
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onKeyDown={handleListKeyDown}>{children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
