import { MenuItem, useTheme, styled } from "@mui/material";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
import { FC, ReactNode } from "react";

export type SelectMenuType = {
  id: string;
  value: string;
  icon: ReactNode;
};

type Props = {
  item: SelectMenuType;
  onSelect: (filter: SelectMenuType, subFilter?: SelectMenuType) => void;
};

const StyledMenuItem = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.slate.slate40,
  transition: "color 0.3s",
  "&:hover .text": {
    color: theme.palette.slate.slate80
  },
  "&:hover .checkBox": {
    color: `${theme.palette.slate.slate80} !important`
  },
  "&:hover .material-symbols-outlined": {
    color: `${theme.palette.slate.slate80} !important`
  }
}));
export const SelectMenu: FC<Props> = ({ item, onSelect }) => {
  const theme = useTheme();
  const onMenuItemClick = (): void => {
    onSelect(item);
  };

  return (
    <MenuItem
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`
      }}
    >
      <StyledMenuItem
        direction="row"
        alignItems="center"
        onClick={onMenuItemClick}
      >
        {typeof item.icon === "string" ? (
          <img src={item.icon} alt={item.id} width={16} />
        ) : (
          item.icon
        )}
        <Typography
          variant="body2"
          className="text"
          color={theme.palette.slate.slate40}
          sx={{ margin: "0 1rem" }}
        >
          {item.value}
        </Typography>
      </StyledMenuItem>
    </MenuItem>
  );
};
