import { GPTContext } from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/GPTContext";
import { WelcomePage } from "@aptedge/lib-ui/src/features/WelcomePage/WelcomePage";
import { UnifiedRoutes } from "@aptedge/lib-ui/src/routes/unifiedRoutes";
import { Card } from "@mui/material";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import useGlobalSearch from "../../../../../app-web/src/pages/GlobalSearch/hooks/useGlobalSearch";
import AiAnswer from "../../AiAnswer/components/AiAnswer/AiAnswer";
import SearchResultDrawer from "../../SearchResultDrawer/SearchResultDrawer";
import { Feed } from "../Feed/Feed";
import styles from "./styles.module.scss";

const SearchResultPage: FC = () => {
  const {
    refetchAnswer,
    userData,
    isLoading,
    isDrawerOpen,
    toggleDrawer,
    showFeedback,
    searchResultsArrowNavigation,
    searchResults,
    queryFromSelectedQuestion,
    searchQuery
  } = useGlobalSearch();

  if (!searchQuery) return <Redirect to={UnifiedRoutes.HOME} />;
  if (isLoading) return <Spinner />;

  if (!userData?.onboardingComplete) return <WelcomePage />;
  return (
    <>
      <Helmet>
        <title>Search Result page</title>
      </Helmet>
      <GPTContext.Provider value={{ refetchAnswer }}>
        <div className={styles.container}>
          <Feed>
            <AiAnswer
              questionWrapperComponent={Card}
              questionQueryTitleClass={styles.questionQueryTitle}
              showFirstQueryTitle={true}
              hideHeader={true}
              hideSuggestedQuestions={true}
              showQuestionFooter={true}
              showSearchResult={true}
              scrollAnswerIntoView={true}
              containerClass={styles.aiAnswerContainer}
              refetchAnswer={refetchAnswer}
              toggleDrawer={toggleDrawer}
            />
          </Feed>
        </div>
      </GPTContext.Provider>
      <SearchResultDrawer
        isDrawerOpen={isDrawerOpen}
        searchResultsArrowNavigation={searchResultsArrowNavigation}
        showFeedback={showFeedback}
        toggleDrawer={toggleDrawer}
        searchQuery={queryFromSelectedQuestion}
        maxResultLength={searchResults?.length}
      />
    </>
  );
};

export default SearchResultPage;
