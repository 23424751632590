import { useQuery } from "react-query";
import { fetchFilters } from "../../../../app-web/src/clients/AptAnswers/fetchFilters";
import { WebCacheKey } from "../../../../app-web/src/clients/cache";
import { fetchMyProducts } from "../../../../app-web/src/clients/MyProducts/fetchMyProducts";
import { useAppDispatch } from "../../redux/hook/hook";
import { updateMyProducts } from "../../redux/reduxSlice/searchSlice";
import {
  AptAnswersContextType,
  AptAnswersFilterType,
  AptAnswersFiltersResponse,
  AptAnswersSearchContextItem
} from "../../types/entities";

function getEntriesFromFilterObject(
  data: AptAnswersFiltersResponse | undefined,
  filterType: AptAnswersFilterType
): AptAnswersSearchContextItem[] {
  const filterData = data?.[filterType] ?? {};
  const filterItems = Object.entries(filterData).map((item) => {
    const name = item[0];
    const id = item[1][0]["id"];
    return {
      id,
      name
    };
  });
  return filterItems;
}

type useAptAnswerFilterProps = {
  isEmpty: boolean;
  isError: boolean;
  isLoading: boolean;
  items: AptAnswersSearchContextItem[];
};

const useAptAnswerFilter = (
  contextType: AptAnswersContextType
): useAptAnswerFilterProps => {
  const dispatch = useAppDispatch();

  const filterType =
    contextType === AptAnswersContextType.PRODUCT_FILTER
      ? AptAnswersFilterType.PRODUCT_FILTER
      : AptAnswersFilterType.QUICK_FILTER;

  const filtersQuery = useQuery(
    [WebCacheKey.APT_ANSWERS_FILTERS, filterType],
    () => fetchFilters({ filterType }),
    {
      refetchOnWindowFocus: false
    }
  );

  const isError = filtersQuery.isError;
  const isLoading = filtersQuery.isLoading;
  const items = getEntriesFromFilterObject(filtersQuery.data, filterType);
  const isEmpty = items.length === 0;

  useQuery([WebCacheKey.APT_ANSWERS_MY_PRODUCTS], () => fetchMyProducts(), {
    enabled: filterType === AptAnswersFilterType.PRODUCT_FILTER,
    staleTime: Infinity,
    onSuccess: (data) => {
      if (filterType === AptAnswersFilterType.PRODUCT_FILTER) {
        const myProductIds = data.filterIds.productFilters;
        if (myProductIds.length) {
          const selectedFilter = items.filter((filterItem) =>
            myProductIds.includes(filterItem.id)
          );
          dispatch(updateMyProducts(selectedFilter));
        }
      }
    }
  });

  return {
    isEmpty,
    isError,
    isLoading,
    items
  };
};

export default useAptAnswerFilter;
