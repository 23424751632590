import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateIsRuleExpanded } from "@aptedge/lib-ui/src/redux/reduxSlice/aptAnswerSlice";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import { isEmpty } from "lodash";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useMutation, useQueryClient } from "react-query";
import { createFilter } from "../../clients/AptAnswers/createFilter";
import { updateFilter } from "../../clients/AptAnswers/updateFilter";
import { WebCacheKey } from "../../clients/cache";
import { useAptAnswersFilters } from "../../hooks/useAptAnswersFilters";
import { AptAnswersFilters } from "./AptAnswersFilters";
import styles from "./FilterForm.module.scss";
import { UnsavedModal } from "./UnsavedModal";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";
import { getFilterRulesForPayload } from "./utils/getFilterRulesForPayload";
import { mergeFiltersWithIntegrations } from "./utils/mergeFiltersWithIntegrations";

interface FilterFormProps {
  filterType: AptAnswersFilterType;
  filterName: string;
  handleCloseModal: () => void;
}

const FilterForm: FC<FilterFormProps> = ({
  filterType,
  filterName,
  handleCloseModal
}) => {
  const { isRuleExpanded } = useAppSelector((state) => state.aptAnswer);
  const dispatch = useAppDispatch();
  const { filterPurpose } = getAptAnswersFilterInfo(filterType);
  const [filterNameVal, setFilterNameVal] = useState<string>(filterName);

  const { filtersData, integrationsData } = useAptAnswersFilters(filterType);

  const integrations = useMemo(() => {
    return mergeFiltersWithIntegrations({
      filterName,
      filtersData,
      integrationsData,
      filterPurpose
    });
  }, [filterName, filtersData, integrationsData, filterPurpose]);

  const filterFormRef = useRef<HTMLDivElement>(null);
  const [integrationValues, setIntegrationValues] = useState(integrations);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hasCustomFieldsToggled, setHasCustomFieldsToggled] = useState(false);

  useEffect(() => {
    // filtersData can be {}, which will always cause a re-render in this useEffect
    // Even useMemo doesn't work, so we check to make sure that filtersData is
    // not an empty object before updating
    if (
      integrations &&
      (integrationValues.length === 0 || Object.keys(filtersData).length !== 0)
    ) {
      setIntegrationValues(integrations);
    }
    if (Boolean(filterNameVal) && !Boolean(filterName)) {
      setHasCustomFieldsToggled(true);
    }
  }, [
    integrations,
    filtersData,
    integrationValues,
    setIntegrationValues,
    filterName,
    filterNameVal
  ]);
  // Extract to a utility
  const headerLabel = filterName
    ? filterName
    : `New ${filterType === "quick-filter" ? "quick" : "product"} filter`;
  const inputLabel = `${
    filterType === "quick-filter" ? "Filter" : "Product"
  } name`;
  const inputPlaceholder = `Please enter ${
    filterType === "quick-filter" ? "filter" : "product"
  } name`;
  const description = `Specify the knowledge that applies to this 
   ${filterType === "quick-filter" ? "filter" : "product"}`;
  const actionsDescription = `Provide a name and a rule for this ${
    filterType === "quick-filter" ? "quick" : "product"
  } filter`;
  const saveButtonLabel = `Save ${
    filterType === "quick-filter" ? "quick" : "product"
  } filter`;

  const isFilterNameEmpty = isEmpty(filterNameVal);

  const hasValidFilterRule = integrationValues.some(
    (integration) =>
      integration.filter?.filterRules &&
      integration.filter.filterRules.some(
        (rule) => rule.value && rule.value.length > 0
      )
  );

  const isManageAllSelected = integrationValues.some(
    (integration) => integration.filter?.manageAllSelected === true
  );

  const shouldDisableOrHide =
    isFilterNameEmpty || (!hasValidFilterRule && !isManageAllSelected);

  const handleRuleCollapse = (): void => {
    const newRuleExpanded = { ...isRuleExpanded };
    Object.keys(newRuleExpanded).map((rule) => (newRuleExpanded[rule] = false));
    dispatch(updateIsRuleExpanded(newRuleExpanded));
  };

  const queryClient = useQueryClient();
  const resetOnMutation = useCallback(() => {
    queryClient.invalidateQueries([
      WebCacheKey.APT_ANSWERS_FILTERS,
      filterType
    ]);
    setIntegrationValues(integrations);
  }, [queryClient, filterType, integrations]);
  const createFilterMutation = useMutation(createFilter, {
    onSuccess: () => {
      resetOnMutation();
      handleRuleCollapse();
      handleCloseModal();
    }
  });
  const updateFilterMutation = useMutation(updateFilter, {
    onSuccess: () => {
      resetOnMutation();
      handleRuleCollapse();
      handleCloseModal();
    }
  });

  const resetForm = (): void => {
    resetOnMutation();
    handleRuleCollapse();
    handleCloseModal();
  };

  const handleSave = (): void => {
    if (shouldDisableOrHide) {
      resetForm();
      return;
    }
    const payload = integrationValues
      .map((integration) => {
        const filterRules = getFilterRulesForPayload({
          filterPurpose,
          integration
        });
        return {
          ...(integration?.filter?.id && { id: integration?.filter?.id }),
          name: filterNameVal,
          filterType,
          filterRules
        };
      })
      .filter((integration) => {
        return integration.filterRules.length !== 0 || integration.id;
      });

    const isUpdate = payload.some((integration) => integration.id);
    if (payload.length === 0) return;
    if (isUpdate) {
      updateFilterMutation.mutate({ filters: payload });
    } else {
      createFilterMutation.mutate({ filters: payload });
    }
  };

  const handleFilterNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFilterNameVal(e.target.value);
  };

  const handleCancel = (): void => {
    resetForm();
  };

  const handleSaveClick = (): void => {
    handleSave();
  };

  const handleRedirection = (): void => {
    if (hasCustomFieldsToggled) {
      setShowConfirmationModal(true);
    } else {
      resetForm();
    }
  };

  useOnMouseDownOutside(filterFormRef, handleRedirection);

  return (
    <div className={styles.container} ref={filterFormRef}>
      <div className={styles.headerContainer}>
        <span className={styles.filterIcon}>
          <AeIcons.ProductFiltersSelected />
        </span>
        <span className={styles.headerLabel}>{headerLabel}</span>
      </div>
      <div className={styles.inputContainer}>
        <label className={styles.inputLabel}>{inputLabel}</label>
        <input
          className={styles.nameInput}
          value={filterNameVal}
          onChange={handleFilterNameChange}
          placeholder={inputPlaceholder}
          data-testid="filter-name"
        />
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.filterDescription}>{description}</div>
        <AptAnswersFilters
          filterType={filterType}
          integrations={integrationValues}
          setIntegrationValues={setIntegrationValues}
          setHasCustomFieldsToggled={setHasCustomFieldsToggled}
          setShowConfirmationModal={setShowConfirmationModal}
          handleRuleCollapse={handleRuleCollapse}
        />
      </div>
      <div className={styles.actionsContainer}>
        {shouldDisableOrHide && (
          <div
            className={styles.actionsDescription}
            data-testid="filter-message"
          >
            {actionsDescription}
          </div>
        )}
        <div className={styles.actionButtons}>
          <button
            className={styles.saveButton}
            disabled={shouldDisableOrHide}
            onClick={handleSaveClick}
            data-testid="save-filter"
          >
            {saveButtonLabel}
          </button>
          <button className={styles.cancelButton} onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
      {showConfirmationModal && (
        <UnsavedModal
          reset={resetForm}
          setHasCustomFieldsToggled={setHasCustomFieldsToggled}
          setShowConfirmationModal={setShowConfirmationModal}
          handleSave={handleSave}
          integrations={integrationValues}
          handleRuleCollapse={handleRuleCollapse}
        />
      )}
    </div>
  );
};

export { FilterForm };
