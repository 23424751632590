import {
  Popper,
  ClickAwayListener,
  Paper,
  MenuList,
  Badge,
  Button,
  Grow,
  Box
} from "@mui/material";
import {
  useState,
  useRef,
  ReactNode,
  FC,
  SyntheticEvent,
  KeyboardEvent
} from "react";
import AEIcon from "../../../../src/components/GMIcon/Icon";
import { DropdownItem } from "../../../../src/components/MultiLevelDropdown/components/DropdownItem";
import { SelectMenu } from "../../../../src/components/MultiLevelDropdown/components/SelectMenu";
import theme from "../../../../src/themeConfigs/muiTheme";
import {
  AptAnswersContextType,
  AptAnswersSearchContextItem
} from "../../../../src/types/entities";

interface AptAnswerFilterMenuProps {
  contextType: AptAnswersContextType;
  items: AptAnswersSearchContextItem[];
  enableSelectAll: boolean;
  selectedItems: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleItemClick: (item: any) => void;
  handleSelectAll: () => void;
  handleReset: () => void;
}

const AptAnswerFilterMenu: FC<AptAnswerFilterMenuProps> = ({
  contextType,
  items,
  enableSelectAll,
  selectedItems,
  handleItemClick,
  handleSelectAll,
  handleReset
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = (): void => setOpen((prev) => !prev);
  const handleClose = (event: Event | SyntheticEvent): void => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;
    setOpen(false);
  };

  const handleListKeyDown = (event: KeyboardEvent<HTMLUListElement>): void => {
    if (event.key === "Tab" || event.key === "Escape") setOpen(false);
  };

  const showSelectAll = items.length > 1 && enableSelectAll;

  const mappedItems = items.map((item: AptAnswersSearchContextItem) => ({
    id: item.id,
    value: item.name,
    icon: (
      <AEIcon
        name={
          contextType === AptAnswersContextType.PRODUCT_FILTER
            ? "inventory_2"
            : "instant_mix"
        }
        color={theme.palette.slate.slate40}
      />
    ) as ReactNode
  }));

  const allItem = {
    value: `All ${
      contextType === AptAnswersContextType.PRODUCT_FILTER
        ? "products"
        : "quick filters"
    }`,
    icon: (
      <AEIcon
        name={
          contextType === AptAnswersContextType.PRODUCT_FILTER
            ? "inventory_2"
            : "instant_mix"
        }
        color={theme.palette.slate.slate40}
      />
    ) as ReactNode
  };

  const resetItem = {
    value: "Reset sources",
    icon: (<AEIcon name="refresh" />) as ReactNode
  };

  return (
    <>
      <Badge
        color="warning"
        variant="dot"
        invisible={!Boolean(selectedItems.length)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiBadge-badge": {
            top: "50%",
            right: "-10%",
            transform: "translate(-50%, -50%)"
          },
          "&:hover .material-symbols-outlined": {
            color: `${theme.palette.slate.slate80} !important`
          }
        }}
      >
        <Button
          onClick={handleToggle}
          ref={anchorRef}
          startIcon={
            <AEIcon
              name={
                contextType === AptAnswersContextType.PRODUCT_FILTER
                  ? "inventory_2"
                  : "instant_mix"
              }
              color={theme.palette.slate.slate40}
            />
          }
          variant="text"
          sx={{
            fontSize: theme.typography.body2
          }}
          disableFocusRipple
        >
          {contextType === AptAnswersContextType.PRODUCT_FILTER
            ? "Products"
            : "Filters"}
        </Button>
      </Badge>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        modifiers={[{ name: "offset", options: { offset: [0, 20] } }]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper
              sx={{
                background: theme.palette.slate.slate00,
                borderRadius: 0.5,
                border: "1px solid rgba(0, 0, 0, 0.1)"
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onKeyDown={handleListKeyDown}>
                  {showSelectAll && (
                    <Box
                      sx={{
                        borderBottom: "1px solid #e5e5e5"
                      }}
                    >
                      <DropdownItem
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        item={allItem as any}
                        isSelected={items.length === selectedItems.length}
                        onSelect={handleSelectAll}
                      />
                    </Box>
                  )}
                  {mappedItems.map((item) => (
                    <DropdownItem
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      item={item as any}
                      isSelected={selectedItems.includes(item.id)}
                      onSelect={() => handleItemClick(item)}
                      key={item.id}
                    />
                  ))}
                  <SelectMenu
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    item={resetItem as any}
                    onSelect={handleReset}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export { AptAnswerFilterMenu };
