import { FC } from "react";
import styles from "./FilterActions.module.scss";
import { FilterAddRuleButton } from "./FilterAddRuleButton";

interface FilterActionsProps {
  handleAddRule: () => void;
  addRuleIsDisabled: boolean;
}

const FilterActions: FC<FilterActionsProps> = ({
  handleAddRule,
  addRuleIsDisabled
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        {addRuleIsDisabled && (
          <FilterAddRuleButton handleAddRule={handleAddRule} />
        )}
      </div>
    </div>
  );
};

export { FilterActions };
