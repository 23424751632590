import { useHighlightByOffset } from "@aptedge/lib-ui/src/hooks/useHighlightByOffset";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  SEARCH_RESULT_TYPE,
  TemplateLocation
} from "@aptedge/lib-ui/src/types/entities";
import { Skeleton } from "@mui/material";
import { FC } from "react";
import { useQuery } from "react-query";
import { Toast } from "../../../../src/components/Toast/Toast";
import { WebCacheKey } from "../../../clients/cache";
import { fetchTemplates } from "../../../clients/Templates/fetchTemplates";
import { useIssueQuery } from "../../../hooks/clients/useIssue";
import { useSocialQuery } from "../../../hooks/clients/useSocial";
import { useTicketQuery } from "../../../hooks/clients/useTicket";
import useRenderSearchPreview from "./useRenderSearchPreview";
import useSearchResult from "./useSearchResult";

const PreviewDetails: FC = () => {
  const {
    description,
    originalId,
    type,
    sourceType,
    summary,
    url
  } = useSearchResult();
  const { prepareHighlightSearchResult } = useHighlightByOffset();
  const { isPreviewDataLoading } = useAppSelector((state) => state.search);

  const {
    data: ticket,
    isError: ticketIsError,
    isLoading: ticketIsLoading
  } = useTicketQuery(
    false,
    Number(originalId),
    type,
    false,
    prepareHighlightSearchResult
  );

  const {
    data: social,
    isError: socialIsError,
    isLoading: socialIsLoading
  } = useSocialQuery(String(originalId), type, prepareHighlightSearchResult);

  const {
    data: issue,
    isError: issueIsError,
    isLoading: issueIsLoading
  } = useIssueQuery(String(originalId), type);

  const fetchTemplatesClient = useQuery(WebCacheKey.TEMPLATES, fetchTemplates);
  const template = fetchTemplatesClient.data?.find(
    (t) => t.location === TemplateLocation.TICKET_DESCRIPTION
  );

  const {
    renderMultiSourceTypeElement,
    renderMarkdownElement,
    renderJiraElement,
    renderTicketElement,
    renderDocumentElement,
    renderSocialElement
  } = useRenderSearchPreview(
    description,
    summary,
    url,
    template,
    ticketIsLoading,
    socialIsLoading,
    issueIsLoading,
    ticket,
    social,
    issue
  );

  let renderElement;
  switch (type) {
    case SEARCH_RESULT_TYPE.TICKET:
      renderElement = renderTicketElement;
      break;
    case SEARCH_RESULT_TYPE.EDGE:
      renderElement = renderMarkdownElement;
      break;
    case SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE:
      renderElement = renderMarkdownElement;
      break;
    case SEARCH_RESULT_TYPE.DOCUMENT:
      renderElement = renderDocumentElement;
      break;
    case SEARCH_RESULT_TYPE.ISSUE:
      if (sourceType === "jira") {
        renderElement = renderJiraElement;
      } else {
        renderElement = renderMultiSourceTypeElement;
      }
      break;
    case SEARCH_RESULT_TYPE.SOCIAL:
      renderElement = renderSocialElement;
      break;
    default:
      renderElement = renderMultiSourceTypeElement;
      break;
  }

  const isError = ticketIsError || socialIsError || issueIsError;

  return (
    <div>
      {!isPreviewDataLoading ? (
        renderElement
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton height={200} className="loader-body" />
        </>
      )}
      {isError && <Toast type="error">Something went wrong.</Toast>}
    </div>
  );
};

export default PreviewDetails;
