import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../routes/routes";
import "./AnalyticsNav.scss";

const AnalyticsNav: React.FC = () => {
  const { flags } = useFlags();

  return (
    <nav className="nav navbar settings sticky-top">
      <div className="container-fluid">
        <nav className="nav navbar">
          <NavLink className="nav-item mr-4" exact to={Routes.ANALYTICS}>
            Usage
          </NavLink>
          {flags.integrationsAnalytics && (
            <NavLink
              className="nav-item mr-4"
              exact
              to={Routes.ANALYTICS_INTEGRATIONS}
            >
              Integrations
            </NavLink>
          )}
        </nav>
      </div>
    </nav>
  );
};

export { AnalyticsNav };
