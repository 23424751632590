import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { IOktaSource } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";
import "./OktaConfigForm.scss";

export interface OktaFormData {
  oktaDomain: string;
  oktaClientId: string;
  oktaSecretKey: string;
}

interface Props {
  dataSource: IOktaSource | null;
  onSubmit: (formData: OktaFormData) => void;
}

const OktaConfigForm: React.FunctionComponent<Props> = ({
  dataSource,
  onSubmit
}) => {
  const formik = useFormik<OktaFormData>({
    validationSchema: Yup.object({
      oktaDomain: Yup.string().required("Please enter your Okta Domain."),
      oktaClientId: Yup.string().required("Please enter Okta App Client ID."),
      oktaSecretKey: Yup.string().required("Please enter Okta Secret Key.")
    }),
    initialValues: {
      oktaDomain: dataSource?.oktaDomain ?? "",
      oktaClientId: dataSource?.oktaClientId ?? "",
      oktaSecretKey: dataSource?.oktaSecretKey ?? ""
    },
    validateOnChange: false,
    onSubmit: onSubmit
  });

  return (
    <Form className="okta-config-form" onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="oktaDomain">
          Okta Account Domain
        </Label>
        <Col sm={9}>
          <Input
            data-testid="oktaDomain"
            type="text"
            name="oktaDomain"
            placeholder=""
            value={formik.values.oktaDomain}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oktaDomain}
          />
          <FormFeedback>{formik.errors.oktaDomain}</FormFeedback>
          <FormText>Okta domain. Example: okta-devok12.okta.com</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="oktaClientId">
          Okta App Client
        </Label>
        <Col sm={9}>
          <Input
            data-testid="oktaClientId"
            type="text"
            name="oktaClientId"
            placeholder=""
            value={formik.values.oktaClientId}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oktaClientId}
          />
          <FormFeedback>{formik.errors.oktaClientId}</FormFeedback>
          <FormText>Okta App Client ID</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="oktaSecretKey">
          Okta App Secret Key
        </Label>
        <Col sm={9}>
          <Input
            data-testid="oktaSecretKey"
            type="textarea"
            name="oktaSecretKey"
            placeholder=""
            value={formik.values.oktaSecretKey}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oktaSecretKey}
          />
          <FormFeedback>{formik.errors.oktaSecretKey}</FormFeedback>
          <FormText>Okta App Secret Key</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { OktaConfigForm };
