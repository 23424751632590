import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  AptAnswersFilterPurpose,
  AptAnswersFilterRule
} from "@aptedge/lib-ui/src/types/entities";
import { isEmpty } from "lodash";
import * as React from "react";
import styles from "./FilterHeaderMessage.module.scss";

interface RulesBadgeProps {
  rulesLength: number;
  isRuleValueEmpty: boolean;
}

const RulesBadge: React.FC<RulesBadgeProps> = ({
  rulesLength,
  isRuleValueEmpty
}) => {
  if (isRuleValueEmpty) return <></>;

  return (
    <div
      className={styles.rulesBadgeContainer}
      data-testid="rules-badge"
    >{`${rulesLength} ${rulesLength === 1 ? "rule" : "rules"}`}</div>
  );
};

interface HiddenBadgeProps {
  hiddenIsDefault: boolean;
}

const HiddenBadge: React.FC<HiddenBadgeProps> = ({ hiddenIsDefault }) => {
  return (
    <div
      className={
        hiddenIsDefault
          ? styles.hiddenTextContainer
          : styles.hiddenBadgeContainer
      }
      data-testid={
        hiddenIsDefault
          ? "css-filter-hidden-header"
          : "non-css-filter-hidden-header"
      }
    >
      <Icons.EyeSlash className={styles.hiddenIcon} />
      Hidden
    </div>
  );
};

interface FilterHeaderMessageProps {
  filterPurpose: AptAnswersFilterPurpose;
  rules: AptAnswersFilterRule[];
  manageAllSelected: boolean;
}

const FilterHeaderMessage: React.FC<FilterHeaderMessageProps> = ({
  filterPurpose,
  rules,
  manageAllSelected
}) => {
  let message;
  const hasRules = rules.length > 0;
  const isRuleValueEmpty = isEmpty(rules[0]?.value);
  const rulesLength = rules.filter((rule) => rule.value).length;

  if (!Boolean(hasRules)) {
    if (filterPurpose === AptAnswersFilterPurpose.HIDE) {
      message = manageAllSelected ? (
        <p
          className={styles.message}
          data-testid="non-css-filter-default-header"
        >
          Showing All Results
        </p>
      ) : (
        <HiddenBadge hiddenIsDefault={false} />
      );
    } else {
      message = manageAllSelected ? (
        <p className={styles.message} data-testid="css-filter-header">
          Showing All Results
        </p>
      ) : (
        <HiddenBadge hiddenIsDefault={true} />
      );
    }
  } else {
    message = (
      <RulesBadge
        rulesLength={rulesLength}
        isRuleValueEmpty={isRuleValueEmpty}
      />
    );
  }

  return <div className={styles.container}>{message}</div>;
};

export { FilterHeaderMessage };
