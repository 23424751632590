import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterListEmptyState.module.scss";

interface FilterListEmptyStateProps {
  handleCreateFilter: () => void;
  filterType: AptAnswersFilterType;
}

const FilterListEmptyState: React.FC<FilterListEmptyStateProps> = ({
  filterType,
  handleCreateFilter
}) => {
  const headerText = `Create your first ${
    filterType === AptAnswersFilterType.QUICK_FILTER ? "quick" : "product"
  } filter`;
  const descriptionText = `Scope answers and search results ${
    filterType === AptAnswersFilterType.QUICK_FILTER
      ? "based on custom rules"
      : "for specific products"
  }`;
  const buttonLabel = `Create ${
    filterType === AptAnswersFilterType.QUICK_FILTER ? "quick" : "product"
  } filter`;

  return (
    <div className={styles.container}>
      <div className={styles.filterIcon}>
        {filterType === AptAnswersFilterType.QUICK_FILTER ? (
          <AeIcons.QuickFiltersLarge />
        ) : (
          <AeIcons.ProductFiltersLarge />
        )}
      </div>
      <div className={styles.header}>{headerText}</div>
      <div className={styles.description}>{descriptionText}</div>
      <div className={styles.actionsContainer}>
        <button
          onClick={handleCreateFilter}
          className={styles.createFilterButton}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

export { FilterListEmptyState };
