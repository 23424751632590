import * as React from "react";
import styles from "./FilterReset.module.scss";

interface FilterResetProps {
  label: string;
  icon: React.ReactNode;
  handleClick: () => void;
}

const FilterReset: React.FC<FilterResetProps> = ({
  label,
  icon,
  handleClick
}) => {
  return (
    <div
      className={styles.container}
      onClick={handleClick}
      data-testid="reset-apt-answer-filter"
    >
      <div className={styles.infoContainer}>
        <span className={styles.icon}>{icon}</span>
        <span className={styles.label}>{label}</span>
      </div>
    </div>
  );
};

export { FilterReset };
