import "reflect-metadata";
import { ContentLayout } from "@aptedge/lib-support-ui/src/features/ContentLayout/ContentLayout";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { Preview } from "@aptedge/lib-ui/src/features/EmbeddedSearch/components/Preview/Preview";
import { sendMessage } from "@aptedge/lib-ui/src/features/EmbeddedSearch/helpers";
import SearchResultPage from "@aptedge/lib-ui/src/features/EmbeddedSearch/SearchResultPage/SearchResultPage";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { FC, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { SingleArticle } from "../Article/Article";
import { Articles } from "../Articles/Articles";
import { SessionReplay } from "../SessionReplay/SessionReplay";
import { EmbeddedSearch } from "./EmbeddedSearch/EmbeddedSearch";
import styles from "./styles.module.scss";

export enum Emb_App_Routes {
  HOME = "/",
  SEARCH = "/search",
  ARTICLES = "/articles",
  ARTICLE = "/article-page",
  RRWEB_PLAYER = "/rrweb-player"
}

export const EmbeddedApp: FC = () => {
  const { searchQuery } = useAppSelector((state) => state.search);
  const { isPreviewMode } = useAppSelector((state) => state.embeddedApp);
  const { cssConfig } = useAppSelector((state) => state.ssApp);

  const { pathname } = useLocation();
  const { flags } = useFlags();

  useEffect(() => {
    sendMessage({
      getUser: true,
      flags: { sessionReplay: flags.sessionReplay }
    });

    if (pathname === Emb_App_Routes.HOME) {
      sendMessage({ getSearchQuery: true });
    }

    if (pathname === Emb_App_Routes.ARTICLE)
      sendMessage({ expandedPage: "52em" });
    else sendMessage({ expandedPage: "30em" });
  }, [flags.sessionReplay, pathname]);

  if (isPreviewMode) {
    if (cssConfig?.proactive_answers.enabled)
      return (
        <ContentLayout
          isPreviewMode={isPreviewMode}
          className={styles.previewContainer}
          contentClassName={styles.previewContent}
        >
          <Preview searchQuery={searchQuery} />
        </ContentLayout>
      );
  }

  return (
    <Switch>
      <Route path={Emb_App_Routes.HOME} exact component={EmbeddedSearch} />

      <Route exact path={Emb_App_Routes.SEARCH} component={SearchResultPage} />

      <Route
        exact
        path={Emb_App_Routes.ARTICLES}
        render={() => (
          <ContentLayout>
            <Articles />
          </ContentLayout>
        )}
      />
      <Route
        exact
        path={Emb_App_Routes.ARTICLE}
        render={() => (
          <ContentLayout>
            <SingleArticle />
          </ContentLayout>
        )}
      />
      <Route
        exact
        path={Emb_App_Routes.RRWEB_PLAYER}
        render={() => (
          <ContentLayout disableContentPadding>
            <SessionReplay />
          </ContentLayout>
        )}
      />
    </Switch>
  );
};
