import * as React from "react";
import styles from "./FilterItem.module.scss";

interface FilterItemProps {
  id: string;
  name: string;
  icon: React.ReactNode;
  selected: boolean;
  handleItemClick: (id: string) => void;
}

const FilterItem: React.FC<FilterItemProps> = ({
  id,
  name,
  icon,
  selected,
  handleItemClick
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <span className={styles.icon}>{icon}</span>
        <span className={styles.label}>{name}</span>
      </div>
      <input
        type="checkbox"
        checked={selected}
        onChange={() => handleItemClick(id)}
        data-testid="apt-answer-filter-checkbox"
      />
    </div>
  );
};

export { FilterItem };
