import { ISharepointPagesSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchSharepointPagesSources(): Promise<ISharepointPagesSource[]> {
  const url = `${getBackendUrl()}/api/integrations/sharepoint-pages`;
  return request(url);
}

export { fetchSharepointPagesSources };
