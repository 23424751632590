import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import WithLoading from "../../../components/WithLoading/WithLoading";
import {
  useOktaSource,
  useOneloginSource
} from "../../../hooks/clients/useDataSource";
import { OktaConfig } from "./OktaConfig";
import { OneloginConfig } from "./OneloginConfig";

const AuthenticationSourceConfig: React.FC = () => {
  const oneloginSource = useOneloginSource();
  const oktaSource = useOktaSource();

  const isLoading = oneloginSource.isLoading || oktaSource.isLoading;

  return (
    <section className="mb-5 mt-5">
      <div className="row">
        <WithLoading isLoading={isLoading} fallback={<Spinner />}>
          <div className="col-md-5 p-2 pr-3">
            <h4 className="text-uppercase">Authentication</h4>
            <p>Connect AptEdge to your Authentication provider.</p>
          </div>
          <div className="col-md-7 p-2 pl-3">
            <OneloginConfig dataSource={oneloginSource} />
            <br />
            <OktaConfig dataSource={oktaSource} />
          </div>
        </WithLoading>
      </div>
      <hr className="mt-5" />
    </section>
  );
};

export { AuthenticationSourceConfig };
