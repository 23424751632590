import { useSearch } from "@aptedge/lib-ui/src/hooks/search/useSearch";
import { Stack, Box, styled, Paper } from "@mui/material";
import { FC, ReactNode } from "react";
import { FiltersProvider } from "../../SearchFilters/FiltersContext";
import { SearchInput } from "../SearchInput/SearchInput";

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "grid",
  justifyItems: "center",
  [theme.breakpoints.up("web")]: {
    padding: theme.spacing(4),
    // fixed 60px can be updated with css variable once we update the top nav bar
    // 60px can be updated with height of navbar
    minHeight: "calc(100dvh - 60px)"
  }
}));
export const Feed: FC<{ children: ReactNode; disableSearch?: boolean }> = ({
  children,
  disableSearch
}) => {
  const {
    searchQueryValue,
    setSearchQueryValue,
    handleFollowUpSearch
  } = useSearch("");

  return (
    <StyledPaper>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
        sx={{
          width: { web: "1024px", f1: "420px", supportApp: "320px" },
          flexGrow: 1
        }}
      >
        <Box>{children}</Box>
        {!disableSearch && (
          <FiltersProvider>
            <SearchInput
              searchQuery={searchQueryValue}
              setSearchQuery={setSearchQueryValue}
              handleSearch={handleFollowUpSearch}
            />
          </FiltersProvider>
        )}
      </Stack>
    </StyledPaper>
  );
};
