import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { useFormik } from "formik";
import * as Cookies from "js-cookie";
import { useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { getBackendUrl } from "../../config";
import { AuthStatus, useAuth } from "../../context/AuthContext";
import {
  useOneloginUrl,
  useOktaLoginUrl
} from "../../hooks/clients/useDataSource";
import { useUrlSearchParams } from "../../hooks/useUrlSearchParams";
import { Routes } from "../../routes/routes";
import {
  MS_OAUTH_FLOW,
  GOOGLE_OAUTH_FLOW,
  OAUTH_REDIRECT_URL
} from "../../types/clients";
import { APTEDGE_COOKIE_DOMAIN } from "../../utils/oauth";
import { request } from "../../utils/request";
import LoginBtn from "./LoginBtn";
import { LoginContent } from "./LoginContent";
import "./LoginPage.scss";

const ONE_LOGIN_ICON =
  "https://www.onelogin.com/press-center/wp-content/themes/oceanwp-child-theme-master/assets/Onelogin_Mark_black_RGB.png";
const OAUTH_COOKIE_EXPIRY = 30;

function deleteAllCookies(): void {
  const cookies = Cookies.get();
  Object.keys(cookies).forEach((cookieName: string) => {
    Cookies.remove(cookieName, { path: "" });
  });
}

const triggerEvent = (eventName: string): void => {
  dataLayerPush({
    event: eventName
  });
};

export const LoginPage: React.FC = () => {
  const { login, status } = useAuth();
  const { dataSource: oktaLoginUrl } = useOktaLoginUrl();
  const { dataSource: oneloginUrl } = useOneloginUrl();
  const [{ redirectTo }] = useUrlSearchParams({ redirectTo: null });
  const inputRef = useRef<HTMLInputElement>(null);
  const { flags } = useFlags();
  const formik = useFormik<FormState>({
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email address")
        .required("Email is required."),
      password: Yup.string().required("Password is required.")
    }),
    onSubmit: (data: FormState): void => {
      login(data);
    },
    validateOnChange: false
  });

  const handleGoogleOAuth = async (): Promise<void> => {
    triggerEvent(GTM_EVENTS.GTM_GOOGLE_OAUTH_CLICKED);
    // Detele all cookies before making requests to google oauth login endpoint
    await deleteAllCookies();
    const data = await request(`${getBackendUrl()}/g-oauth/login`);
    const authURI = data.url;
    await Cookies.remove(GOOGLE_OAUTH_FLOW, {
      domain: APTEDGE_COOKIE_DOMAIN
    });

    window.open(authURI, "_self");
  };

  const handleMSOAuth = async (): Promise<void> => {
    triggerEvent(GTM_EVENTS.GTM_MS_OAUTH_CLICKED);
    // Detele all cookies before making requests to ms oauth login endpoint
    await deleteAllCookies();
    const data = await request(`${getBackendUrl()}/ms-oauth/login`);
    const expiry = 0.01; // 15 min in terms of Day
    const authURI = data.flow.auth_uri;
    await Cookies.set(MS_OAUTH_FLOW, JSON.stringify(data), {
      expires: expiry,
      domain: APTEDGE_COOKIE_DOMAIN
    });

    await window.open(authURI, "_self");
  };

  const handleOktaOAuth = async (): Promise<void> => {
    triggerEvent(GTM_EVENTS.GTM_OKTA_OAUTH_CLICKED);
    // Detele all cookies before making requests to okta login endpoint
    await deleteAllCookies();
    const loginUrl = await request(`${getBackendUrl()}/okta/login`);
    await window.open(loginUrl.loginUrl, "_self");
  };

  const handleOneloginOAuth = async (): Promise<void> => {
    const data = await request(`${getBackendUrl()}/onelogin/login`);
    const authURI = data.redirect;
    window.open(authURI, "_self");
  };

  useEffect(() => {
    const origin = window.location.hostname;

    Cookies.set(OAUTH_REDIRECT_URL, origin, {
      expires: OAUTH_COOKIE_EXPIRY,
      domain: APTEDGE_COOKIE_DOMAIN
    });
  }, []);

  // Redirect if Logged In — this will execute:
  // 1. After a successful login, which updates AuthContext and re-renders this component.
  // 2. If someone tries to visit this page directly, after logging in.
  if (status === AuthStatus.LOGGED_IN)
    return (
      <Redirect
        to={flags.unifiedUi ? Routes.HOME : redirectTo || Routes.HOME}
      />
    );

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <LoginContent>
        <div className="header">
          <img alt="logo" className="logo" src="/trimmed-logo.png" />
          <h2>AptEdge</h2>
          <h3>Log in to your account</h3>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {!formik.errors.email &&
            !formik.errors.password &&
            status === AuthStatus.FAILED && (
              <div className="alert alert-danger">
                The username or password you entered is incorrect.
              </div>
            )}
          <div className="form-group">
            <TextInput
              ref={inputRef}
              name="email"
              value={formik.values.email}
              error={formik.errors.email}
              label="Email"
              onChange={formik.handleChange}
              onClear={() => formik.setFieldValue("email", "")}
              autoFocus
              data-testid="email"
            />
          </div>
          <div className="form-group">
            <TextInput
              type="password"
              name="password"
              value={formik.values.password}
              error={formik.errors.password}
              label="Password"
              onChange={formik.handleChange}
              onClear={() => formik.setFieldValue("password", "")}
              data-testid="password"
            />
          </div>
          <Button
            className="mt-3 login-btn"
            disabled={status === AuthStatus.PENDING}
            data-testid="login"
            type="submit"
          >
            <span>
              {status === AuthStatus.PENDING
                ? "Logging you in"
                : "Continue with email"}
            </span>
          </Button>
          <div className="divider">or</div>
          <LoginBtn onClick={handleGoogleOAuth}>
            <AeIcons.GoogleIcon
              className="oauth-icon"
              style={{ left: "0.6rem" }}
            />
            <span>Continue with Google</span>
          </LoginBtn>
          <LoginBtn onClick={handleMSOAuth}>
            <AeIcons.MSIcon
              className="oauth-icon"
              style={{ left: "0.75rem" }}
            />
            <span>Continue with Microsoft</span>
          </LoginBtn>
          {(oneloginUrl?.redirect || true) && (
            <LoginBtn onClick={handleOneloginOAuth}>
              <img
                src={ONE_LOGIN_ICON}
                alt="Onelogin"
                className="oneloginIcon oauth-icon"
              />
              <span>Continue with OneLogin</span>
            </LoginBtn>
          )}
          {oktaLoginUrl?.loginUrl && (
            <LoginBtn onClick={handleOktaOAuth}>
              <AeIcons.OktaIcon
                className="oktaIcon oauth-icon"
                style={{ left: "0.75rem" }}
              />
              <span>Continue with Okta</span>
            </LoginBtn>
          )}

          <hr />

          <div className="reset d-flex justify-content-center align-items-center">
            <Link to={Routes.RESET}>Can&apos;t log in?</Link>
          </div>
        </form>
      </LoginContent>
    </>
  );
};

interface FormState {
  email: string;
  password: string;
}
