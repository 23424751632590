import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { useSearch } from "@aptedge/lib-ui/src/hooks/search/useSearch";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  AptAnswersContextType,
  AptAnswersSearchContextItem,
  FilterQueryLabels,
  ISearchFilter,
  SearchFilterSubType,
  SearchResultSource
} from "@aptedge/lib-ui/src/types/entities";
import {
  Chip,
  ClickAwayListener,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme
} from "@mui/material";
import { FC, useState } from "react";
import { FiltersProvider } from "../../SearchFilters/FiltersContext";
import { SearchInput } from "../SearchInput/SearchInput";

type Props = {
  query: string;
  querySource: SearchResultSource;
  editable?: boolean;
  marginBottom?: number | string;
};

const FilterChip = styled(Chip)(() => ({
  borderRadius: ".25rem",
  padding: "0.125rem 0.375rem 0.125rem 0.25rem"
}));

const FilterLabels: FC<{ filter: string; id: string }> = ({ filter, id }) => {
  if (!filter) return null;
  return (
    <FilterChip
      label={filter}
      key={id}
      size="small"
      sx={{ textTransform: "capitalize" }}
    />
  );
};

export const Query: FC<Props> = ({
  query,
  querySource,
  marginBottom,
  editable = true
}) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);

  const {
    searchFilter,
    searchFilterSubType,
    productFilters,
    quickFilters
  } = useAppSelector((state) => state.search);

  const getFilters = (): FilterQueryLabels[] => [
    { name: searchFilter, id: searchFilter },
    { name: searchFilterSubType, id: searchFilterSubType },
    ...(productFilters?.length
      ? [
          { name: "Product", id: AptAnswersContextType.PRODUCT_FILTER },
          ...productFilters.map((f) => ({ name: f.name, id: f.id }))
        ]
      : []),
    ...(quickFilters?.length
      ? [
          { name: "Filters", id: AptAnswersContextType.QUICK_FILTER },
          ...quickFilters.map((f) => ({ name: f.name, id: f.id }))
        ]
      : [])
  ];

  const {
    searchQueryValue,
    setSearchQueryValue,
    handleFollowUpSearch
  } = useSearch(query);

  const handleEdit = (): void => {
    if (editable) {
      setSearchQueryValue(query);
      setIsEditing(true);
    }
  };
  const onClickAway = (): void => {
    setIsEditing(false);
  };

  const handleSearch = (
    query: string,
    filter?: ISearchFilter,
    subFilter?: SearchFilterSubType,
    productFilter?: AptAnswersSearchContextItem[],
    quickFilter?: AptAnswersSearchContextItem[]
  ): void => {
    handleFollowUpSearch(query, filter, subFilter, productFilter, quickFilter);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <div>
            <FiltersProvider>
              <SearchInput
                expanded
                handleSearch={handleSearch}
                searchQuery={searchQueryValue}
                setSearchQuery={setSearchQueryValue}
              />
            </FiltersProvider>
          </div>
        </ClickAwayListener>
      ) : (
        <Stack direction="column">
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              "&:hover .edit-icon": { visibility: "visible" }
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color:
                  querySource === SearchResultSource.SEARCH_PAGE
                    ? theme.palette.slate.slate80
                    : theme.palette.viking.viking80
              }}
              fontWeight={
                querySource === SearchResultSource.SEARCH_PAGE ? undefined : 600
              }
            >
              {query}
            </Typography>
            {editable && (
              <IconButton
                size="small"
                className="edit-icon"
                onClick={handleEdit}
                sx={{
                  visibility: "hidden",
                  transition: "visibility 0.2s ease-in-out"
                }}
              >
                <AEIcon name="edit" />
              </IconButton>
            )}
          </Stack>
          <Stack direction="row" spacing={1} marginBottom={marginBottom}>
            {getFilters().map((filter) => (
              <FilterLabels
                filter={filter.name}
                key={filter.id}
                id={filter.id}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};
