import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateIsRuleExpanded } from "@aptedge/lib-ui/src/redux/reduxSlice/aptAnswerSlice";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import isEqual from "lodash/isEqual";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createFilter } from "../../clients/AptAnswers/createFilter";
import { updateFilter } from "../../clients/AptAnswers/updateFilter";
import { WebCacheKey } from "../../clients/cache";
import { useAptAnswersFilters } from "../../hooks/useAptAnswersFilters";
import { AptAnswersErrorMessage } from "./AptAnswersErrorMessage";
import { AptAnswersFilters } from "./AptAnswersFilters";
import styles from "./CustomerSelfServiceFilters.module.scss";
import { UnsavedModal } from "./UnsavedModal";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";
import { getFilterRulesForPayload } from "./utils/getFilterRulesForPayload";
import { mergeFiltersWithIntegrations } from "./utils/mergeFiltersWithIntegrations";

const CustomerSelfServiceFilters: FC = () => {
  const { isRuleExpanded } = useAppSelector((state) => state.aptAnswer);
  const dispatch = useAppDispatch();
  const { filterPurpose, filterType } = getAptAnswersFilterInfo(
    AptAnswersFilterType.CSS_FILTER
  );
  const filterName = AptAnswersFilterType.CSS_FILTER;

  // Get integration and filter data
  const {
    isError,
    isLoading,
    isSuccess,
    filtersData,
    integrationsData
  } = useAptAnswersFilters(filterType);

  const memoizedFiltersData = useMemo(() => {
    return filtersData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filtersData)]);

  const integrations = useMemo(
    () =>
      mergeFiltersWithIntegrations({
        filterName,
        filtersData: memoizedFiltersData,
        integrationsData,
        filterPurpose
      }),
    [filterName, memoizedFiltersData, integrationsData, filterPurpose]
  );

  const selfServiceRef = useRef<HTMLDivElement>(null);
  const [integrationValues, setIntegrationValues] = useState(integrations);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hasCustomFieldsToggled, setHasCustomFieldsToggled] = useState(false);

  useEffect(() => {
    if (!isEqual(integrationValues, integrations)) {
      setIntegrationValues(integrations);
    }
  }, [integrationValues, integrations]);

  useEffect(() => {
    dispatch(updateIsRuleExpanded({}));
  }, [dispatch]);

  // Define mutations
  const queryClient = useQueryClient();
  const resetOnMutation = useCallback(() => {
    queryClient.invalidateQueries([
      WebCacheKey.APT_ANSWERS_FILTERS,
      filterType
    ]);
    setIntegrationValues(integrations);
  }, [queryClient, filterType, integrations]);
  const createFilterMutation = useMutation(createFilter, {
    onSuccess: () => {
      handleRuleCollapse();
      resetOnMutation();
    }
  });
  const updateFilterMutation = useMutation(updateFilter, {
    onSuccess: () => {
      handleRuleCollapse();
      resetOnMutation();
    }
  });

  // Execute mutations
  const handleSave = (): void => {
    const payload = integrationValues
      .map((integration) => {
        const filterRules = getFilterRulesForPayload({
          filterPurpose,
          integration
        });
        return {
          ...(integration?.filter?.id && { id: integration?.filter?.id }),
          name: filterName,
          filterType,
          filterRules
        };
      })
      .filter((integration) => {
        return integration.filterRules.length !== 0 || integration.id;
      });
    const isUpdate = payload.some((integration) => integration.id);
    if (payload.length === 0) return;
    if (isUpdate) {
      updateFilterMutation.mutate({ filters: payload });
    } else {
      createFilterMutation.mutate({ filters: payload });
    }
  };

  const handleRuleCollapse = (): void => {
    const newRuleExpanded = { ...isRuleExpanded };
    Object.keys(newRuleExpanded).map((rule) => (newRuleExpanded[rule] = false));
    dispatch(updateIsRuleExpanded(newRuleExpanded));
  };

  const handleRedirection = (): void => {
    if (hasCustomFieldsToggled) {
      setShowConfirmationModal(true);
    }
  };

  useOnMouseDownOutside(selfServiceRef, handleRedirection);

  return (
    <div className={styles.container} ref={selfServiceRef}>
      {isLoading && <Spinner />}
      {isError && (
        <AptAnswersErrorMessage message="There as an error loading Knowledge Sources." />
      )}
      {isSuccess && (
        <AptAnswersFilters
          filterType={filterType}
          integrations={integrationValues}
          setIntegrationValues={setIntegrationValues}
          handleItemSave={handleSave}
          setHasCustomFieldsToggled={setHasCustomFieldsToggled}
          setShowConfirmationModal={setShowConfirmationModal}
          handleRuleCollapse={handleRuleCollapse}
        />
      )}
      {showConfirmationModal && (
        <UnsavedModal
          reset={resetOnMutation}
          setHasCustomFieldsToggled={setHasCustomFieldsToggled}
          setShowConfirmationModal={setShowConfirmationModal}
          handleSave={handleSave}
          integrations={integrationValues}
          handleRuleCollapse={handleRuleCollapse}
        />
      )}
    </div>
  );
};

export { CustomerSelfServiceFilters };
