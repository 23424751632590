import DotLoader from "@aptedge/lib-ui/src/components/DotLoader/DotLoader";
import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { setSessionData } from "@aptedge/lib-ui/src/redux/reduxSlice/embeddedAppSlice";
import { Box, styled, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import type rrwebPlayer from "rrweb-player";
import { fetchSessionReplayData } from "../../../../app-web/src/clients/ArticleSources/dashcam/getSessionReplayData";
import "./sessionPreview.scss";
import "rrweb-player/dist/style.css";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  sessionRecordingUrl?: string;
  title?: string;
};

const PlayerBox = styled(Box)`
  background: #1b1b1b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SessionPreview: FC<Props> = ({
  className,
  height,
  width,
  sessionRecordingUrl,
  title
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const playerInstanceRef = useRef<rrwebPlayer | null>(null);
  const playerWidth = width || 448;
  const playerHeight = height || 200;
  const { flags } = useFlags();
  const [isPlayed, setIsPlayed] = useState(false);
  const dispatch = useAppDispatch();

  const { sessionData } = useAppSelector((state) => state.embeddedApp);

  const { isLoading: isLoadingDashcamData } = useQuery(
    [CACHE_KEY.DASHCAM_SESSION_REPLAY, sessionRecordingUrl],
    () => fetchSessionReplayData(sessionRecordingUrl),
    {
      enabled: !!sessionRecordingUrl && isPlayed && flags.unifiedUi,
      onSuccess: (data) => {
        dispatch(setSessionData(data));
      }
    }
  );

  // Initialize rrweb-player when session data is available
  useEffect(() => {
    const element = ref.current;

    if (element && (!isEmpty(sessionData) || isPlayed)) {
      element.innerHTML = "";

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsedEvents = JSON.parse(sessionData as any);

        if (!Array.isArray(parsedEvents)) return;

        import("rrweb-player").then((rrwebPlayer) => {
          playerInstanceRef.current = new rrwebPlayer.default({
            target: element,
            props: {
              maxScale: 1,
              events: parsedEvents,
              autoPlay: true,
              width: playerWidth,
              height: playerHeight
            }
          });
        });
      } catch (error) {
        console.error("Error initializing rrweb-player:", error);
      }
    }

    // Clean up the player instance
    return () => {
      if (element) element.innerHTML = "";
      playerInstanceRef.current = null;
    };
  }, [playerHeight, playerWidth, sessionData, isPlayed]);

  const onPlayBtnClicked = (): void => {
    setIsPlayed(true);
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={(theme) => ({
          color: theme.palette.slate.slate80,
          marginBottom: "1rem",
          marginTop: "1rem"
        })}
      >
        {title}
      </Typography>

      <div className={className}>
        {!isEmpty(sessionData) && isPlayed ? (
          <div ref={ref} className="preview-container"></div>
        ) : (
          <PlayerBox
            onClick={onPlayBtnClicked}
            sx={{
              height: playerHeight,
              width: playerWidth
            }}
          >
            {isLoadingDashcamData ? (
              <DotLoader />
            ) : (
              <AEIcon name="play_circle" color="white" size="1.5rem" />
            )}
          </PlayerBox>
        )}
      </div>
    </>
  );
};

export default SessionPreview;
