import { GetDashcamSessionByTicketIdResponse } from "@aptedge/lib-ui/src/types/dashcam";
import { inject, injectable } from "inversify";
import { SDKClient } from "../sdkClient";
import { DashCamSession } from "./dashCamSession";

@injectable()
class DashCamSessionProd implements DashCamSession {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async getDashCamSession(
    ticketId: string
  ): Promise<GetDashcamSessionByTicketIdResponse> {
    const url = `/api/dashcam/sessionbyticketid/${ticketId}`;

    return this.client.fetch(url);
  }
}

export { DashCamSessionProd };
