import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { FiltersType } from "@aptedge/lib-ui/src/redux/reduxSlice/useSearchReducer";
import {
  AptAnswersSearchContextItem,
  ISearchFilter,
  QUERY_PARAMS,
  SearchFilterSubType
} from "@aptedge/lib-ui/src/types/entities";
import {
  alpha,
  InputBase,
  InputBaseProps,
  useMediaQuery,
  Grid,
  Box,
  useTheme,
  styled,
  ClickAwayListener
} from "@mui/material";
import { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useFilters } from "../../SearchFilters/FiltersContext";
import { SearchFilters } from "../../SearchFilters/SearchFilters";

const SearchContainer = styled("div")(({ theme }) => ({
  alignSelf: "flex-end",
  position: "sticky",
  bottom: 40,
  display: "flex",
  alignItems: "center",
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
  border: "1px solid",
  borderColor: alpha(theme.palette.common.black, 0.1),

  transition: "all 0.2s ease-in-out",
  "&:hover": {
    borderColor: alpha(theme.palette.common.black, 0.4)
  },
  "&:focus-within": {
    borderColor: theme.palette.primary.main
  }
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  fontSize: "1rem",
  color: theme.palette.text.primary,
  "&::placeholder": {
    color: alpha(theme.palette.text.primary, 0.5)
  }
}));

interface CustomInputProps extends InputBaseProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onQuerySubmit?: () => void;
}

const CustomInput: React.FC<CustomInputProps> = ({
  value,
  onChange,
  onQuerySubmit,
  ...props
}) => {
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("web"));

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (isWeb && event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onQuerySubmit?.();
    }
  };

  return (
    <StyledInputBase
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
};

type SearchInputProps = {
  handleSearch: (
    query: string,
    filter?: ISearchFilter,
    subFilter?: SearchFilterSubType,
    productFilter?: AptAnswersSearchContextItem[],
    quickFilter?: AptAnswersSearchContextItem[]
  ) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  expanded?: boolean;
  placeholder?: string;
};
export const SearchInput: FC<SearchInputProps> = ({
  expanded,
  handleSearch,
  searchQuery,
  setSearchQuery,
  placeholder
}) => {
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("web"));
  const { queryParams } = useQueryParams();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(expanded);
  const { state, dispatch } = useFilters();
  const { filter, subFilter } = state;

  const { answerId } = useAppSelector((state) => state.search);

  const handleFocus = (): void => {
    setIsFocused(true);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (isWeb && isFocused && inputRef.current) {
      const input = inputRef.current;
      input.focus();
      const length = input.value.length;
      input.setSelectionRange(length, length);
    }
    if (!Boolean(filter) && queryParams.has(QUERY_PARAMS.FILTER)) {
      dispatch({
        type: FiltersType.SET_FILTER,
        payload: queryParams.get(QUERY_PARAMS.FILTER) as ISearchFilter
      });
    }
    if (!Boolean(subFilter) && queryParams.has(QUERY_PARAMS.FILTER_SUB_TYPE)) {
      dispatch({
        type: FiltersType.SET_SUB_FILTER,
        payload: queryParams.get(
          QUERY_PARAMS.FILTER_SUB_TYPE
        ) as SearchFilterSubType
      });
    }
  }, [isFocused, isWeb, queryParams, dispatch, filter, subFilter]);

  const onClickAway = (): void => {
    !expanded && setIsFocused(false);
  };

  const sendButtonClick = (): void => {
    handleSearch(
      searchQuery,
      state.filter,
      state.subFilter,
      state.productFilter,
      state.quickFilter
    );
  };

  const placeholderText =
    placeholder || answerId
      ? "Ask a follow-up question..."
      : "Ask a question...";

  return (
    <SearchContainer onClick={handleFocus}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Grid container direction="column" justifyContent="center">
          <Box>
            <CustomInput
              inputRef={inputRef}
              value={searchQuery}
              onChange={onInputChange}
              onQuerySubmit={sendButtonClick}
              fullWidth
              placeholder={placeholderText}
              multiline={isWeb && isFocused}
              minRows={3}
              maxRows={6}
            />
          </Box>
          {isWeb && isFocused && (
            <SearchFilters sendButtonClick={sendButtonClick} />
          )}
        </Grid>
      </ClickAwayListener>
    </SearchContainer>
  );
};
