import sanitizeHtml from "sanitize-html";
import { IContext } from "../context/SentryContext";
import { TopSearchResults } from "../types/entities";

// This function is used in app-web and lib-support-ui components which has different types
// for searchResults that's why keeping it as type any.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateTopSearchResults(searchResults: any): TopSearchResults[] {
  const results: TopSearchResults[] = (searchResults || []).slice(0, 10).map(
    // These fields are each available on different result types (article, ticket, etc.) under
    // different names, so we simply find the one that isn't undefined.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (searchResult: any) => ({
      type: searchResult.type,
      id: [searchResult.externalId, searchResult.id].find((t) => t),
      title: [
        searchResult.title,
        searchResult.summary,
        searchResult.name,
        searchResult.subject
      ].find((t) => t),
      preview: searchResult.preview,
      sourceType: searchResult.sourceType ?? "",
      final_rank: searchResult.score_details?.final_rank || 0,
      keyword_search_rank: searchResult.score_details?.keyword_search_rank || 0,
      semantic_search_rank:
        searchResult.score_details?.semantic_search_rank || 0,
      search_type: searchResult.score_details?.search_type || ""
    })
  );
  return results;
}

function calculatePosition(
  position: number | undefined,
  page: number | undefined,
  perPage: number
): number | string {
  return position && page ? (page - 1) * perPage + position : "";
}

function sanitizeText(text: string | undefined): string {
  return (text || "").replace(
    /<span class='offset-highlight'>(.*?)<\/span>/g,
    (_, textInsideSpanTag) => textInsideSpanTag
  );
}

function sanitizeSnippet(
  sentry: IContext,
  url: string,
  sanitizeConfig: Record<string, unknown>,
  description?: string
): string {
  const cleanPreviewSnippet = sanitizeHtml(description ?? "", sanitizeConfig);
  return cleanPreviewSnippet
    .replace(/(<\/\w+>)\n+/g, "<br />")
    .replace(/^&gt;\s?/gm, "> ")
    .replace(/\\n/g, "\n")
    .replace(/\\n+/g, "")
    .replace(/\\t/g, "    ")
    .replace(/\\r/g, "")
    .replace(
      /<span\s+class="image-wrap">\s*<img\s+[^>]*src="([^"]*)"[^>]*>\s*<\/span>/gi,
      (_, src) => {
        return `<a href="${src}" target="_blank" rel="noreferrer">Image</a>`;
      }
    )
    .replace(
      /<span\s+class="image-wrap">\s*<a\s+[^>]*href="([^"]*)"[^>]*>\s*<img\s+[^>]*src="([^"]*)"[^>]*>\s*<\/a>\s*<\/span>/gi,
      (_, src) => {
        return `<a href="${src}" target="_blank" rel="noreferrer">
          Image
        </a>`;
      }
    )
    .replace(/<img\s+[^>]*src="([^"]*)"[^>]*>/gi, (_, src) => {
      return `<a href="${src}" target="_blank" rel="noreferrer">
        Image
      </a>`;
    })
    .replace(/<a\s+href="([^"]*)"/g, (_, href) => {
      const decodedUrl = decodeURIComponent(href);
      try {
        const updatedUrl = new URL(decodedUrl, url);
        return `<a href="${updatedUrl.href}" target="_blank" rel="noreferrer"`;
      } catch (error) {
        sentry.captureException(
          `Error processing URL: ${decodedUrl}, ${error}`
        );
        return "";
      }
    });
}

export {
  generateTopSearchResults,
  calculatePosition,
  sanitizeText,
  sanitizeSnippet
};
