import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import classNames from "classnames";
import { FC, ReactElement } from "react";
import styles from "./styles.module.scss";

type Props = {
  text: { error: string; success?: string };
  isError: boolean;
  updateError?: (value: boolean) => void;
  isFormSubmitted?: boolean;
  className?: string;
  actionBtn?: ReactElement | null;
};
const NotificationBlock: FC<Props> = ({
  text,
  isError,
  updateError,
  isFormSubmitted,
  className,
  actionBtn
}) => {
  if (!isError && isFormSubmitted && !text.success) return null;
  return (
    <div
      className={classNames(styles.baseNotification, className, {
        [styles.errorContainer]: isError,
        [styles.successContainer]: !isError && isFormSubmitted
      })}
    >
      <div className={styles.left}>
        <AEIcon name="error" />
        <span>{isError ? text.error : text.success}</span>
      </div>
      {actionBtn ? (
        actionBtn
      ) : (
        <AEIcon
          name="close"
          onClick={() => updateError && updateError(false)}
          className={styles.closeBtn}
        />
      )}
    </div>
  );
};

export default NotificationBlock;
