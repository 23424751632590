import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { FC } from "react";
import styles from "./styles.module.scss";

export const EmptyDashboard: FC = () => {
  return (
    <div className={styles.emptyDashboard}>
      <AeIcons.Inbox />
      <h1>No dashboards configured</h1>
      <p>
        Please contact your account manager to configure your analytics
        dashboard
      </p>
    </div>
  );
};
