import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import {
  DropdownItem,
  DropdownItemType
} from "@aptedge/lib-ui/src/components/MultiLevelDropdown/components/DropdownItem";
import { MultiLevelDropdown } from "@aptedge/lib-ui/src/components/MultiLevelDropdown/MultiLevelDropdown";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { updatePage } from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  updateResultId,
  updateSearchCardVisibility,
  updateFeedbackSubmittedIds
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { FiltersType } from "@aptedge/lib-ui/src/redux/reduxSlice/useSearchReducer";
import {
  ISearchFilter,
  SearchFilterSubType,
  QUERY_PARAMS,
  FeedbackIds
} from "@aptedge/lib-ui/src/types/entities";
import { dataLayerPush, GTM_EVENTS } from "@aptedge/lib-ui/src/utils/event";
import { useTheme } from "@mui/material";
import { FC } from "react";
import { useFilters } from "../FiltersContext";
import { useSearchFilterData } from "../useSearchFilter";

const trigerSearchFilterEvent = (
  filter: ISearchFilter,
  sourceType: string
): void => {
  dataLayerPush({
    event: GTM_EVENTS.GTM_SEARCH_RESULT_FILTER,
    data: {
      search_result_type: filter,
      integration_type: sourceType
    }
  });
};

export const SourcesFilters: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { searchFilterList } = useSearchFilterData();
  const { removeQueryParams, queryParams, history } = useQueryParams();
  const { state, dispatch: filterDispatch } = useFilters();

  const handleSelect = (
    filter: DropdownItemType,
    subFilter?: DropdownItemType
  ): void => {
    const filterKey = filter.id as ISearchFilter;
    const subFilterKey = subFilter?.id as SearchFilterSubType;

    if (!filterKey) return;

    if (filterKey !== state.filter) {
      if (queryParams.has(QUERY_PARAMS.FILTER_SUB_TYPE)) {
        removeQueryParams(QUERY_PARAMS.FILTER_SUB_TYPE);
        filterDispatch({ type: FiltersType.SET_SUB_FILTER, payload: "" });
      }
      queryParams.set(QUERY_PARAMS.FILTER, filterKey);
      filterDispatch({ type: FiltersType.SET_FILTER, payload: filterKey });
    }
    if (Boolean(subFilterKey)) {
      filterDispatch({ type: FiltersType.SET_FILTER, payload: filterKey });
      filterDispatch({
        type: FiltersType.SET_SUB_FILTER,
        payload: subFilterKey
      });
      queryParams.set(QUERY_PARAMS.FILTER_SUB_TYPE, subFilterKey);
    }
    trigerSearchFilterEvent(filterKey, subFilterKey);
    dispatch(updatePage(1));
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
    dispatch(updateResultId(""));
    dispatch(updateSearchCardVisibility(false));
    dispatch(updateFeedbackSubmittedIds({} as FeedbackIds));
    history.push(`?${queryParams.toString()}`);
  };

  const filterItems = searchFilterList.map((filterItem) => ({
    id: filterItem.filter_key,
    icon: filterItem.icon,
    value: filterItem.label,
    subItems: filterItem.filters?.map((subFilter) => ({
      id: subFilter.filter_key,
      icon: subFilter.icon,
      value: subFilter.label
    }))
  }));
  return (
    <MultiLevelDropdown
      title="Sources"
      startIcon={
        <AEIcon
          name="crowdsource"
          color={theme.palette.slate.slate40}
          className="start-icon"
        />
      }
      isSelected={
        Boolean(state.filter || state.subFilter) &&
        state.filter !== ISearchFilter.ALL
      }
    >
      {filterItems.map((item) =>
        item.subItems?.length ? (
          <MultiLevelDropdown
            key={item.id}
            title={item.value}
            multiLevel
            startIcon={<img src={item.icon} alt={item.id} />}
            endIcon={
              <AEIcon
                name="arrow_forward_ios"
                className="arrow"
                size="1rem"
                color={
                  state.filter === item.id
                    ? theme.palette.primary.main
                    : theme.palette.slate.slate40
                }
              />
            }
            placement="right"
          >
            {item.subItems.map((subItem) => (
              <DropdownItem
                item={subItem}
                isSelected={subItem.id === state.subFilter}
                onSelect={() => handleSelect(item, subItem)}
                key={subItem.id}
              />
            ))}
          </MultiLevelDropdown>
        ) : (
          <DropdownItem
            item={item}
            isSelected={item.id === state.filter}
            onSelect={() => handleSelect(item)}
            key={item.id}
          />
        )
      )}
    </MultiLevelDropdown>
  );
};
