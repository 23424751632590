import { GetDashcamSessionByTicketIdResponse } from "@aptedge/lib-ui/src/types/dashcam";
import { getBackendUrl } from "../../../config";
import { request } from "../../../utils/request";

async function getDashcamSessionByTicketId(
  ticketId?: string
): Promise<GetDashcamSessionByTicketIdResponse | undefined> {
  if (!ticketId) return;
  const url = `${getBackendUrl()}/api/dashcam/sessionbyticketid/${ticketId}`;

  const req = request(url);
  return req;
}

export { getDashcamSessionByTicketId };
