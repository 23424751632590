import { Checkbox, MenuItem, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
import React, { FC, ReactNode } from "react";

export type DropdownItemType = {
  id: string;
  value: string;
  icon: ReactNode;
  subItems?: DropdownItemType[];
};

type Props = {
  item: DropdownItemType;
  isSelected: boolean;
  onSelect: (filter: DropdownItemType, subFilter?: DropdownItemType) => void;
};
export const DropdownItem: FC<Props> = ({ item, isSelected, onSelect }) => {
  const theme = useTheme();
  const onMenuItemClick = (): void => {
    onSelect(item);
  };

  return (
    <MenuItem disabled={item.subItems?.length === 0}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          flexGrow: 1,
          color: theme.palette.slate.slate40, // Default color for Stack
          transition: "color 0.3s",
          "&:hover .text": {
            color: theme.palette.slate.slate80
          },
          "&:hover .checkBox": {
            color: `${theme.palette.slate.slate80} !important`
          },
          "&:hover .material-symbols-outlined": {
            color: `${theme.palette.slate.slate80} !important`
          }
        }}
        onClick={onMenuItemClick}
      >
        {typeof item.icon === "string" ? (
          <img src={item.icon} alt={item.id} width={16} />
        ) : (
          item.icon
        )}
        <Typography
          variant="body2"
          className="text"
          color={theme.palette.slate.slate40}
          sx={{ margin: "0 1rem" }}
        >
          {item.value}
        </Typography>
        <Checkbox
          size="small"
          className="checkBox"
          checked={isSelected}
          sx={{
            marginLeft: "auto",
            color: theme.palette.slate.slate40
          }}
        />
      </Stack>
    </MenuItem>
  );
};
