import { BetaLabel } from "@aptedge/lib-ui/src/components/BetaLabel/BetaLabel";
import { Pill } from "@aptedge/lib-ui/src/components/Pill/Pill";
import { SentimentBar } from "@aptedge/lib-ui/src/components/SentimentBar/SentimentBar";
import { TextEditor } from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  EntityType,
  ITicketInfo,
  IUserListData,
  TemplateLocation
} from "@aptedge/lib-ui/src/types/entities";
import { sanitizeUrl } from "@aptedge/lib-ui/src/utils/sanitizeUrl";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { WebCacheKey } from "../../clients/cache";
import { fetchTemplates } from "../../clients/Templates/fetchTemplates";
import TicketPreviewContent from "../../pages/GlobalSearch/SearchResultPreview/TicketPreviewContent";
import { toEdgePath } from "../../routes/toPath";
import { IUpdateTicketRequest } from "../../types/clients";
import { DisplayDate } from "../../utils/time";
import { Highlight } from "../Highlight/Highlight";
import { LabelsWidget } from "../LabelWidget/LabelsWidget";
import { TicketFrustrationInfo } from "./TicketFrustrationInfo";
import "./TicketContent.scss";

interface Props {
  ticket: ITicketInfo;
  userData?: IUserListData[];
  isDisabled?: boolean;
  className?: string;
  onTicketUpdate: (update: IUpdateTicketRequest) => void;
}

function TicketContent(props: Props): React.ReactElement {
  const { ticket, isDisabled, onTicketUpdate, className } = props;
  const history = useHistory();
  const { flags } = useFlags();
  const jiraReferences =
    ticket.urlReferences?.filter((j) => !!j.productIssueId) || [];
  const searchWords = ticket.frustration ? [ticket.frustration.text] : [];
  const customer = ticket.customer;
  const fetchTemplatesClient = useQuery(WebCacheKey.TEMPLATES, fetchTemplates);
  const template = fetchTemplatesClient.data?.find(
    (t) => t.location === TemplateLocation.TICKET_DESCRIPTION
  );

  return (
    <div className={`ticket-content ${className}`}>
      <h3 className="subject">
        <Highlight
          autoEscape={true}
          searchWords={searchWords}
          textToHighlight={ticket.subject}
          highlightTag={(props) => (
            <Tooltip
              content={
                <TicketFrustrationInfo
                  highlightedText={props.children}
                  frustration={ticket.frustration}
                />
              }
              placement="top-end"
            >
              <span className="highlighted">{props.children}</span>
            </Tooltip>
          )}
        />
      </h3>
      <div className="ticket-info">
        <main>
          <LabelsWidget
            labels={ticket.themes.map((t) => ({
              id: t.id,
              name: t.name,
              type: EntityType.THEME
            }))}
            searchFilter={(label) => label.type === EntityType.THEME}
            onChange={(labels) =>
              onTicketUpdate({
                themes: labels.map((l) => ({ id: l.id }))
              })
            }
            disabled={isDisabled}
          />
          <div className="main-section agent-description">
            <h4 className="mb-3">Agent Description</h4>
            <TextEditor
              content={ticket.summary}
              templateText={template?.content}
              placeholder="Add a description..."
              onSave={(summary) => onTicketUpdate({ summary })}
              readOnly={isDisabled}
              isImageDropAllowed={false}
            />
          </div>
          <hr />
          <TicketPreviewContent ticket={ticket} />
        </main>
        <aside>
          {ticket.frustration && flags.sentimentScore && (
            <section>
              <h4>
                Frustration Score
                <BetaLabel />
              </h4>
              <SentimentBar className="pt-2" score={ticket.frustration.score} />
            </section>
          )}
          <section>
            <h4>Status</h4>
            <Tooltip
              content={`Closed on ${DateTime.fromSeconds(
                ticket.closedOn
              ).toLocaleString(DateTime.DATETIME_MED)}`}
              disabled={ticket.closedOn === -1}
            >
              <Pill className="status" text={capitalize(ticket.status)} />
            </Tooltip>
          </section>
          <section>
            <h4>Priority</h4>
            {capitalize(ticket.priority)}
          </section>
          <section>
            <h4>Assigned to</h4>
            {!!ticket.assigneeUser ? (
              <div className="user">
                <div>{ticket.assigneeUser.name}</div>

                <small className={classNames("text-muted")}>
                  {ticket.assigneeUser.email}
                </small>
              </div>
            ) : (
              <div className="no-data">N/A</div>
            )}
          </section>
          <section>
            <h4>Requested by</h4>
            {!!ticket.requesterUser ? (
              <div className="user">
                <div>{ticket.requesterUser.name}</div>
                <small className={classNames("text-muted")}>
                  {ticket.requesterUser.email}
                </small>
              </div>
            ) : (
              <div className="no-data">N/A</div>
            )}
          </section>
          {ticket.products && (
            <section>
              <h4>Products</h4>
              {ticket.products.map((product) => (
                <Pill key={product.id} text={product.name} />
              ))}
            </section>
          )}
          {ticket.features && (
            <section>
              <h4>Features</h4>
              {ticket.features.map((feature) => (
                <Pill key={feature.id} text={feature.name} />
              ))}
            </section>
          )}
          {ticket.space && (
            <section>
              <h4>Space</h4>
              <Pill key={ticket.space} text={ticket.space} />
            </section>
          )}
          {ticket.ticketType && (
            <section>
              <h4>Ticket Type</h4>
              <Pill key={ticket.ticketType} text={ticket.ticketType} />
            </section>
          )}
          {ticket.reason && (
            <section>
              <h4>Reason</h4>
              <Pill key={ticket.reason} text={ticket.reason} />
            </section>
          )}
          {customer && (
            <section>
              <h4>Customer</h4>
              <Pill text={customer.name} />
            </section>
          )}
          <section>
            <h4>Jira References</h4>
            {!!jiraReferences.length ? (
              <div>
                {jiraReferences.map((r) => {
                  const santizedUrl = sanitizeUrl(r.url);
                  return (
                    <div key={r.id}>
                      <a
                        href={santizedUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {r.productIssueId}
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="no-data">No Jira references</div>
            )}
          </section>
          <section>
            <h4>Linked Edges</h4>
            {!!ticket.edges.length ? (
              ticket.edges.map((e) => (
                <div
                  key={`linked-edge-${e}`}
                  className={classNames("edge-link", {
                    disabled: history.location.pathname === toEdgePath(e)
                  })}
                  onClick={() => history.push(toEdgePath(e))}
                >{`EDGE-${e}`}</div>
              ))
            ) : (
              <div className="no-data">No Edges linked</div>
            )}
          </section>
          <section>
            <h4>Custom Fields</h4>
            {!!ticket.fields?.length ? (
              ticket.fields.map((f) => (
                <div
                  key={`custom-field-${f.name}`}
                  className="d-flex flex-column align-items-left"
                >
                  <span className="text-muted">{f.name}</span>
                  <span>
                    {f.value.match("^https?") ? (
                      <a href={f.value}>{f.value}</a>
                    ) : (
                      f.value
                    )}
                  </span>
                </div>
              ))
            ) : (
              <div className="no-data">No Custom Fields defined</div>
            )}
          </section>
          <section className="ticket-metadata">
            <div className="ticket-age">
              <span>Created on </span>
              {DateTime.fromSeconds(ticket.createdOn).toLocaleString(
                DisplayDate.FULL
              )}
            </div>
            <div className="ticket-age">
              <span>Last updated on </span>
              {DateTime.fromSeconds(ticket.updatedOn).toLocaleString(
                DisplayDate.FULL
              )}
            </div>
            <a href={ticket.url} target="_blank" rel="noreferrer">
              View ticket in your ticketing system
            </a>
          </section>
        </aside>
      </div>
    </div>
  );
}

export { TicketContent };
