import {
  AptAnswersFilterRule,
  AptAnswersFilterRuleUpdate
} from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import styles from "./FilterRulesItem.module.scss";
import { FilterRulesItemConditionSelect } from "./FilterRulesItemConditionSelect";
import { FilterRulesItemFieldSelect } from "./FilterRulesItemFieldSelect";
import { FilterRulesItemValueInput } from "./FilterRulesItemValueInput";

interface FilterRulesItemProps {
  rule: AptAnswersFilterRule;
  fields: string[];
  filterConditions: string[];
  idx: number;
  handleRuleUpdate: (o: AptAnswersFilterRuleUpdate) => void;
  handleDeleteRule: (idx: number) => void;
  isTop: boolean;
  isBottom: boolean;
}

const FilterRulesItem: FC<FilterRulesItemProps> = ({
  rule,
  fields,
  filterConditions,
  idx,
  handleRuleUpdate,
  handleDeleteRule,
  isTop,
  isBottom
}) => {
  return (
    <div className={styles.rulesItemContainer} data-testid="filter-rule-item">
      <FilterRulesItemFieldSelect
        rule={rule}
        fields={fields}
        idx={idx}
        handleRuleUpdate={handleRuleUpdate}
        isTop={isTop}
        isBottom={isBottom}
      />
      <FilterRulesItemConditionSelect
        rule={rule}
        filterConditions={filterConditions}
        idx={idx}
        handleRuleUpdate={handleRuleUpdate}
        isTop={isTop}
        isBottom={isBottom}
      />
      <FilterRulesItemValueInput
        rule={rule}
        idx={idx}
        handleRuleUpdate={handleRuleUpdate}
        handleDeleteRule={handleDeleteRule}
        isTop={isTop}
        isBottom={isBottom}
      />
    </div>
  );
};

export { FilterRulesItem };
