import {
  AptAnswersFilter,
  AptAnswersFilterPurpose,
  AptAnswersFilterTypeValues,
  AptAnswersIntegrationsObject
} from "@aptedge/lib-ui/src/types/entities";

type Args = {
  filterName: string;
  filtersData: AptAnswersFilterTypeValues;
  integrationsData: { [key: string]: string[] };
  filterPurpose: AptAnswersFilterPurpose;
};

export function mergeFiltersWithIntegrations({
  filterName,
  filtersData,
  integrationsData,
  filterPurpose
}: Args): AptAnswersIntegrationsObject[] {
  const integrations: AptAnswersIntegrationsObject[] = [];

  for (const key in integrationsData) {
    const integration: AptAnswersIntegrationsObject = {
      name: key,
      fields: integrationsData[key],
      filter: undefined
    };

    const filterData = filtersData?.[filterName];
    const filter: AptAnswersFilter | undefined = filterData?.find((filter) => {
      const filterRules = filter.filterRules;
      if (filterRules.length) {
        const filterRule = filterRules[0];
        if (filterRule.integration === integration.name) {
          if (filterRule.fieldsStr === "all-fields") {
            filter.manageAllSelected = true;
            filter.filterRules = [];
          } else if (
            filterPurpose === AptAnswersFilterPurpose.SHOW &&
            filterRules.length > 0
          ) {
            filter.manageAllSelected = true;
          } else {
            filter.manageAllSelected = false;
          }
          return true;
        } else {
          return false;
        }
      }
      return false;
    });

    integration.filter = filter
      ? filter
      : { id: null, filterRules: [], manageAllSelected: false };

    if (integration.fields.length > 0) {
      integrations.push(integration);
    }
  }

  return integrations;
}
