import { MenuItem, Box, Stack, Typography, useTheme } from "@mui/material";
import { forwardRef, ReactNode } from "react";

type SubMenuButtonType = {
  handleToggle: () => void;
  startIcon: ReactNode;
  title: string;
  endIcon: ReactNode;
};

export const SubMenuButton = forwardRef<HTMLButtonElement, SubMenuButtonType>(
  (props, ref) => {
    const { handleToggle, startIcon, title, endIcon } = props;
    const theme = useTheme();
    return (
      <MenuItem onClick={handleToggle}>
        <Box width="100%" ref={ref}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              flexGrow: 1,
              color: theme.palette.slate.slate40, // Default color for Stack
              transition: "color 0.3s",
              "&:hover .hover-text": {
                color: theme.palette.slate.slate80
              },
              "&:hover .material-symbols-outlined": {
                color: `${theme.palette.slate.slate80} !important`
              }
            }}
          >
            {startIcon}
            <Typography
              variant="body2"
              className="hover-text"
              color={theme.palette.slate.slate40}
              sx={{
                margin: "0 1rem"
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                marginLeft: "auto",
                padding: ".5rem .75rem"
              }}
            >
              {endIcon}
            </Box>
          </Stack>
        </Box>
      </MenuItem>
    );
  }
);
