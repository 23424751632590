import * as React from "react";
import styles from "./FilterRulesHeader.module.scss";

const FilterRulesHeader: React.FC = () => {
  return (
    <div className={styles.container} data-testid="filter-rule-header">
      <div className={styles.label}>Field</div>
      <div className={styles.label}>Condition</div>
      <div className={styles.label}>Value</div>
    </div>
  );
};

export { FilterRulesHeader };
