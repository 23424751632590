import {
  AptAnswersFilterType,
  AptAnswersIntegrationsObject
} from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import { FilterSaveButton } from "./FilterSaveButton";
import { IntegrationItem } from "./IntegrationItem";
import {
  IntegrationUpdate,
  updateIntegrationState
} from "./utils/updateIntegrationState";

interface AptAnswersFiltersProps {
  integrations: AptAnswersIntegrationsObject[];
  setIntegrationValues: (integrations: AptAnswersIntegrationsObject[]) => void;
  filterType: AptAnswersFilterType;
  handleItemSave?: () => void;
  setHasCustomFieldsToggled?: (hasCustomFieldsToggled: boolean) => void;
  setShowConfirmationModal?: (showConfirmationModal: boolean) => void;
  handleRuleCollapse?: () => void;
}

const AptAnswersFilters: FC<AptAnswersFiltersProps> = ({
  integrations,
  setIntegrationValues,
  filterType,
  handleItemSave,
  setHasCustomFieldsToggled,
  setShowConfirmationModal,
  handleRuleCollapse
}) => {
  const handleRulesUpdate = ({
    integrationName,
    updateType,
    ruleIdx,
    ruleUpdate
  }: IntegrationUpdate): void => {
    const updatedIntegrationValues = updateIntegrationState({
      integrationValues: integrations,
      integrationUpdate: { integrationName, updateType, ruleIdx, ruleUpdate },
      filterType
    });
    if (setHasCustomFieldsToggled instanceof Function) {
      setHasCustomFieldsToggled(true);
    }
    setIntegrationValues(updatedIntegrationValues);
  };

  const handleSave = (): void => {
    if (handleItemSave instanceof Function) {
      handleItemSave();
      if (handleRuleCollapse instanceof Function) {
        handleRuleCollapse();
      }
      if (setHasCustomFieldsToggled instanceof Function) {
        setHasCustomFieldsToggled(false);
      }
      if (setShowConfirmationModal instanceof Function) {
        setShowConfirmationModal(false);
      }
    }
  };

  return (
    <div>
      {integrations.map((integration, idx) => (
        <IntegrationItem
          key={integration.name}
          filterType={filterType}
          integration={integration}
          isTop={idx === 0}
          isBottom={idx === integrations.length - 1}
          handleRulesUpdate={handleRulesUpdate}
        />
      ))}
      {!!handleItemSave ? (
        <FilterSaveButton handleSave={handleSave} integrations={integrations} />
      ) : null}
    </div>
  );
};

export { AptAnswersFilters };
