import {
  ICreateOktaSourceRequest,
  IOktaSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createOktaSource(
  args: ICreateOktaSourceRequest
): Promise<IOktaSource> {
  const url = `${getBackendUrl()}/okta/collect`;
  const options: RequestInit = post(args);
  return request(url, options);
}

export { createOktaSource };
