import { FC } from "react";
import Switch from "react-switch";
import styles from "./FilterManageAllOption.module.scss";

interface FilterManageAllOptionProps {
  manageAllSelected: boolean;
  handleManageAllClick: () => void;
  integrationName: string;
}

const FilterManageAllOption: FC<FilterManageAllOptionProps> = ({
  manageAllSelected,
  handleManageAllClick,
  integrationName
}) => {
  const message = `Show results from ${integrationName}`;
  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <span>{message}</span>
      <Switch
        width={36}
        height={18}
        handleDiameter={16}
        checked={manageAllSelected}
        checkedIcon={false}
        uncheckedIcon={false}
        onChange={handleManageAllClick}
        onColor="#06758C"
        offColor="#F2F4F7"
        activeBoxShadow="none"
        boxShadow="0px 1px 5px 0 var(--shadow)"
        data-testid="integration-toggle"
      />
    </div>
  );
};

export { FilterManageAllOption };
