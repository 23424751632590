import { FiltersProvider } from "@aptedge/lib-ui/src/features/SearchFilters/FiltersContext";
import { Feed } from "@aptedge/lib-ui/src/features/UnifiedUI/Feed/Feed";
import { SearchInput } from "@aptedge/lib-ui/src/features/UnifiedUI/SearchInput/SearchInput";
import { useSearch } from "@aptedge/lib-ui/src/hooks/search/useSearch";
import { Button, Paper, Typography, useTheme } from "@mui/material";
import { FC, useEffect } from "react";

type RecentSearch = {
  query: string;
  answerId: string;
};
export const SearchHome: FC = () => {
  const theme = useTheme();
  const recentSearches: RecentSearch[] = JSON.parse(
    localStorage.getItem("recent_queries") || "[]"
  );
  const user = JSON.parse(localStorage.getItem("ae_user") || "{}");
  const {
    handleNewSearch,
    searchQueryValue,
    setSearchQueryValue,
    resetSearchAndFilters
  } = useSearch("");

  useEffect(() => {
    resetSearchAndFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Feed disableSearch>
      <Typography
        variant="h5"
        color={theme.palette.viking.viking80}
        align="center"
        fontSize="3.5rem"
        textTransform="capitalize"
        fontWeight={900}
      >
        Search {user?.accountName}
      </Typography>
      <FiltersProvider>
        <SearchInput
          expanded
          handleSearch={handleNewSearch}
          searchQuery={searchQueryValue}
          setSearchQuery={setSearchQueryValue}
        />
      </FiltersProvider>
      {!!recentSearches.length && (
        <Paper
          sx={{
            backgroundColor: theme.palette.slate.slate20,
            padding: 2,
            borderRadius: 1,
            width: "100%"
          }}
          elevation={0}
        >
          <Typography
            variant="body2"
            color={theme.palette.slate.slate40}
            sx={{ fontWeight: 500, marginBottom: 1, paddingLeft: ".5rem" }}
          >
            History
          </Typography>
          {recentSearches.map((searchItem) => (
            <Button
              fullWidth
              key={searchItem.query}
              variant="text"
              onClick={() => handleNewSearch(searchItem.query)}
              sx={{
                marginBottom: 0.5,
                color: theme.palette.slate.slate60,
                justifyContent: "flex-start"
              }}
            >
              {searchItem.query}
            </Button>
          ))}
        </Paper>
      )}
    </Feed>
  );
};
