import { createContext, useContext, useReducer, Dispatch, FC } from "react";
import {
  FiltersAction,
  FiltersState,
  filtersReducer
} from "../../redux/reduxSlice/useSearchReducer";
import { ISearchFilter } from "../../types/entities";

type Context = {
  state: FiltersState;
  dispatch: Dispatch<FiltersAction>;
};

const FiltersContext = createContext<Context>({} as Context);

const FiltersProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(filtersReducer, {
    filter: ISearchFilter.ALL,
    subFilter: "",
    productFilter: [],
    quickFilter: []
  });

  return (
    <FiltersContext.Provider value={{ state, dispatch }}>
      {children}
    </FiltersContext.Provider>
  );
};

const useFilters = (): Context => useContext(FiltersContext);

export { FiltersProvider, useFilters };
