import { CopyButton } from "@aptedge/lib-ui/src/components/CopyButton/CopyButton";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import SkeletonLoader from "@aptedge/lib-ui/src/components/SkeletonLoader/SkeletonLoader";
import classNames from "classnames";
import { FC, useState } from "react";
import { useMutation } from "react-query";
import { createSelfServiceToken } from "../../../../../clients/Account/createSelfServiceToken";
import { useFetchSelfServiceToken } from "../../../Apps/SelfServiceConfiguration";
import SectionLayout from "../../../common/SectionLayout/SectionLayout";
import styles from "./styles.module.scss";

const Integration: FC = () => {
  const { data: apiKey, isLoading, refetch } = useFetchSelfServiceToken();
  const [expanded, setExpanded] = useState<boolean>(false);
  const instanceName = window.location.host.split(".")[0];
  const user = {};
  const getJSSnippet = (apiKey?: string): string => `
const aptedgeConfig={apiKey:"${apiKey}",instanceName:"${instanceName}",user:${JSON.stringify(
    user
  )},};!(function(i,s,o,g,r,a,m){(i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)}),(a=s.createElement(o)),(m=s.getElementsByTagName(o)[0]),(a.type="module"),(a.async=1),(a.onload=function(){i[r](aptedgeConfig)}),(a.src=g),m.parentNode.insertBefore(a,m)})(window,document,"script","https://storage.googleapis.com/aptedge-self-service-customers/css/aptedge.js","AptEdge")
`;

  const createTokenMutation = useMutation(createSelfServiceToken, {
    onSuccess: () => refetch()
  });

  if (isLoading) return <SkeletonLoader lines={1} />;
  return (
    <SectionLayout title="Integration" description="">
      <button
        onClick={() => setExpanded((prev) => !prev)}
        className={classNames(styles.snippetBtn, {
          [styles.expandedSnippetCode]: expanded
        })}
      >
        <span>JS Snippet Integration Code</span>

        <AeIcons.ChevronDown />
      </button>
      {expanded && (
        <div className={classNames(styles.integration)}>
          <CopyButton
            text={getJSSnippet(apiKey)}
            event=""
            className={styles.copyButton}
          />
          <pre>
            <code className={styles.jsSnippet}>
              {getJSSnippet(
                apiKey ? "**************" : "API KEY IS NOT GENERATED"
              )}
            </code>
          </pre>
        </div>
      )}
      {expanded && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            createTokenMutation.mutate();
          }}
          className={classNames(styles.generateBtn)}
        >
          {apiKey ? `Regenerate API Key` : `Generate API Key`}
        </button>
      )}
    </SectionLayout>
  );
};
export default Integration;
