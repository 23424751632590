import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswer } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  updateAnswerId,
  updateQuickFilters
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  AptAnswersContextType,
  AptAnswersSearchContextItem,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { useAptAnswersSearchContext } from "../../../hooks/clients/useAptAnswersSearchContext";
import { SearchFilterMenuRoot } from "./components/SearchFilterMenuRoot";

const QuickFiltersMenu: React.FC = () => {
  const {
    quickFiltersIsEmpty: isEmpty,
    quickFiltersIsError: isError,
    isLoading,
    quickFiltersItems: items
  } = useAptAnswersSearchContext();

  const dispatch = useAppDispatch();
  const { history, queryParams } = useQueryParams();
  const { quickFilters } = useAppSelector((state) => state.search);
  const selectedFilterIds = quickFilters.map((qf) => qf.id);

  const addAnswerId = (): void => {
    const answerId = uuidv4();
    queryParams.set(QUERY_PARAMS.ANSWER_ID, answerId);
    dispatch(updateAnswer([]));
    dispatch(updateAnswerId(answerId));
    history.push(`?${queryParams.toString()}`);
  };

  const handleItemClick = (id: string): void => {
    const quickFilterItem = items.find((item) => item.id === id);
    if (!quickFilterItem) {
      return;
    }
    const updatedQuickFilters = selectedFilterIds.includes(id)
      ? quickFilters.filter((qf) => qf.id !== id)
      : [...quickFilters, quickFilterItem];
    addAnswerId();
    dispatch(updateQuickFilters(updatedQuickFilters));
  };

  const handleSelectAll = (): void => {
    const updatedQuickFilters =
      quickFilters.length === items.length ? [] : items;
    addAnswerId();
    dispatch(updateQuickFilters(updatedQuickFilters));
  };

  const handleReset = (): void => {
    if (Boolean(selectedFilterIds.length === 0)) {
      return;
    }
    const updatedQuickFilters: AptAnswersSearchContextItem[] = [];
    addAnswerId();
    dispatch(updateQuickFilters(updatedQuickFilters));
  };

  if (isEmpty || isError || isLoading) {
    return null;
  }

  return (
    <div>
      <SearchFilterMenuRoot
        contextType={AptAnswersContextType.QUICK_FILTER}
        label="Quick Filters"
        icon={<AeIcons.QuickFiltersMenu />}
        count={quickFilters.length}
        items={items}
        enableSelectAll={false}
        selectedItems={selectedFilterIds}
        handleItemClick={handleItemClick}
        handleSelectAll={handleSelectAll}
        handleReset={handleReset}
      />
    </div>
  );
};

export { QuickFiltersMenu };
