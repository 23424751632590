import { useSearchFilterData } from "@aptedge/lib-ui/src/features/SearchFilters/useSearchFilter";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateIsRuleExpanded } from "@aptedge/lib-ui/src/redux/reduxSlice/aptAnswerSlice";
import {
  AptAnswersFilterType,
  AptAnswersFilterRuleUpdate,
  AptAnswersIntegrationsObject,
  AptAnswersFilterPurpose
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { FC, MouseEvent } from "react";
import { FilterActions } from "./FilterActions";
import { FilterDescription } from "./FilterDescription";
import { FilterHeader } from "./FilterHeader";
import { FilterManageAllOption } from "./FilterManageAllOption";
import { FilterRules } from "./FilterRules";
import styles from "./IntegrationItem.module.scss";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";
import { getIntegrationInfo } from "./utils/getIntegrationInfo";
import {
  IntegrationStateUpdateType,
  IntegrationUpdate
} from "./utils/updateIntegrationState";

interface IntegrationItemProps {
  filterType: AptAnswersFilterType;
  integration: AptAnswersIntegrationsObject;
  isTop: boolean;
  isBottom: boolean;
  handleRulesUpdate: (arg0: IntegrationUpdate) => void;
}

const IntegrationItem: FC<IntegrationItemProps> = ({
  filterType,
  integration,
  isTop,
  isBottom,
  handleRulesUpdate
}) => {
  const { isRuleExpanded } = useAppSelector((state) => state.aptAnswer);
  const dispatch = useAppDispatch();
  const { filterPurpose, filterConditions } = getAptAnswersFilterInfo(
    filterType
  );

  const { searchFilterList } = useSearchFilterData();
  const integrationInfo = getIntegrationInfo(
    integration.name,
    searchFilterList
  );
  const integrationName = integrationInfo.label;
  const integrationIcon = integrationInfo.iconSrc;

  const handleExpandClick = (): void => {
    const newRuleExpanded = {
      ...isRuleExpanded,
      [integrationName]: !isRuleExpanded[integrationName]
    };
    dispatch(updateIsRuleExpanded(newRuleExpanded));
  };

  const handleCollapseClick = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
  };

  const manageAllSelected = integration.filter?.manageAllSelected ?? false;
  const manageAllToggleState =
    filterPurpose === AptAnswersFilterPurpose.HIDE
      ? !manageAllSelected
      : manageAllSelected;

  const filterRules = integration?.filter?.filterRules;
  const hasFilterRules = filterRules && filterRules.length > 0;

  const handleAddRule = (): void => {
    handleRulesUpdate({
      integrationName: integration.name,
      updateType: IntegrationStateUpdateType.ADD_RULE,
      ruleIdx: filterRules?.length ?? 0
    });
  };

  const handleDeleteRule = (idx: number): void => {
    handleRulesUpdate({
      integrationName: integration.name,
      updateType: IntegrationStateUpdateType.DELETE_RULE,
      ruleIdx: idx
    });
  };

  const handleManageAllClick = (): void => {
    if (manageAllSelected) {
      handleRulesUpdate({
        integrationName: integration.name,
        updateType: IntegrationStateUpdateType.UNSELECT_ALL,
        ruleIdx: filterRules?.length ?? 0
      });
    } else {
      handleRulesUpdate({
        integrationName: integration.name,
        updateType: IntegrationStateUpdateType.SELECT_ALL,
        ruleIdx: filterRules?.length ?? 0
      });
    }
  };

  const handleRuleUpdate = ({
    idx,
    key,
    value
  }: AptAnswersFilterRuleUpdate): void => {
    handleRulesUpdate({
      integrationName: integration.name,
      updateType: IntegrationStateUpdateType.UPDATE_RULE,
      ruleIdx: idx,
      ruleUpdate: { idx, key, value }
    });
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerTop]: isTop,
        [styles.containerBottom]: isBottom
      })}
    >
      <FilterHeader
        integrationName={integrationName}
        integrationIcon={integrationIcon}
        filterPurpose={filterPurpose}
        manageAllSelected={manageAllToggleState}
        rules={integration.filter?.filterRules}
        isExpanded={isRuleExpanded[integrationName]}
        handleExpandClick={handleExpandClick}
      />
      {isRuleExpanded[integrationName] && (
        <div className={styles.contentContainer} onClick={handleCollapseClick}>
          <div className={styles.manageAllContainer}>
            <FilterManageAllOption
              integrationName={integrationName}
              manageAllSelected={manageAllToggleState}
              handleManageAllClick={handleManageAllClick}
            />
          </div>
          {hasFilterRules && manageAllToggleState && (
            <>
              <FilterDescription integrationName={integrationName} />
              <FilterRules
                rules={integration.filter?.filterRules ?? []}
                fields={integration.fields}
                filterConditions={filterConditions}
                handleRuleUpdate={handleRuleUpdate}
                handleDeleteRule={handleDeleteRule}
                ruleEditIsDisabled={manageAllToggleState}
              />
            </>
          )}
          {manageAllToggleState && (
            <div className={styles.actionsContainer}>
              <FilterActions
                handleAddRule={handleAddRule}
                addRuleIsDisabled={manageAllToggleState}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { IntegrationItem };
