import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { Skeleton } from "@mui/material";
import { FC, useRef } from "react";
import { useQuery } from "react-query";
import { fetchCssConfig } from "../../clients/CssConfig/fetchCssConfig";
import { EmptyDashboard } from "./EmptyDashboard";
import styles from "./styles.module.scss";

export const Usage: FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { data, isLoading } = useQuery([CACHE_KEY.CSS_CONFIG], fetchCssConfig);

  const analyticsDashboardLink = data?.internal_config.dashboard_link;

  if (isLoading)
    return (
      <div className={styles.analytics}>
        <Skeleton animation="wave" className={styles.loader} />
      </div>
    );

  if (!analyticsDashboardLink) return <EmptyDashboard />;

  return (
    <div className={styles.analytics}>
      <iframe
        className={styles.iframe}
        ref={iframeRef}
        id="posthog_analytics"
        width="100%"
        height="100%"
        title="PostHog Analytics"
        allowFullScreen
        src={analyticsDashboardLink}
      />
    </div>
  );
};
