import * as React from "react";
import styles from "./FilterDescription.module.scss";

interface FilterDescriptionProps {
  integrationName: string;
}

const FilterDescription: React.FC<FilterDescriptionProps> = ({
  integrationName
}) => {
  const description = `Only show ${integrationName} results that meet these rules`;
  return <p className={styles.description}>{description}</p>;
};

export { FilterDescription };
