import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import IOSSwitch from "@aptedge/lib-ui/src/components/IOSSwitch/IOSSwitch";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { IUserListData, UserRole } from "@aptedge/lib-ui/src/types/entities";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import React from "react";
import { useAuth } from "../../../context/AuthContext";
import "./AdminDashboard.scss";

interface IProps {
  user: IUserListData;
  className?: string;
  onRoleSelected: (role: UserRole) => void;
  onBanUser: (banned: boolean) => void;
  onResetPassword: () => void;
  index: number;
}

const getUserRole = (value: UserRole): UserRole[] => {
  return Object.values(UserRole).filter((item) => item !== value) as UserRole[];
};

const UserListItem: React.FC<IProps> = ({
  user,
  onRoleSelected,
  onBanUser,
  onResetPassword,
  index
}) => {
  const { user: loggedUser } = useAuth();
  const text = user?.email ? capitalize(user?.email?.charAt(0)) : "N / A";
  const avatarColorTeal = index % 2;

  return (
    <div className="user-list">
      <div className="user-assignee">
        <Avatar
          color={avatarColorTeal ? "var(--primary-hover)" : "var(--danger)"}
          text={text}
          size="40px"
        />
      </div>
      <Tooltip content={<span>{user.email}</span>} placement="top">
        <div className={classNames("user-email", { banned: user.banned })}>
          {user.email}
        </div>
      </Tooltip>
      <div
        className="user-status"
        onClick={(e) => e.stopPropagation()}
        role="button"
      >
        <Dropdown.Container
          disabled={loggedUser?.email === user.email}
          className="options"
        >
          <Dropdown.Toggle>
            {user.role} <Icon icon={faCaretDown} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {getUserRole(user.role).map((role: UserRole) => {
              return (
                <Dropdown.Item onClick={() => onRoleSelected(role)} key={role}>
                  {role}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown.Container>
      </div>
      <IOSSwitch
        checked={!user.banned}
        onChange={() => onBanUser(!user.banned)}
        disabled={loggedUser?.email === user.email}
      />
      <button disabled={user.banned} onClick={onResetPassword}>
        <Icon icon={faRedo} />
      </button>
    </div>
  );
};

export { UserListItem };
