import Helmet from "react-helmet";
import { Route } from "react-router-dom";
import { Routes } from "../../routes/routes";
import { Integrations } from "./Integrations";
import { AnalyticsNav } from "./Nav/AnalyticsNav";
import { Usage } from "./Usage";

const AnalyticsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Analytics</title>
      </Helmet>
      <AnalyticsNav />

      <Route exact path={Routes.ANALYTICS}>
        <Usage />
      </Route>
      <Route exact path={Routes.ANALYTICS_INTEGRATIONS}>
        <Integrations />
      </Route>
    </>
  );
};

export { AnalyticsPage };
