import { IIssueInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchIssueById(id: string): Promise<IIssueInfo> {
  const url = `${getBackendApiUrl()}/v1/issues/${id}/`;

  return request(url);
}

export { fetchIssueById };
