import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import { FC } from "react";
import AEIcon from "../../components/GMIcon/Icon";
import theme from "../../themeConfigs/muiTheme";
import { ProductFilters } from "./components/ProductFilters";
import { QuickFilters } from "./components/QuickFilters";
import { SourcesFilters } from "./components/SourcesFilters";

type Props = {
  sendButtonClick: () => void;
};

export const SearchFilters: FC<Props> = ({ sendButtonClick }) => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={2} flex={1}>
      <Stack direction="row" spacing={1.5}>
        <SourcesFilters />
        <ProductFilters />
        <QuickFilters />
      </Stack>
      <IconButton size="medium" onClick={sendButtonClick}>
        <AEIcon name="arrow_circle_right" color={theme.palette.slate.slate40} />
      </IconButton>
    </Stack>
  );
};
