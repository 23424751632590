import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hook";
import { updateAnswer } from "../../redux/reduxSlice/answerGPTSlice";
import {
  setAnswerStage,
  AnswerStage
} from "../../redux/reduxSlice/embeddedAppSlice";
import {
  resetSearch,
  updateAnswerId,
  updateProductFilters,
  updateQuickFilters,
  updateSearchFilter,
  updateSearchFilterSubType,
  updateSearchQuery
} from "../../redux/reduxSlice/searchSlice";
import { UnifiedRoutes } from "../../routes/unifiedRoutes";
import {
  AptAnswersSearchContextItem,
  ISearchFilter,
  QUERY_PARAMS,
  SearchFilterSubType
} from "../../types/entities";
import { generateUniqueId } from "../../utils/utils";
import { useQueryParams } from "../useQueryParams";

type UseSearch = {
  searchQueryValue: string;
  setSearchQueryValue: (value: string) => void;
  handleNewSearch: (
    searchQuery: string,
    filter?: ISearchFilter,
    subFilter?: SearchFilterSubType,
    productFilter?: AptAnswersSearchContextItem[],
    quickFilter?: AptAnswersSearchContextItem[]
  ) => void;
  handleFollowUpSearch: (
    searchQuery: string,
    filter?: ISearchFilter,
    subFilter?: SearchFilterSubType,
    productFilter?: AptAnswersSearchContextItem[],
    quickFilter?: AptAnswersSearchContextItem[],
    answerId?: string
  ) => void;
  resetSearchAndFilters: () => void;
};
const saveQueriesToLocalStorage = (query: string, answerId: string): void => {
  const recentQueries = JSON.parse(
    localStorage.getItem("recent_queries") || "[]"
  ).slice(0, 4);

  // prevent adding duplicate queries
  if (recentQueries.some((q: { query: string }) => q.query === query)) return;
  const updatedQueries = [{ query, answerId }, ...recentQueries];
  localStorage.setItem("recent_queries", JSON.stringify(updatedQueries));
};

export const useSearch = (query: string): UseSearch => {
  const [searchQueryValue, setSearchQueryValue] = useState(query ?? "");
  const dispatch = useAppDispatch();
  const { history, queryParams } = useQueryParams();
  const { myProducts } = useAppSelector((state) => state.search);

  const updateSearchQueryParam = (
    searchQuery: string,
    answerId?: string
  ): void => {
    queryParams.set(QUERY_PARAMS.QUERY, searchQuery);
    if (answerId) queryParams.set(QUERY_PARAMS.ANSWER_ID, answerId);
  };
  const resetSearchAndFilters = (): void => {
    dispatch(resetSearch());
    dispatch(updateAnswerId(""));
    dispatch(updateProductFilters(myProducts));
    dispatch(updateQuickFilters([]));
  };

  const handleUpdateFilter = (
    filter?: ISearchFilter,
    subFilter?: SearchFilterSubType,
    productFilter?: AptAnswersSearchContextItem[],
    quickFilter?: AptAnswersSearchContextItem[]
  ): void => {
    if (filter) {
      dispatch(updateSearchFilter(filter));
      dispatch(updateSearchFilterSubType(""));
    }
    if (subFilter) {
      dispatch(updateSearchFilterSubType(subFilter));
    }
    if (productFilter) {
      dispatch(updateProductFilters(productFilter));
    }
    if (quickFilter) {
      dispatch(updateQuickFilters(quickFilter));
    }
  };

  const handleNewSearch = (
    searchQuery: string,
    filter?: ISearchFilter,
    subFilter?: SearchFilterSubType,
    productFilter?: AptAnswersSearchContextItem[],
    quickFilter?: AptAnswersSearchContextItem[]
  ): void => {
    if (!searchQuery.trim()) return;
    const answerId = generateUniqueId();
    resetSearchAndFilters();
    dispatch(updateAnswer([]));
    dispatch(updateAnswerId(answerId));
    dispatch(updateSearchQuery(searchQuery));
    handleUpdateFilter(filter, subFilter, productFilter, quickFilter);
    dispatch(setAnswerStage(AnswerStage.question));
    updateSearchQueryParam(searchQuery, answerId);
    saveQueriesToLocalStorage(searchQuery, answerId);
    history.push(`${UnifiedRoutes.GLOBAL_SEARCH}?${queryParams.toString()}`);
  };
  const handleFollowUpSearch = (
    searchQuery: string,
    filter?: ISearchFilter,
    subFilter?: SearchFilterSubType,
    productFilter?: AptAnswersSearchContextItem[],
    quickFilter?: AptAnswersSearchContextItem[],
    answerId?: string
  ): void => {
    if (!searchQuery.trim()) return;

    if (answerId) {
      updateSearchQueryParam(searchQuery, answerId);
      window.open(
        `${UnifiedRoutes.GLOBAL_SEARCH}?${queryParams.toString()}`,
        "_self"
      );
    } else {
      dispatch(updateSearchQuery(searchQuery));
      dispatch(setAnswerStage(AnswerStage.question));
      updateSearchQueryParam(searchQuery);
      history.push(`?${queryParams.toString()}`);
    }
    handleUpdateFilter(filter, subFilter, productFilter, quickFilter);
  };
  return {
    searchQueryValue,
    setSearchQueryValue,
    handleNewSearch,
    handleFollowUpSearch,
    resetSearchAndFilters
  };
};
