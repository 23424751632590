import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { IOktaSource } from "@aptedge/lib-ui/src/types/entities";
import { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createOktaSource } from "../../../clients/Authentication/createOktaSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { UseDataSourceResult } from "../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { OktaConfigForm, OktaFormData } from "./OktaConfigForm";

interface Props {
  dataSource: UseDataSourceResult<IOktaSource> | null;
}

const OktaConfig: React.FunctionComponent<Props> = (props) => {
  const { dataSource } = props;
  const oktaSource = dataSource?.dataSource ?? null;
  const hasOktaSource = Boolean(dataSource?.dataSource?.oktaDomain);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState(ClientStatus.PROMPT);

  const addSource = useMutation(createOktaSource, {
    onError: () => {
      setStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setShowModal(false);
      setStatus(ClientStatus.SUCCESS);
      dataSource?.reload();
    }
  });

  const onSubmit = (formData: OktaFormData): void => {
    setStatus(ClientStatus.LOADING);
    addSource.mutate({
      ...formData
    });
  };

  return (
    <div>
      <div className="mb-3">
        <h5>Okta</h5>
        <p>Configure your Okta settings below.</p>
      </div>
      <div>
        <ConfirmationModal
          title="Okta Settings"
          width={ModalWidth.LARGE}
          loadingTitle="Loading..."
          status={status}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <OktaConfigForm dataSource={oktaSource} onSubmit={onSubmit} />
        </ConfirmationModal>
      </div>
      <WithLoading
        isLoading={status === ClientStatus.LOADING}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {hasOktaSource ? (
          <StatusButton status="ok" onClickEdit={() => setShowModal(true)}>
            <>
              <div>Okta</div>
              <p>Okta Domain: {oktaSource?.oktaDomain}</p>
            </>
          </StatusButton>
        ) : (
          <AddButton onClick={() => setShowModal(true)}>
            Connect to Okta
          </AddButton>
        )}
      </WithLoading>
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </div>
  );
};

export { OktaConfig };
