import { Emb_App_Routes } from "@aptedge/lib-support-ui/src/features/EmbeddedApp/EmbeddedApp";
import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateSelectedSearchResultsQuestion,
  updateSelectedSearchResultsQuestionText
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { isWebApp } from "@aptedge/lib-ui/src/utils/event";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Feedback } from "../../Feedback/Feedback";
import Card from "../common/Card/Card";
import styles from "./styles.module.scss";

interface Props {
  questionIndex: number;
  questionText: string;
  showFeedback: boolean;
}
const QuestionFooter: FC<Props> = ({
  questionIndex,
  questionText,
  showFeedback
}) => {
  const { searchResultsByQuestion } = useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();
  const [visibility, setVisibility] = useState(true);
  const { isAnswerLoaded, suggestedFollowupQuestions } = useAppSelector(
    (state) => state.answerGPT
  );
  const { flags } = useFlags();
  const router = useHistory();
  const onCardClick = (): void => {
    dispatch(updateSelectedSearchResultsQuestion(questionIndex));
    dispatch(updateSelectedSearchResultsQuestionText(questionText));
    router.push(Emb_App_Routes.ARTICLES);
  };

  return (
    <>
      {!isWebApp && (
        <Card className={styles.relatedArticlesBtn} onClick={onCardClick}>
          <div className={styles.leftSection}>
            <AEIcon
              name="auto_stories"
              color="#2A767E"
              filled
              size="1.2rem"
              className={styles.icon}
            />
            <span className={styles.label}>Related articles</span>
          </div>
          <div className={styles.rightSection}>
            <span className={styles.label}>
              {searchResultsByQuestion[questionText]?.length}
            </span>
            <AEIcon name="chevron_forward" color="#94A2B7" size="1.2rem" />
          </div>
        </Card>
      )}
      {isAnswerLoaded &&
        !!suggestedFollowupQuestions.length &&
        isWebApp &&
        flags.unifiedUi && (
          <Stack marginY={1}>
            <Typography variant="body2">
              Suggested follow up questions
            </Typography>
            {suggestedFollowupQuestions.map((followup) => (
              <Button
                key={followup}
                variant="contained"
                startIcon={<AEIcon name="bolt" filled />}
                color="default"
                sx={{
                  marginY: 1,
                  justifyContent: "flex-start",
                  textAlign: "left"
                }}
                data-testid="followup-questions"
              >
                {followup}
              </Button>
            ))}
          </Stack>
        )}
      {showFeedback && (
        <Feedback
          isVisible={visibility}
          updateVisibility={setVisibility}
          title={
            <div className={styles.feedbackTitle}>
              <AEIcon
                name="chat_paste_go"
                color="#2A767E"
                className={styles.chatIcon}
                size="1.2rem"
                filled
              />
              <span>Is this answer useful?</span>
            </div>
          }
        />
      )}
    </>
  );
};

export default QuestionFooter;
