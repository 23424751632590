import {
  AptAnswersFilterRule,
  AptAnswersFilterRuleUpdate
} from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import { Select } from "./Select";

interface FilterRulesItemFieldSelectProps {
  rule: AptAnswersFilterRule;
  fields: string[];
  idx: number;
  handleRuleUpdate: (o: AptAnswersFilterRuleUpdate) => void;
  isTop: boolean;
  isBottom: boolean;
}

const FilterRulesItemFieldSelect: FC<FilterRulesItemFieldSelectProps> = ({
  rule,
  fields,
  idx,
  handleRuleUpdate,
  isTop,
  isBottom
}) => {
  const currentOption = fields.includes(rule.fieldsStr)
    ? rule.fieldsStr
    : fields[0];

  const handleUpdate = (value: string): void => {
    handleRuleUpdate({ idx, key: "fieldsStr", value });
  };

  return (
    <Select
      options={fields}
      currentOption={currentOption}
      getOptionLabel={(o) => o}
      handleUpdate={handleUpdate}
      isTop={isTop}
      isRight={false}
      isBottom={isBottom}
      isLeft={true}
    />
  );
};

export { FilterRulesItemFieldSelect };
