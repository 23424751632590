import {
  SearchFilterDropdown,
  SearchFilters
} from "@aptedge/lib-ui/src/services/search/searchFilterService";

interface Map {
  [key: string]: string | undefined;
}

const INTEGRATION_DICT: Map = {
  integration_confluence: "confluence",
  integration_confluence_onprem: "confluence_onprem",
  integration_zendeskkb: "zendesk-knowledge-base",
  integration_salesforce_knowledge: "salesforce-knowledge",
  integration_notion: "notion",
  integration_sharepoint: "sharepoint",
  integration_sharepoint_drive: "sharepoint_drive",
  integration_servicenow: "servicenow-knowledge",
  integration_jira: "jira",
  integration_jira_onprem: "jira_onprem",
  integration_azure_devops: "azure_devops",
  integration_slack: "slack",
  integration_ms_teams: "microsoft-teams"
};

const getIntegrationKey = (
  integrationName: string
): string | undefined | null => {
  if (integrationName.startsWith("ticket_source")) {
    return null;
  } else if (integrationName.startsWith("integration_website_")) {
    return integrationName.replace("integration_website_", "");
  } else if (integrationName in INTEGRATION_DICT) {
    return INTEGRATION_DICT[integrationName];
  } else {
    return null;
  }
};

const getIntegrationFilterInfo = (
  key: string,
  searchFilterList: SearchFilters[]
): SearchFilterDropdown | null => {
  let match = null;
  searchFilterList.forEach((searchFilterMenu) => {
    if (searchFilterMenu.filters?.length) {
      const filterMatch = searchFilterMenu.filters.find((filter) => {
        return filter.key === key;
      });
      if (filterMatch) {
        match = filterMatch;
      }
    }
  });
  return match;
};

type IntegrationInfo = {
  label: string;
  iconSrc: string | null;
};

export const getIntegrationInfo = (
  integrationName: string,
  searchFilterList: SearchFilters[]
): IntegrationInfo => {
  if (integrationName === "source_edges") {
    return {
      label: "Edges",
      iconSrc:
        "https://storage.googleapis.com/aptedge-integrations-assets-customers/edgesIcon.svg"
    };
  }

  if (integrationName === "source_generated_knowledge") {
    return {
      label: "Articles",
      iconSrc:
        "https://storage.googleapis.com/aptedge-integrations-assets-customers/generatedKnowledge.svg"
    };
  }

  if (integrationName === "ticket_source_zendesk") {
    return {
      label: "Zendesk",
      iconSrc:
        "https://storage.googleapis.com/aptedge-integrations-assets-customers/zendesk.svg"
    };
  }

  if (integrationName === "ticket_source_salesforce") {
    return {
      label: "Salesforce",
      iconSrc:
        "https://storage.googleapis.com/aptedge-integrations-assets-customers/salesforce.svg"
    };
  }

  if (integrationName === "integration_confluence_onprem") {
    return {
      label: "Confluence On-Premise",
      iconSrc:
        "https://storage.googleapis.com/aptedge-integrations-assets-customers/confluence.svg"
    };
  }

  if (integrationName === "integration_jira_onprem") {
    return {
      label: "Jira On-Premise",
      iconSrc:
        "https://storage.googleapis.com/aptedge-integrations-assets-customers/jiraIcon.svg"
    };
  }

  if (integrationName === "integration_sharepoint_drive") {
    return {
      label: "SharePoint Drives",
      iconSrc:
        "https://storage.googleapis.com/aptedge-integrations-assets-customers/sharepointIcon.svg"
    };
  }

  if (integrationName === "integration_sharepoint") {
    return {
      label: "SharePoint Pages",
      iconSrc:
        "https://storage.googleapis.com/aptedge-integrations-assets-customers/sharepointIcon.svg"
    };
  }

  const key = getIntegrationKey(integrationName);
  if (!key) {
    return {
      label: integrationName.replace("integration_", ""),
      iconSrc: null
    };
  }

  const info = getIntegrationFilterInfo(key, searchFilterList);
  if (!info) {
    return {
      label: key,
      iconSrc: null
    };
  }

  return { label: info.label, iconSrc: info.icon };
};
