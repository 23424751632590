import { AptAnswersIntegrationsObject } from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import styles from "./FilterSaveButton.module.scss";

interface FilterSaveButtonProps {
  handleSave: () => void;
  integrations: AptAnswersIntegrationsObject[];
}

const FilterSaveButton: FC<FilterSaveButtonProps> = ({
  handleSave,
  integrations
}) => {
  const isSaveButtonDisabled = integrations.some((integration) =>
    integration.filter?.filterRules?.some((rule) => rule.value.trim() === "")
  );

  return (
    <button
      className={styles.saveButton}
      onClick={handleSave}
      disabled={isSaveButtonDisabled}
      data-testid="filter-save-button"
    >
      Save
    </button>
  );
};

export { FilterSaveButton };
