import { GetDashcamSessionByTicketIdResponse } from "@aptedge/lib-ui/src/types/dashcam";
import { injectable } from "inversify";

@injectable()
abstract class DashCamSession {
  abstract getDashCamSession(
    ticketId: string
  ): Promise<GetDashcamSessionByTicketIdResponse>;
}

export { DashCamSession };
