import {
  ICreateSharepointPagesSourceRequest,
  ISharepointPagesSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateSharepointPagesSource(
  args: ICreateSharepointPagesSourceRequest
): Promise<ISharepointPagesSource> {
  const url = `${getBackendUrl()}/api/integrations/sharepoint-pages`;
  const options: RequestInit = patch(args);
  return request(url, options);
}

export { updateSharepointPagesSource };
