import { IAdminPasswordResetResponse } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function resetPassword({
  userId
}: {
  userId: number;
}): Promise<IAdminPasswordResetResponse> {
  const url = `${getBackendApiUrl()}/users/${userId}/reset-password`;
  const options = post({});

  return request(url, options);
}

export { resetPassword };
