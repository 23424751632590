import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hook/hook";
import { ResultsMap, processMappedResults } from "../../utils";

interface UseMappedResultsReturnType {
  mappedResults: ResultsMap;
  totalSearchResults: number;
  searchQuery: string;
  page: number;
  searchResultsByQuestion: Record<string, ICompositeResult[]>;
}

const useMappedResults = (): UseMappedResultsReturnType => {
  const {
    searchResults,
    totalSearchResults,
    searchQuery,
    page,
    searchResultsByQuestion
  } = useAppSelector((state) => state.search);
  const [mappedResults, setMappedResults] = useState<ResultsMap>(
    new Map() as ResultsMap
  );
  const memoisedProcessMappedResults = useCallback(
    () => processMappedResults(searchResults as ICompositeResult[]),
    [searchResults]
  );
  useEffect(() => {
    if (searchResults) {
      const results = memoisedProcessMappedResults();
      setMappedResults(results);
    }
  }, [memoisedProcessMappedResults, searchResults]);

  return {
    mappedResults,
    totalSearchResults,
    searchQuery,
    page,
    searchResultsByQuestion
  };
};

export default useMappedResults;
