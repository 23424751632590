import { getBackendApiUrl } from "@aptedge/lib-ui/src/utils/config";
import { decorateHeadersWithAuth } from "@aptedge/lib-ui/src/utils/request";
export interface IDatalayer<T> {
  event: string;
  data?: T;
}

export const isWebApp =
  !window.location.origin ||
  (window.location.origin.includes("localhost") &&
    !window.location.origin.includes(":4000")) ||
  window.location.origin.includes("aptedge.io");

const generateEventNameByAppType = (event: string): string => {
  if (isWebApp) return `web-app-${event}`;
  return `support-${event}`;
};

export const GTM_EVENTS = {
  GTM_QUESTION_ASKED: generateEventNameByAppType("question-asked"),
  GTM_ANSWER_FEEDBACK_STATUS: generateEventNameByAppType(
    "answer-feedback-status"
  ),
  GTM_ANSWER_FEEDBACK_OVERVIEW: generateEventNameByAppType(
    "answer-feedback-overview"
  ),
  GTM_SEARCH_FEEDBACK_STATUS: generateEventNameByAppType(
    "search-feedback-status"
  ),
  GTM_SEARCH_FEEDBACK_OVERVIEW: generateEventNameByAppType(
    "search-feedback-overview"
  ),
  GTM_FEEDBACK_RATING: generateEventNameByAppType("search-feedback-rating"),

  GTM_ANSWER_COPIED: generateEventNameByAppType("answer-copied"),
  GTM_SELECT_AI_MODAL: generateEventNameByAppType("select-ai-model"),
  GTM_EDGE_TICKET_COPIED: "web-app-edge-ticket-copied",
  GTM_USER_CONTEXT: "user_context",
  GTM_ADDITIONAL_INSTRUCTIONS: generateEventNameByAppType(
    "additional-instructions"
  ),
  GTM_ANSWER_SOURCE_CLICK: generateEventNameByAppType("answer-source-click"),
  GTM_SEARCH: isWebApp ? "web-app-search" : "search-support",
  GTM_INSIGHTS_AUTOSEARCH: generateEventNameByAppType("insights-autosearch"),
  GTM_INSIGHTS_CASELOG_EXPANDED: generateEventNameByAppType(
    "insights-caselog-expanded"
  ),
  GTM_INSIGHTS_NEXTSTEPS_SELECTED: generateEventNameByAppType(
    "insights-nextsteps-selected"
  ),
  GTM_INSIGHTS_NEXTSTEPS_DISMISSED: generateEventNameByAppType(
    "insights-nextsteps-dismissed"
  ),
  GTM_INSIGHTS_NEXTSTEPS_STEP_COPIED: generateEventNameByAppType(
    "insights-nextsteps-step-copied"
  ),
  GTM_WEB_SEARCH_PAGINATION: "web-search-pagination",
  GTM_SUPPORT_SEARCH_PAGINATION: "support-search-pagination",
  GTM_SEARCH_RESULT_PREVIEW_POPOUT: "web-app-search-result-preview-popout",
  GTM_SEARCH_RESULT_LIST_POPOUT: "web-app-search-result-list-popout",
  GTM_SEARCH_RESULT_PREVIEW_TEXT_COPIED:
    "web-app-search-result-preview-text-copied",
  GTM_SEARCH_RESULT_LIST_TEXT_COPIED: "web-app-search-result-list-text-copied",
  GTM_SEARCH_RESULT_FILTER: "web-app-search-result-filter",
  GTM_CLICK_CLEAR_SEARCH: generateEventNameByAppType("click-clear-search"),
  GTM_SUPPORT_CLICK_VIEW_SEARCH_IN_WEB_APP:
    "support-click-view-search-in-web-app",
  GTM_SUPPORT_UNLINK_EDGE: "support-unlink-edge",
  GTM_SUPPORT_CLICK_HOME: "support-click-home",
  GTM_SUPPORT_CLICK_DETAILS: "support-click-details",
  GTM_SUPPORT_CLICK_BACK_TO_HOME: "support-click-back-to-home",
  GTM_SUPPORT_SEARCH_RESULT_FILTER: "support-app-search-result-filter",
  GTM_SUPPORT_SORT_RESULTS: "support-sort-results",
  GTM_SUPPORT_LINK_EDGE: "support-link-edge",
  GTM_CREATE_EDGE: generateEventNameByAppType("create-edge"),
  GTM_SUPPORT_CLICK_VIEW_TICKET_IN_APTEDGE:
    "support-click-view-ticket-in-aptedge",
  GTM_SUPPORT_AUTO_SEARCH: "support-auto-search",
  GTM_SUPPORT_SEARCH_RESULT_INTERACTION: "support-search-result-interaction",
  GTM_SUPPORT_TICKET_SUMMARY_GENERATION: "support-ticket-summary-generation",
  GTM_SUPPORT_TICKET_SUMMARY_INTERACTION: "support-ticket-summary-interaction",
  GTM_SUPPORT_KB_DRAFT_GENERATION: "support-kb-draft-generation",
  GTM_SUPPORT_KB_DRAFT_COPIED: "support-kb-draft-copied",
  GTM_SUPPORT_KB_DRAFT_REGENERATION: "support-kb-draft-regeneration",
  GTM_SUPPORT_TICKET_SUMMARY_COPIED: "support-ticket-summary-copied",
  GTM_TICKET_CLICK: "ticket-click",
  GTM_TEXT_FILTER: "text-filter",
  GTM_CREATE_EDGE_LABEL: "create-edge-label",
  GTM_ARCHIVE_EDGE: "archive-edge",
  GTM_UNARCHIVE_EDGE: "unarchive-edge",
  GTM_CREATE_BULK_REPLY: "create-bulk-reply",
  GTM_MERGE_EDGE: "merge-edge",
  GTM_EDGE_TICKET_CLICK: "edge-ticket-click",
  GTM_MIGRATE_EDGE_EXAMPLE: "migrate-edge-example",
  GTM_ADD_EDGE_EXAMPLE: "add-edge-example",
  GTM_DELETE_EDGE_EXAMPLE: "delete-edge-example",
  GTM_EXPORT_EDGE_EXAMPLE: "export-edge-example",
  GTM_CREATE_EDGE_LINK: "create-edge-link",
  GTM_CREATE_EDGE_COMMENT: "create-edge-comment",
  GTM_UPDATE_EDGE_COMMENT: "update-edge-comment",
  GTM_DELETE_EDGE_COMMENT: "delete-edge-comment",
  GTM_WATCH_EDGE: "watch-edge",
  GTM_UNWATCH_EDGE: "unwatch-edge",
  GTM_UPDATE_AUTO_LINK: "update-auto-link",
  GTM_UPDATE_AUTO_REPLY: "update-auto-reply",
  GTM_UPDATE_EDGE_DESCRIPTION: "update-edge-description",
  GTM_UPDATE_EDGE_SPACE: "update-edge-space",
  GTM_UPDATE_EDGE_TEAM: "update-edge-team",
  GTM_UPLOAD_EDGE_FILE: "upload-edge-file",
  GTM_EDGES_MENU_ITEM: "edges-menu-item",
  GTM_TICKETS_MENU_ITEM: "tickets-menu-item",
  GTM_ANALYTICS_MENU_ITEM: "analytics-menu-item",
  GTM_ARTICLES_MENU_ITEM: "articles-menu-item",
  GTM_CREATE_EDGE_MENU_ITEM: "create-edge-menu-item",
  GTM_SETTINGS: "settings",
  GTM_PROFILE: "profile",
  GTM_INVITE_USER_TEXT: "invite-user-text",
  GTM_MS_OAUTH_CLICKED: "ms-oauth-clicked",
  GTM_OKTA_OAUTH_CLICKED: "okta-oauth-clicked",
  GTM_GOOGLE_OAUTH_CLICKED: "google-oauth-clicked",
  GTM_SUPPORT_TICKET_SUMMARY_FEEDBACK_STATUS:
    "support-ticket-summary-feedback-status",
  GTM_SUPPORT_TICKET_SUMMARY_FEEDBACK_OVERVIEW:
    "support-ticket-summary-feedback-overview",
  GTM_SUPPORT_KB_GEN_FEEDBACK_STATUS: "support-kb-gen-feedback-status",
  GTM_SUPPORT_KB_GEN_FEEDBACK_OVERVIEW: "support-kb-gen-feedback-overview",
  GTM_SUPPORT_CREATE_KB_GEN_ARTICLE: "support-create-kb-gen-article",
  GTM_CREATE_KB_GEN_ARTICLE: "create-kb-gen-article",
  GTM_DISCARD_KB_GEN_ARTICLE: "discard-kb-gen-article",
  GTM_SAVE_KB_GEN_ARTICLE: "save-kb-gen-article",
  GTM_EDIT_KB_GEN_ARTICLE: "edit-kb-gen-article",
  GTM_VIEW_KB_GEN_ARTICLE: "view-kb-gen-article",
  GTM_COPY_KB_GEN_ARTICLE_HTML: "copy-kb-gen-article-html",
  GTM_COPY_KB_GEN_ARTICLE_MARKDOWN: "copy-kb-gen-article-markdown",
  GTM_TICKET_SUMMARY_LOADED: "ticket-summary-loaded",
  GTM_INSIGHT_RESPONSE_LOADED: "insight-response-loaded"
};

export const sendEventViaBackend = <T>({
  event,
  data
}: IDatalayer<T>): Promise<void> => {
  const requestData = {
    headers: {
      "content-type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      event: event,
      properties: data
    })
  };

  const url = `${getBackendApiUrl()}/analytics_events`;

  return fetch(url, decorateHeadersWithAuth(requestData)).then((result) => {
    // This logging is intentional! Please do not delete.
    console.groupCollapsed(
      `%cEvent captured: "${event}"`,
      "color: green; font-weight: 900"
    );
    console.log(data);
    console.groupEnd();
  });
};

export const dataLayerPush = <T>({ event, data }: IDatalayer<T>): void => {
  if (!window.aptedgeEvents) {
    window.aptedgeEvents = [];
  }
  window.aptedgeEvents.push({ event, data });
};

export const dataLayerPushToGTM = <T>({ event, data }: IDatalayer<T>): void => {
  window.dataLayer?.push({ event, data });
};

export const mockGenerateEventNameByAppType = (
  event: string,
  isWebApp: boolean
): string => {
  if (isWebApp) return `web-app-${event}`;
  return `support-${event}`;
};
