import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswer } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { updateAnswerId } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { FiltersType } from "@aptedge/lib-ui/src/redux/reduxSlice/useSearchReducer";
import { generateUniqueId } from "@aptedge/lib-ui/src/utils/utils";
import { isEqual } from "lodash";
import { FC, useEffect } from "react";
import {
  AptAnswersContextType,
  AptAnswersSearchContextItem,
  QUERY_PARAMS
} from "../../../../src/types/entities";
import { useFilters } from "../FiltersContext";
import useAptAnswerFilter from "../useAptAnswerFilter";
import { AptAnswerFilterMenu } from "./AptAnswerFilterMenu";

export const ProductFilters: FC = () => {
  const { isEmpty, isError, isLoading, items } = useAptAnswerFilter(
    AptAnswersContextType.PRODUCT_FILTER
  );
  const { state, dispatch: filterDispatch } = useFilters();

  const { history, queryParams } = useQueryParams();
  const { myProducts, productFilters, searchQuery } = useAppSelector(
    (state) => state.search
  );
  const dispatch = useAppDispatch();

  const selectedFilterIds = state.productFilter.map((pf) => pf.id);

  const addAnswerId = (): void => {
    const answerId = generateUniqueId();
    queryParams.set(QUERY_PARAMS.ANSWER_ID, answerId);
    dispatch(updateAnswer([]));
    dispatch(updateAnswerId(answerId));
    history.push(`?${queryParams.toString()}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleItemClick = (filterItem: any): void => {
    const productFilterItem = items.find((item) => item.id === filterItem.id);
    if (!productFilterItem) {
      return;
    }
    const updatedProductFilters = selectedFilterIds.includes(filterItem.id)
      ? state.productFilter.filter((pf) => pf.id !== filterItem.id)
      : [...state.productFilter, productFilterItem];
    if (
      searchQuery &&
      !isEqual(state.productFilter.length, productFilters.length)
    ) {
      addAnswerId();
    }
    filterDispatch({
      type: FiltersType.SET_PRODUCT_FILTER,
      payload: updatedProductFilters
    });
  };

  const handleSelectAll = (): void => {
    const updatedProductFilters =
      state.productFilter.length === items.length ? [] : items;
    if (
      searchQuery &&
      !isEqual(state.productFilter.length, productFilters.length)
    ) {
      addAnswerId();
    }
    filterDispatch({
      type: FiltersType.SET_PRODUCT_FILTER,
      payload: updatedProductFilters
    });
  };

  const handleReset = (): void => {
    if (Boolean(selectedFilterIds.length === 0)) {
      return;
    }
    if (
      searchQuery &&
      !isEqual(state.productFilter.length, productFilters.length)
    ) {
      addAnswerId();
    }
    filterDispatch({
      type: FiltersType.SET_PRODUCT_FILTER,
      payload: [] as AptAnswersSearchContextItem[]
    });
  };

  useEffect(() => {
    if (Boolean(myProducts.length || productFilters.length)) {
      const filters = Boolean(myProducts.length) ? myProducts : productFilters;
      filterDispatch({
        type: FiltersType.SET_PRODUCT_FILTER,
        payload: filters
      });
    }
  }, [myProducts, filterDispatch, productFilters]);

  if (isEmpty || isError || isLoading) {
    return null;
  }

  return (
    <AptAnswerFilterMenu
      contextType={AptAnswersContextType.PRODUCT_FILTER}
      items={items}
      enableSelectAll={true}
      selectedItems={selectedFilterIds}
      handleItemClick={handleItemClick}
      handleSelectAll={handleSelectAll}
      handleReset={handleReset}
    />
  );
};
