import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SearchFilters } from "../../services/search/searchFilterService";
import {
  ISearchFilter,
  ISearchResultSortingModes,
  QUERY_PARAMS,
  ICompositeResult,
  SearchFilterSubType,
  PaginatedSearchResultInfo,
  ResultOffsetType,
  FeedbackIds,
  AptAnswersSearchContextItem
} from "../../types/entities";
import { isSupportApp } from "../../utils/supportApp";

const SEARCH_PATH = isSupportApp
  ? `?${window.location.hash.split("?")[1]}`
  : window.location.search;

const queryParams = new URLSearchParams(SEARCH_PATH);

const INITIAL_SEARCH_QUERY = queryParams.get(QUERY_PARAMS.QUERY) ?? "";

const INITIAL_FILTER_SUB_TYPE = (queryParams.get(
  QUERY_PARAMS.FILTER_SUB_TYPE
) ?? "") as SearchFilterSubType;

const INITIAL_SEARCH_FILTER = (queryParams.get(QUERY_PARAMS.FILTER) ??
  ISearchFilter.ALL) as ISearchFilter;

const INITIAL_PAGE = Number(queryParams.get(QUERY_PARAMS.PAGE) ?? 1);

const INITIAL_SORT_MODE =
  (queryParams.get(QUERY_PARAMS.SORT) as ISearchResultSortingModes) ??
  ISearchResultSortingModes.RELEVANCE;

const INITIAL_ANSWER_ID = queryParams.get(QUERY_PARAMS.ANSWER_ID) ?? "";
const INITIAL_RESULT_ID = queryParams.get(QUERY_PARAMS.RESULT_ID) ?? "";

type SliceStateType = {
  selectedSearchResult: ICompositeResult;
  selectedSearchResultsQuestion: number;
  selectedSearchResultsQuestionText: string;
  searchCardVisibility: boolean;
  searchResults: ICompositeResult[];
  searchResultsByQuestion: Record<string, ICompositeResult[]>;
  searchResultsExpanded: ICompositeResult[];
  searchResultsInfo?: PaginatedSearchResultInfo;
  isPreviewDataLoading: boolean;
  isSearchFocused: boolean;
  isSearchLoading: boolean;
  searchQuery: string;
  page: number;
  searchFilter: ISearchFilter;
  searchFilterSubType: SearchFilterSubType;
  searchSuggestions: string[];
  sortMode: ISearchResultSortingModes;
  showSuggestion: boolean;
  hasLinkedEdge: boolean;
  totalSearchResults: number;
  autoSearchActive: boolean;
  filters: SearchFilters[];
  myProducts: AptAnswersSearchContextItem[];
  productFilters: AptAnswersSearchContextItem[];
  quickFilters: AptAnswersSearchContextItem[];
  searchResultVisibilityOnEmbeddedSearch: boolean;
  answerId: string;
  searchResultOffsets: ResultOffsetType[];
  showBackButtonInPreview: boolean;
  feedbackSubmittedIds: FeedbackIds;
  initialSearchQuery: boolean;
  pageHtml: string;
  previousPageHtml: string;
  resultId: string;
  answerStreamingStarted: boolean;
};
interface SearchState {
  searchQuery: string;
  searchFilter: ISearchFilter;
  searchFilterSubType: SearchFilterSubType;
  sortMode: ISearchResultSortingModes;
  page: number;
  answerId: string;
  resultId: string;
}
const initialState: SliceStateType = {
  selectedSearchResult: {} as ICompositeResult,
  selectedSearchResultsQuestion: 0,
  selectedSearchResultsQuestionText: "",
  searchCardVisibility: false,
  searchResults: [],
  searchResultsByQuestion: {},
  searchResultsExpanded: [],
  searchResultsInfo: {} as PaginatedSearchResultInfo,
  isPreviewDataLoading: false,
  isSearchFocused: true,
  isSearchLoading: false,
  searchQuery: INITIAL_SEARCH_QUERY,
  page: Number(INITIAL_PAGE),
  searchFilter: INITIAL_SEARCH_FILTER,
  searchSuggestions: [],
  searchFilterSubType: INITIAL_FILTER_SUB_TYPE,
  sortMode: INITIAL_SORT_MODE,
  showSuggestion: false,
  hasLinkedEdge: false,
  filters: [],
  quickFilters: [],
  productFilters: [],
  myProducts: [],
  totalSearchResults: 0,
  autoSearchActive: false,
  searchResultVisibilityOnEmbeddedSearch: false,
  answerId: INITIAL_ANSWER_ID,
  searchResultOffsets: [] as ResultOffsetType[],
  showBackButtonInPreview: false,
  feedbackSubmittedIds: {} as FeedbackIds,
  initialSearchQuery: true,
  pageHtml: "",
  previousPageHtml: "",
  resultId: INITIAL_RESULT_ID,
  answerStreamingStarted: false
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateSelectedSearchResult: (
      state,
      action: PayloadAction<ICompositeResult>
    ) => {
      state.selectedSearchResult = action.payload;
      state.searchCardVisibility = true;
    },
    updateSelectedSearchResultsQuestion: (
      state,
      action: PayloadAction<number>
    ) => {
      state.selectedSearchResultsQuestion = action.payload;
    },
    updateSelectedSearchResultsQuestionText: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedSearchResultsQuestionText = action.payload;
      state.searchResultsByQuestion[action.payload] = state.searchResults;
    },
    updateSearchOnRouteChange: (state, action: PayloadAction<SearchState>) => {
      state.searchQuery = action.payload.searchQuery;
      state.searchFilter = action.payload.searchFilter;
      state.searchFilterSubType = action.payload.searchFilterSubType;
      state.sortMode = action.payload.sortMode;
      state.page = action.payload.page;
      state.answerId = action.payload.answerId;
      state.searchCardVisibility = false;
      state.selectedSearchResult = {} as ICompositeResult;
      state.feedbackSubmittedIds = [];
      state.resultId = action.payload.resultId;
    },
    updateSearchCardVisibility: (state, action) => {
      state.searchCardVisibility = action.payload;
      state.selectedSearchResult = {} as ICompositeResult;
    },
    updateSearchFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateSearchResults: (state, action: PayloadAction<ICompositeResult[]>) => {
      state.searchResults = action.payload;
      state.searchResultsByQuestion[state.selectedSearchResultsQuestionText] =
        action.payload;
      state.searchSuggestions = [];
    },
    addSearchResults: (state, action: PayloadAction<ICompositeResult[]>) => {
      state.searchResults = action.payload;
    },
    updateSearchResultsInfo: (state, action) => {
      state.searchResultsInfo = action.payload;
    },
    updateSearchResultsExpanded: (state, action) => {
      state.searchResultsExpanded = action.payload;
    },
    setIsSearchFocused: (state, action) => {
      state.isSearchFocused = action.payload;
    },
    setIsSearchLoading: (state, action) => {
      state.isSearchLoading = action.payload;
    },
    setIsPreviewDataLoadingState: (state, action) => {
      state.isPreviewDataLoading = action.payload;
    },
    updateSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
      state.initialSearchQuery = false;
      state.isSearchLoading = true;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateSearchFilter: (state, action: PayloadAction<ISearchFilter>) => {
      state.searchFilter = action.payload;
    },
    updateQuickFilters: (
      state,
      action: PayloadAction<AptAnswersSearchContextItem[]>
    ) => {
      state.quickFilters = action.payload;
    },
    updateProductFilters: (
      state,
      action: PayloadAction<AptAnswersSearchContextItem[]>
    ) => {
      state.productFilters = action.payload;
    },
    updateMyProducts: (
      state,
      action: PayloadAction<AptAnswersSearchContextItem[]>
    ) => {
      state.myProducts = action.payload;
    },
    updateSearchFilterSubType: (
      state,
      action: PayloadAction<SearchFilterSubType>
    ) => {
      state.searchFilterSubType = action.payload;
    },
    updateSearchSuggestions: (state, action: PayloadAction<string[]>) => {
      state.searchSuggestions = action.payload;
    },
    updateShowSuggestion: (state, action: PayloadAction<boolean>) => {
      state.showSuggestion = action.payload;
    },
    updateHasLinkedEdge: (state, action: PayloadAction<boolean>) => {
      state.hasLinkedEdge = action.payload;
    },
    resetSearch: (state) => {
      state.hasLinkedEdge = false;
      state.searchQuery = "";
      state.searchFilter = ISearchFilter.ALL;
      state.searchFilterSubType = "";
      state.searchResults = [];
      state.searchResultsExpanded = [];
      state.searchResultsByQuestion = {};
      state.totalSearchResults = 0;
      state.autoSearchActive = false;
      state.isSearchLoading = false;
      state.searchResultOffsets = [] as ResultOffsetType[];
      state.page = 1;
      state.answerId = "";
      state.searchResultsInfo = {} as PaginatedSearchResultInfo;
      state.showBackButtonInPreview = false;
      state.feedbackSubmittedIds = [];
      state.quickFilters = [];
      state.resultId = "";
    },
    updateSortMode: (
      state,
      action: PayloadAction<ISearchResultSortingModes>
    ) => {
      state.sortMode = action.payload;
    },
    // TODO: remove this
    updateTotalSearchResults: (state, action: PayloadAction<number>) => {
      state.totalSearchResults = action.payload;
    },
    updateAutoSearchActive: (state, action: PayloadAction<boolean>) => {
      state.autoSearchActive = action.payload;
    },
    updateSearchResultVisibilityOnEmbeddedApp: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.searchResultVisibilityOnEmbeddedSearch = action.payload;
    },
    updateAnswerId: (state, action: PayloadAction<string>) => {
      state.answerId = action.payload;
      state.searchResults = [];
      state.searchResultsByQuestion = {};
    },
    updateSearchResultOffsets: (
      state,
      action: PayloadAction<ResultOffsetType[]>
    ) => {
      state.searchResultOffsets = action.payload;
    },
    updateShowBackButtonInPreview: (state, action: PayloadAction<boolean>) => {
      state.showBackButtonInPreview = action.payload;
    },
    updateFeedbackSubmittedIds: (state, action: PayloadAction<FeedbackIds>) => {
      state.feedbackSubmittedIds = action.payload;
    },
    updatePageHtml: (state, action: PayloadAction<string>) => {
      state.pageHtml = action.payload;
    },
    updatePreviousPageHtml: (state, action: PayloadAction<string>) => {
      state.previousPageHtml = action.payload;
    },
    updateResultId: (state, action: PayloadAction<string>) => {
      state.resultId = action.payload;
    },
    setAnswerStreamingStarted: (state, action: PayloadAction<boolean>) => {
      state.answerStreamingStarted = action.payload;
    }
  }
});

export const {
  updateSelectedSearchResult,
  updateSelectedSearchResultsQuestion,
  updateSelectedSearchResultsQuestionText,
  updateSearchCardVisibility,
  addSearchResults,
  updateSearchResults,
  updateSearchResultsExpanded,
  updateSearchResultsInfo,
  setIsPreviewDataLoadingState,
  setIsSearchFocused,
  setIsSearchLoading,
  updateSearchQuery,
  updatePage,
  updateSearchFilter,
  updateSearchSuggestions,
  updateShowSuggestion,
  updateSearchFilterSubType,
  updateSortMode,
  updateHasLinkedEdge,
  resetSearch,
  updateSearchOnRouteChange,
  updateAutoSearchActive,
  updateTotalSearchResults,
  updateSearchFilters,
  updateSearchResultVisibilityOnEmbeddedApp,
  updateAnswerId,
  updateShowBackButtonInPreview,
  updateSearchResultOffsets,
  updatePageHtml,
  updatePreviousPageHtml,
  updateFeedbackSubmittedIds,
  updateProductFilters,
  updateQuickFilters,
  updateMyProducts,
  setAnswerStreamingStarted,
  updateResultId
} = searchSlice.actions;

export default searchSlice.reducer;
