import { AptAnswersIntegrationsObject } from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import styles from "./UnsavedModal.module.scss";

type UnsavedModalProps = {
  handleSave: () => void;
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setHasCustomFieldsToggled: React.Dispatch<React.SetStateAction<boolean>>;
  reset: () => void;
  integrations: AptAnswersIntegrationsObject[];
  handleRuleCollapse: () => void;
};

const UnsavedModal: FC<UnsavedModalProps> = ({
  setShowConfirmationModal,
  setHasCustomFieldsToggled,
  handleSave,
  reset,
  integrations,
  handleRuleCollapse
}) => {
  const isSaveButtonDisabled = integrations.some((integration) =>
    integration.filter?.filterRules?.some((rule) => rule.value.trim() === "")
  );

  const resetFilterStates = (): void => {
    setShowConfirmationModal(false);
    setHasCustomFieldsToggled(false);
    handleRuleCollapse();
  };

  const handleClose = (): void => {
    resetFilterStates();
    if (isSaveButtonDisabled) {
      reset();
    } else {
      handleSave();
    }
  };

  const handleUnsavedCancel = (): void => {
    resetFilterStates();
    reset();
  };

  return (
    <>
      <div className={styles.backdrop}></div>
      <div className={styles.confirmationContainer} data-testid="confirm-modal">
        <span className={styles.confirmationHeader}>Save Changes</span>
        <span className={styles.confirmationBody}>
          You have unsaved changes that may be lost if you leave.
          <br />
          Would you like to save them?
        </span>
        <div className={styles.confirmationActions}>
          <button className={styles.confirm} onClick={handleClose}>
            Save
          </button>
          <button className={styles.cancel} onClick={handleUnsavedCancel}>
            Discard
          </button>
        </div>
      </div>
    </>
  );
};

export { UnsavedModal };
