import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { Skeleton } from "@mui/material";
import { FC, useRef } from "react";
import { useQuery } from "react-query";
import { fetchDashboard } from "../../clients/Dashboard/fetchDashboard";
import { EmptyDashboard } from "./EmptyDashboard";
import styles from "./styles.module.scss";

export const Integrations: FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { data, isLoading } = useQuery([CACHE_KEY.DASHBOARD], fetchDashboard, {
    // backend currently generates a token that's valid for 10 minutes, but let's keep it simple
    // and just get a new one every time
    cacheTime: 0
  });

  const dashboardLink = data?.iframe_url;

  if (isLoading)
    return (
      <div className={styles.analytics}>
        <Skeleton animation="wave" className={styles.loader} />
      </div>
    );

  if (!dashboardLink) return <EmptyDashboard />;

  return (
    <div className={styles.analytics}>
      <iframe
        className={styles.iframe}
        ref={iframeRef}
        id="posthog_analytics"
        width="100%"
        height="100%"
        title="PostHog Analytics"
        allowFullScreen
        src={dashboardLink}
      />
    </div>
  );
};
