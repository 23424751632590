import { SDKClient } from "@aptedge/lib-support-ui/src/clients/sdkClient";
import NotificationBlock from "@aptedge/lib-support-ui/src/components/NotificationBlock/NotificationBlock";
import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import SkeletonLoader from "@aptedge/lib-ui/src/components/SkeletonLoader/SkeletonLoader";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import useMappedResults from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/useMappedResults";
import Body from "@aptedge/lib-ui/src/features/AiAnswer/components/Body/Body";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  AnswerQuestion,
  AnswerAction,
  updateSelectedQuestion,
  updateAnswerCardVisibility
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { updateResultId } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  ApiChatStatus,
  ICompositeResult,
  QUERY_PARAMS,
  SearchResultSource
} from "@aptedge/lib-ui/src/types/entities";
import { Button, Stack, Typography } from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";
import { FC, useEffect, useRef, MouseEvent } from "react";
import { GlobalSearchList } from "../../../../../../app-web/src/components/GlobalSearch/GlobalSearchList/GlobalSearchList";
import useArrowNavigationSL from "../../../../../../app-web/src/components/GlobalSearch/GlobalSearchList/hooks/useArrowNavigationSL";
import QuestionFooter from "../../../EmbeddedSearch/SearchResultPage/QuestionFooter";
import { Query } from "../../../UnifiedUI/Query/Query";
import styles from "./MultiStepAnswer.module.scss";

interface Props {
  truncateLongAnswer?: boolean;
  resultsMap?: Map<string, ICompositeResult>;
  client?: SDKClient;
  aptEdgeBaseUrl?: string;
  questionWrapperComponent?: React.ElementType;
  showFirstQueryTitle?: boolean;
  questionQueryTitleClass?: string;
  showQuestionFooter?: boolean;
  showSearchResult?: boolean;
  scrollAnswerIntoView?: boolean;
  disableMaximize?: boolean;
  refetchAnswer?: () => void;
  toggleDrawer?: (isViewAll: boolean) => void;
}

const MultiStepAnswer: FC<Props> = ({
  truncateLongAnswer,
  resultsMap,
  client,
  aptEdgeBaseUrl,
  questionWrapperComponent,
  showFirstQueryTitle,
  questionQueryTitleClass,
  showQuestionFooter,
  showSearchResult,
  scrollAnswerIntoView,
  disableMaximize,
  refetchAnswer,
  toggleDrawer
}) => {
  const { updateParams } = useQueryParams();

  const {
    mappedResults,
    totalSearchResults,
    searchQuery,
    page,
    searchResultsByQuestion
  } = useMappedResults();

  const { answer, isAnswerLoaded, isAnswerLoading } = useAppSelector(
    (state) => state.answerGPT
  );
  const dispatch = useAppDispatch();
  const { flags } = useFlags();
  const containerRef = useRef<HTMLDivElement>(null);
  const Wrapper =
    questionWrapperComponent ?? (({ children }) => <>{children}</>);

  const handleViewAll = (
    e: MouseEvent<HTMLButtonElement>,
    question: AnswerQuestion
  ): void => {
    e.stopPropagation();
    dispatch(updateSelectedQuestion(question));
    dispatch(updateAnswerCardVisibility(false));
    toggleDrawer?.(true);
  };

  const handleSearchResultClick = (resultId: string): void => {
    updateParams(QUERY_PARAMS.RESULT_ID, resultId);
    dispatch(updateResultId(resultId));
    dispatch(updateAnswerCardVisibility(false));
    toggleDrawer?.(false);
  };

  const {
    searchContainerRef,
    ...searchResultsArrowNavigation
  } = useArrowNavigationSL(page, handleSearchResultClick);

  useEffect(() => {
    if (!scrollAnswerIntoView || !containerRef.current) return;

    containerRef.current.scrollIntoView({
      block: "start",
      behavior: (isAnswerLoading ? "smooth" : "instant") as ScrollBehavior
    });
  }, [isAnswerLoading, scrollAnswerIntoView]);

  const animationFeedbackStyle = useSpring({
    opacity: isAnswerLoaded ? 1 : 0,
    transform: isAnswerLoaded ? "translateX(0px)" : "translateX(40px)",
    delay: 500,
    config: { tension: 200, friction: 20, duration: 600 }
  });

  const animationSearchResultStyle = useSpring({
    opacity: isAnswerLoaded ? 1 : 0,
    transform: "translateY(0px)",
    delay: 500,
    config: { tension: 200, friction: 20, duration: 600 }
  });

  return (
    <>
      {answer.map((question: AnswerQuestion, i: number) => {
        const isLastAnswer = i === answer.length - 1;
        const hasError = question.actions.some(
          (action) => action.status === ApiChatStatus.ERROR
        );

        // Only display the error block if it's the last question, otherwise, skip it
        if (hasError && !isLastAnswer) return null;

        // If the last question has an error, show an error message with a retry button
        if (hasError && isLastAnswer) {
          return (
            <div
              key={i}
              className={classNames(styles.container, {
                [styles.lastAnswer]: isLastAnswer && i > 0 && !disableMaximize
              })}
              id={isLastAnswer ? "lastAnswer" : ""}
              ref={isLastAnswer ? containerRef : null}
            >
              <h2 className={questionQueryTitleClass}>{searchQuery}</h2>
              <NotificationBlock
                text={{
                  error: "Could not complete your query."
                }}
                isError
                actionBtn={
                  <button
                    onClick={() => refetchAnswer && refetchAnswer()}
                    className={styles.actionButton}
                  >
                    Retry
                  </button>
                }
              />
            </div>
          );
        }

        // Render normal question-answer pairs
        return (
          <div
            key={i}
            className={classNames(styles.container, {
              [styles.lastAnswer]: isLastAnswer && i > 0 && !disableMaximize
            })}
            id={isLastAnswer ? "lastAnswer" : ""}
            ref={isLastAnswer ? containerRef : null}
          >
            {question.actions.map((action: AnswerAction, j: number) => {
              return (
                <div key={j}>
                  {/* Show the question title for the first query or subsequent ones */}
                  {(showFirstQueryTitle || i > 0) &&
                    (flags.unifiedUi ? (
                      <Query
                        query={question.query}
                        marginBottom="2rem"
                        querySource={SearchResultSource.SEARCH_PAGE}
                      />
                    ) : (
                      <h2 className={questionQueryTitleClass}>
                        {question.query}
                      </h2>
                    ))}
                  {j > 0 && (
                    <>
                      <hr />
                      {action.newSearches && (
                        <i>Searching for {action.newSearches.join(", ")}</i>
                      )}
                    </>
                  )}
                  {action.answer && (
                    <Wrapper className={styles.wrapperStyle}>
                      <Body
                        answer={action.answer}
                        resultsMap={resultsMap ?? mappedResults}
                        totalResults={totalSearchResults}
                        truncateLongAnswer={truncateLongAnswer}
                        client={client}
                        aptEdgeBaseUrl={aptEdgeBaseUrl}
                        answerBodyClass={styles.answerBody}
                      />
                    </Wrapper>
                  )}
                  {showSearchResult &&
                    !hasError &&
                    !!searchResultsByQuestion[question.query]?.length && (
                      <animated.div
                        style={isLastAnswer ? animationSearchResultStyle : {}}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row"
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={(theme) => ({
                              fontWeight: "600",
                              paddingY: "0.5rem",
                              color: theme.palette.slate.slate80
                            })}
                            data-testid="search-result-count"
                          >
                            {`Search results (${
                              searchResultsByQuestion[question.query]?.length
                            })`}
                          </Typography>
                          <Button
                            onClick={(e) => handleViewAll(e, question)}
                            variant="text"
                            color="secondary"
                            endIcon={
                              <AEIcon name="arrow_right_alt" size="1.25rem" />
                            }
                            sx={{
                              marginLeft: "auto"
                            }}
                            disableRipple={true}
                          >
                            View all
                          </Button>
                        </Stack>
                        <GlobalSearchList
                          arrowNavigation={searchResultsArrowNavigation}
                          searchResultSource={SearchResultSource.SEARCH_PAGE}
                          maxResultLength={3}
                          searchResultByQuestion={
                            searchResultsByQuestion[question.query]
                          }
                          question={question}
                        />
                      </animated.div>
                    )}
                </div>
              );
            })}
            {/* Show a loading skeleton while the answer is being loaded */}
            {isLastAnswer && !isAnswerLoaded && (
              <div className="suggested-answer-body">
                <SkeletonLoader lines={2} />
              </div>
            )}

            {/* Show the footer with feedback options if applicable */}
            {showQuestionFooter && !hasError && (
              <animated.div style={isLastAnswer ? animationFeedbackStyle : {}}>
                <QuestionFooter
                  questionIndex={i}
                  questionText={question.query}
                  showFeedback={isLastAnswer}
                />
              </animated.div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default MultiStepAnswer;
