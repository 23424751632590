import { Skeleton } from "@mui/material";
import { FC } from "react";

interface SkeletonLoaderProps {
  lines: number;
  containerClassName?: string;
  loaderClassName?: string;
  lightMode?: boolean;
  animation?: "pulse" | "wave";
}
const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  lines,
  containerClassName,
  loaderClassName,
  lightMode,
  animation = "pulse"
}) => (
  <div className={containerClassName}>
    {[...Array(lines)].map((_, i) => (
      <Skeleton
        className={loaderClassName}
        data-testid="answer-skeleton"
        key={i}
        animation={animation}
        sx={
          lightMode
            ? {
                backgroundColor: "rgba(255, 255, 255, .11)",
                transformOrigin: "0 15%"
              }
            : { transformOrigin: "0 15%" }
        }
      />
    ))}
  </div>
);

export default SkeletonLoader;
