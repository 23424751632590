import {
  AptAnswersFilterRule,
  AptAnswersFilterRuleUpdate
} from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import { FilterRulesHeader } from "./FilterRulesHeader";
import { FilterRulesItem } from "./FilterRulesItem";

interface FilterRulesProps {
  rules: AptAnswersFilterRule[];
  fields: string[];
  filterConditions: string[];
  handleRuleUpdate: (o: AptAnswersFilterRuleUpdate) => void;
  handleDeleteRule: (idx: number) => void;
  ruleEditIsDisabled: boolean;
}

const FilterRules: FC<FilterRulesProps> = ({
  rules,
  fields,
  filterConditions,
  handleRuleUpdate,
  handleDeleteRule,
  ruleEditIsDisabled
}) => {
  return (
    <div className="w-100">
      {ruleEditIsDisabled && (
        <>
          {rules.length > 0 ? <FilterRulesHeader /> : null}
          {rules.map((rule, idx) => (
            <FilterRulesItem
              key={idx}
              idx={idx}
              rule={rule}
              fields={fields}
              filterConditions={filterConditions}
              handleRuleUpdate={handleRuleUpdate}
              handleDeleteRule={handleDeleteRule}
              isTop={idx === 0}
              isBottom={idx === rules.length - 1}
            />
          ))}
        </>
      )}
    </div>
  );
};

export { FilterRules };
