import { IOktaSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchOktaSource(): Promise<IOktaSource> {
  const url = `${getBackendUrl()}/okta/tap`;
  return request(url);
}

export { fetchOktaSource };
