import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { setSharepointDriveTapData } from "@aptedge/lib-ui/src/redux/reduxSlice/sharepointDriveSlice";
import {
  IntegrationStatus,
  INTEGRATION_STATUS,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import { useEffect, useState, useCallback, FC } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createSharepointDriveSource } from "../../../clients/ArticleSources/createSharepointDriveSource";
import { updateSharepointDriveSource } from "../../../clients/ArticleSources/updateSharepointDriveSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { getBackendUrl } from "../../../config";
import { useSharepointDriveSource } from "../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { SharepointDriveAddSite } from "./SharepointDriveAddSite";
import {
  SharepointDriveConfigForm,
  SharepointDriveFormData
} from "./SharepointDriveConfigForm";
import { SharepointDriveSiteList } from "./SharepointDriveSiteList";

const SharepointDriveConfig: FC = () => {
  const dataSource = useSharepointDriveSource();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAddSites, setShowAddSites] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = useState(ClientStatus.PROMPT);
  const dispatch = useAppDispatch();

  const sharepointDriveSource = dataSource?.dataSource ?? null;

  const addSource = useMutation(createSharepointDriveSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      const url = `${getBackendUrl()}/api/integrations/sharepoint-drive/login`;
      window.location.href = url;
    }
  });

  const updateSource = useMutation(updateSharepointDriveSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      const url = `${getBackendUrl()}/api/integrations/sharepoint-drive/login`;
      window.location.href = url;
    }
  });

  const onFormSubmit = (formData: SharepointDriveFormData): void => {
    setRedirectStatus(ClientStatus.LOADING);
    sharepointDriveSource
      ? updateSource.mutate(formData)
      : addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (dataSource?.error?.code === GATEWAY_TIMEOUT)
      return INTEGRATION_STATUS.TIME_OUT;
    if (sharepointDriveSource?.status === OAuthStatus.AUTHENTICATED)
      return INTEGRATION_STATUS.OK;
    return INTEGRATION_STATUS.ERROR;
  };

  const handleReduxDispatch = useCallback((): void => {
    dispatch(setSharepointDriveTapData(sharepointDriveSource ?? null));
  }, [dispatch, sharepointDriveSource]);

  const getDatasource = (): void => {
    dataSource.reload();
  };

  useEffect(() => {
    handleReduxDispatch();
  }, [handleReduxDispatch]);

  const isConnectionExist =
    sharepointDriveSource || getStatus() === INTEGRATION_STATUS.TIME_OUT;
  const isLoaderVisible = addSource.isLoading || dataSource.isLoading;

  return (
    <div>
      <WithLoading isLoading={isLoaderVisible} fallback={<Spinner />}>
        <div className="mb-3 mt-5">
          <h5>SharePoint Drives</h5>
          <p>Configure your SharePoint Drives settings below.</p>
        </div>

        <div>
          {isConnectionExist ? (
            <StatusButton
              status={getStatus()}
              onClickEdit={() => setShowModal(true)}
            >
              <>
                <div>SharePoint Drives</div>
                <p className="tenantId">
                  Tenant ID: {sharepointDriveSource?.tenantId}
                </p>
              </>
            </StatusButton>
          ) : (
            <AddButton onClick={() => setShowModal(true)}>Connect</AddButton>
          )}
        </div>
      </WithLoading>
      <ConfirmationModal
        title="SharePoint Drive Settings"
        width={ModalWidth.MEDIUM}
        loadingTitle="Loading...."
        status={redirectStatus}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <p className="mb-4">Configure your settings below.</p>
        <SharepointDriveConfigForm
          source={sharepointDriveSource}
          onSubmit={onFormSubmit}
        />
      </ConfirmationModal>
      {sharepointDriveSource?.status === OAuthStatus.AUTHENTICATED && (
        <>
          <div className="col-md-12">
            <div className="mt-4 d-flex justify-content-between align-items-start">
              <div>
                <p>
                  Connect Drives by adding or removing SharePoint Sites from
                  your tenant below.
                </p>
              </div>
              <div>
                <Button
                  color="secondary"
                  data-testid="sharepointdrive-add-site"
                  onClick={() => setShowAddSites(true)}
                >
                  <Icons.Plus />
                  <span>Add Site</span>
                </Button>
              </div>
            </div>
          </div>
          {showAddSites && (
            <SharepointDriveAddSite
              getDatasource={getDatasource}
              onCancel={() => setShowAddSites(false)}
            />
          )}

          <SharepointDriveSiteList getDatasource={getDatasource} />
        </>
      )}
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </div>
  );
};

export { SharepointDriveConfig };
