import {
  Button,
  Divider,
  Drawer,
  Stack,
  Typography,
  styled
} from "@mui/material";
import { FC } from "react";
import { GlobalSearchList } from "../../../../app-web/src/components/GlobalSearch/GlobalSearchList/GlobalSearchList";
import { IUseArrowNavigationSL } from "../../../../app-web/src/components/GlobalSearch/GlobalSearchList/hooks/useArrowNavigationSL";
import SearchResultPreviewCard from "../../../../app-web/src/pages/GlobalSearch/SearchResultPreview/SearchResultPreviewCard";
import AEIcon from "../../components/GMIcon/Icon";
import { useAppDispatch } from "../../redux/hook/hook";
import { updateResultId } from "../../redux/reduxSlice/searchSlice";
import { SearchResultSource } from "../../types/entities";
import { Query } from "../UnifiedUI/Query/Query";

const StyledDrawer = styled(Drawer)(() => ({
  width: "70%",
  "& .MuiDrawer-paper": {
    width: "70%",
    boxSizing: "border-box",
    paddingBottom: "1rem"
  }
}));

type SearchResultDrawerProps = {
  isDrawerOpen: boolean;
  searchResultsArrowNavigation: Omit<
    IUseArrowNavigationSL,
    "searchContainerRef"
  >;
  showFeedback: boolean;
  toggleDrawer: (isViewAll: boolean) => void;
  searchQuery: string;
  maxResultLength: number;
};

const SearchResultDrawer: FC<SearchResultDrawerProps> = ({
  isDrawerOpen,
  searchResultsArrowNavigation,
  showFeedback,
  toggleDrawer,
  searchQuery,
  maxResultLength
}) => {
  const dispatch = useAppDispatch();
  const handleToggle = (): void => {
    dispatch(updateResultId(""));
    toggleDrawer(false);
  };

  return (
    <StyledDrawer open={isDrawerOpen} onClose={handleToggle} anchor="right">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        padding={2}
      >
        <Typography variant="body1">Search results</Typography>
        <Button
          endIcon={<AEIcon name="close" />}
          onClick={handleToggle}
          color="secondary"
          sx={{ ":hover": { background: "transparent" }, padding: 0 }}
        />
      </Stack>
      <Stack flex={"0 1"} padding="0 1rem 1rem 1rem">
        <Query
          query={searchQuery}
          querySource={SearchResultSource.SEARCH_DRAWER}
          editable={false}
        />
      </Stack>
      <Divider />
      <Stack
        direction="row"
        padding={2}
        sx={{
          overflowY: "auto"
        }}
      >
        <GlobalSearchList
          arrowNavigation={searchResultsArrowNavigation}
          searchResultSource={SearchResultSource.SEARCH_DRAWER}
          maxResultLength={maxResultLength}
        />
        <SearchResultPreviewCard showFeedback={showFeedback} />
      </Stack>
    </StyledDrawer>
  );
};

export default SearchResultDrawer;
