export type SlateColors = {
  slate00: string;
  slate10: string;
  slate20: string;
  slate30: string;
  slate40: string;
  slate50: string;
  slate60: string;
  slate70: string;
  slate80: string;
  slate90: string;
  slate100: string;
  slate110: string;
  slate120: string;
};

export type VikingColors = {
  viking00: string;
  viking10: string;
  viking20: string;
  viking30: string;
  viking40: string;
  viking50: string;
  viking60: string;
  viking70: string;
  viking80: string;
  viking90: string;
  viking100: string;
};

export type RedColors = {
  red00: string;
  red10: string;
  red20: string;
  red30: string;
  red40: string;
  red50: string;
  red60: string;
  red70: string;
  red80: string;
  red90: string;
  red100: string;
};

export type OrangeColors = {
  orange00: string;
  orange10: string;
  orange20: string;
  orange30: string;
  orange40: string;
  orange50: string;
  orange60: string;
  orange70: string;
  orange80: string;
  orange90: string;
  orange100: string;
};

export type GreenColors = {
  green00: string;
  green10: string;
  green20: string;
  green30: string;
  green40: string;
  green50: string;
  green60: string;
  green70: string;
  green80: string;
  green90: string;
  green100: string;
};

export type BlueColors = {
  blue00: string;
  blue10: string;
  blue20: string;
  blue30: string;
  blue40: string;
  blue50: string;
  blue60: string;
  blue70: string;
  blue80: string;
  blue90: string;
  blue100: string;
};

export const customColors = {
  slate: {
    slate00: "#FFFFFF",
    slate10: "#F6F7F9",
    slate20: "#EAECF1",
    slate30: "#CBD2DC",
    slate40: "#B0BACA",
    slate50: "#94A2B7",
    slate60: "#7789A4",
    slate70: "#5C6E89",
    slate80: "#48566B",
    slate90: "#333D4C",
    slate100: "#1D222B",
    slate110: "#12161B",
    slate120: "#000000"
  },
  viking: {
    viking00: "#F4FAFB",
    viking10: "#E0F3F5",
    viking20: "#BAE4E8",
    viking30: "#94D4DB",
    viking40: "#71C7D0",
    viking50: "#4AB7C3",
    viking60: "#379AA5",
    viking70: "#2A767E",
    viking80: "#1D5258",
    viking90: "#123236",
    viking100: "#0B2022"
  },
  red: {
    red00: "#FEF2F2",
    red10: "#FEE2E2",
    red20: "#FECACA",
    red30: "#FCA5A5",
    red40: "#F87171",
    red50: "#EF4444",
    red60: "#DC2626",
    red70: "#B91C1C",
    red80: "#991B1B",
    red90: "#7F1D1D",
    red100: "#450A0A"
  },
  orange: {
    orange00: "#FFFBEB",
    orange10: "#FEF3C7",
    orange20: "#FDE68A",
    orange30: "#FCD34D",
    orange40: "#FBBF24",
    orange50: "#F59E0B",
    orange60: "#D97706",
    orange70: "#B45309",
    orange80: "#92400E",
    orange90: "#78350F",
    orange100: "#431407"
  },
  green: {
    green00: "#F0FDF4",
    green10: "#DCFCE7",
    green20: "#BBF7D0",
    green30: "#86EFAC",
    green40: "#4ADE80",
    green50: "#22C55E",
    green60: "#16A34A",
    green70: "#15803D",
    green80: "#166534",
    green90: "#14532D",
    green100: "#052E16"
  },
  blue: {
    blue00: "#EEF2FF",
    blue10: "#E0E7FF",
    blue20: "#C7D2FE",
    blue30: "#A5B4FC",
    blue40: "#818CF8",
    blue50: "#6366F1",
    blue60: "#4F46E5",
    blue70: "#4338CA",
    blue80: "#3730A3",
    blue90: "#312E81",
    blue100: "#1E1B4B"
  }
};
