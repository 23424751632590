import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type RuleExpandedPerIntegration = {
  [key: string]: boolean;
};

type SliceStateType = {
  isRuleExpanded: RuleExpandedPerIntegration;
};

const initialState: SliceStateType = {
  isRuleExpanded: {} as RuleExpandedPerIntegration
};

const aptAnswerSlice = createSlice({
  name: "aptAnswer",
  initialState,
  reducers: {
    updateIsRuleExpanded: (
      state,
      action: PayloadAction<RuleExpandedPerIntegration>
    ) => {
      state.isRuleExpanded = action.payload;
    }
  }
});

export const { updateIsRuleExpanded } = aptAnswerSlice.actions;

export default aptAnswerSlice.reducer;
