import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

export type DashboardResponse = {
  iframe_url: string;
};

function fetchDashboard(): Promise<DashboardResponse> {
  const url = `${getBackendApiUrl()}/dashboard/`;

  return request(url);
}

export { fetchDashboard };
