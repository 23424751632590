import {
  AnswerAction,
  AnswerQuestion
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  ApiAction,
  ApiAnswer,
  ApiChatStatus,
  ApiQuestion,
  GQADocSearchResult,
  ICompositeResult,
  SearchFilterSubType
} from "@aptedge/lib-ui/src/types/entities";
import { ConfigResponse } from "@aptedge/lib-ui/src/types/selfService";
import { dataLayerPush, GTM_EVENTS } from "@aptedge/lib-ui/src/utils/event";
import { generateTopSearchResults } from "@aptedge/lib-ui/src/utils/generator";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { isEmpty } from "lodash";

export function triggerSearchEvent(
  response: TimedResponse<GQADocSearchResult>,
  searchQuery: string,
  activeSourceType: SearchFilterSubType,
  page: number,
  timers: Record<string, unknown>,
  autoSearchActive: boolean,
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false,
  user?: { email: string; name: string }
): void {
  const { data, startTimeMillis, endTimeMillis } = response;
  const { items, total } = data;
  const event = autoSearchActive
    ? GTM_EVENTS.GTM_SUPPORT_AUTO_SEARCH
    : GTM_EVENTS.GTM_SEARCH;

  const loadingTimeSeconds = (endTimeMillis - startTimeMillis) / 1000;
  dataLayerPush({
    event,
    data: {
      search_query: searchQuery,
      search_context: activeSourceType,
      latency_seconds: loadingTimeSeconds,
      page: page,
      total_search_results: total,
      top_search_results: generateTopSearchResults(items),
      timers,
      is_proactive_answer: isProactiveAnswer,
      is_self_service_search:
        !isEmpty(cssConfig) ||
        (!!cssConfig && "proactive_answers" in cssConfig),

      user,
      email: user?.email
    }
  });
}

export const triggerQuestionAskedEvent = (
  searchQuery: string,
  answerQuestions: AnswerQuestion[],
  searchResults: ICompositeResult[],
  totalSearchResults: number,
  startTimeMillis: number | null,
  answerId: string,
  timers?: Record<string, unknown>,
  user?: { email: string; name: string },
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false,
  limitedAnswerRetention = false
): void => {
  const lastQuestion = answerQuestions[answerQuestions.length - 1];
  const lastAction = lastQuestion.actions[lastQuestion.actions.length - 1];
  if (!lastAction) {
    return;
  }

  const answer = lastAction.answer;
  const sourcesIndex = answer.toLowerCase().indexOf("sources:");
  const loadingTimeSeconds = startTimeMillis
    ? (Date.now() - startTimeMillis.valueOf()) / 1000
    : null;

  const data: Record<string, unknown> = {
    latency_seconds: loadingTimeSeconds,
    question: searchQuery,
    total_search_results: totalSearchResults,
    top_search_results: generateTopSearchResults(searchResults),
    sources:
      sourcesIndex !== -1
        ? answer.slice(sourcesIndex + "sources:".length).trim()
        : [],
    timers,
    user,
    email: user?.email,
    is_proactive_answer: isProactiveAnswer,
    is_self_service_search:
      !isEmpty(cssConfig) || (!!cssConfig && "proactive_answers" in cssConfig),
    answerId
  };

  if (!limitedAnswerRetention) {
    data.answer_generated = answer;
  }

  dataLayerPush({
    event: GTM_EVENTS.GTM_QUESTION_ASKED,
    data: data
  });
};

export const responseToDisplayAnswer = (
  answer: ApiAnswer
): AnswerQuestion[] => {
  const answerQuestions: AnswerQuestion[] = [];
  if (answer) {
    const { questions } = answer;

    questions.forEach((question: ApiQuestion) => {
      if (question.status === ApiChatStatus.ERROR) {
        answerQuestions.push({
          query: question.query,
          actions: [
            {
              query: "",
              answer: "**<Error.>**",
              newSearches: [],
              status: question.status
            }
          ]
        });
      } else {
        const { actions } = question;
        const answerActions: AnswerAction[] = [];

        actions.forEach((action: ApiAction) => {
          const answerText = actionToString(action);

          answerActions.push({
            query: action.query,
            answer: answerText,
            newSearches: action.content.new_searches ?? [],
            status: action.status
          });
        });

        answerQuestions.push({
          query: question.query,
          actions: answerActions
        });
      }
    });
  }

  return answerQuestions;
};
export const actionToString = (action: ApiAction): string => {
  const { content, status } = action;
  const done = status === "SUCCESS" || status === "ERROR";
  let displayAnswer = "";
  if (typeof content === "undefined") {
  } else if (typeof content === "string") {
    displayAnswer = content + (done ? "" : loadingIndicator);
  } else {
    if (content.text) {
      displayAnswer += content.text;
      displayAnswer += !done ? loadingIndicator : "";
    } else {
      if (content.summary) {
        displayAnswer += `### Summary\n\n${content.summary}`;
        displayAnswer += !done && !content.details ? loadingIndicator : "\n\n";
      }
      if (content.details) {
        displayAnswer += `### Details\n\n${content.details}`;
        displayAnswer += !done && !content.sources ? loadingIndicator : "\n\n";
      }
      if (content.sources && content.sources.length > 0) {
        displayAnswer += `### Sources\n\n`;
        if (done) {
          for (let i = 0; i < content.sources.length; i++) {
            const source = content.sources[i];
            const pages = source.pages ?? [];
            const pagesStr = pages
              .filter((p: number | string | undefined) => p)
              .map((p: number | string) => p.toString())
              .join(", ");
            const pluralSuffix = pages.length > 1 ? "s" : "";
            const title = pagesStr
              ? `${source.title} (page${pluralSuffix} ${pagesStr})`
              : source.title;
            displayAnswer += `${i + 1}. [${title}](${source.url})\n`;
          }
        } else {
          displayAnswer += "*Loading sources...*\n";
        }
      }
    }
  }
  return displayAnswer;
};
export const loadingIndicator = "⎢";
