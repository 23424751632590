import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import SearchSkeletonLoader from "@aptedge/lib-ui/src/components/SearchSkeletonLoader/SearchSkeletonLoader";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { AnswerQuestion } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  ICompositeResult,
  SearchResultSource
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { FC } from "react";
import WithLoading from "../../WithLoading/WithLoading";
import { GlobalSearchItem } from "../GlobalSearchItem/GlobalSearchItem";
import "./GlobalSearchList.scss";
import { IUseArrowNavigationSL } from "./hooks/useArrowNavigationSL";

const shouldFirstCardHighlighted = (
  resultId: string | number,
  idx: number,
  highlighted: number,
  selectedSearchResult: ICompositeResult
): boolean => {
  return highlighted === idx || selectedSearchResult?.id === resultId;
};

interface Props {
  arrowNavigation: Omit<IUseArrowNavigationSL, "searchContainerRef">;
  searchResultSource: SearchResultSource;
  maxResultLength: number;
  searchResultByQuestion?: ICompositeResult[];
  question?: AnswerQuestion;
}

const GlobalSearchList: FC<Props> = ({
  arrowNavigation,
  searchResultSource,
  maxResultLength,
  searchResultByQuestion,
  question
}) => {
  const {
    getListBoxProps,
    getListItemProps,
    listBoxRef,
    highlighted,
    recentSearches
  } = arrowNavigation;

  const {
    searchQuery,
    searchResults,
    selectedSearchResult,
    isSearchLoading
  } = useAppSelector((state) => state.search);

  const { flags } = useFlags();

  // check if searchResultByQuestion is present and searchResults from redux also present
  // if yes, then show searchResults from searchResultsByQuestion
  // else show searchResults from redux

  const combinedSearchResults = isEmpty(searchResultByQuestion)
    ? searchResults
    : searchResultByQuestion || [];

  return (
    <div
      ref={listBoxRef}
      id="global-search-results"
      {...getListBoxProps()}
      className={classNames("global-search-results", {
        "unified-global-search-results":
          flags.unifiedUi &&
          searchResultSource === SearchResultSource.SEARCH_DRAWER
      })}
    >
      {searchQuery || isSearchLoading || Boolean(searchResults.length) ? (
        <WithLoading
          isLoading={isSearchLoading}
          fallback={<SearchSkeletonLoader lines={5} flags={flags} />}
        >
          {Boolean(searchResults.length) ? (
            [null, ...combinedSearchResults.slice(0, maxResultLength)].map(
              (result, idx) => {
                if (result === null) {
                  return <div key={idx} />;
                } else {
                  return (
                    <GlobalSearchItem
                      result={result}
                      key={result.id}
                      isSelected={shouldFirstCardHighlighted(
                        result.id,
                        idx,
                        highlighted,
                        selectedSearchResult
                      )}
                      {...getListItemProps(idx, question)}
                    />
                  );
                }
              }
            )
          ) : (
            <NoData message={NoDataMessage.SEARCH_RESULTS} />
          )}
        </WithLoading>
      ) : (
        <WithLoading
          isLoading={isSearchLoading}
          fallback={<SearchSkeletonLoader lines={5} flags={flags} />}
        >
          {!!recentSearches?.length ? (
            [null, ...recentSearches]?.map((result, idx) => {
              if (result === null) {
                return <div key={idx} />;
              } else {
                return (
                  <GlobalSearchItem
                    result={result}
                    key={`${result.type}:${result.id}`}
                    isSelected={shouldFirstCardHighlighted(
                      result.id,
                      idx,
                      highlighted,
                      selectedSearchResult
                    )}
                    {...getListItemProps(idx)}
                  />
                );
              }
            })
          ) : (
            <NoData message={NoDataMessage.NO_RECENT_SEARCHRES} />
          )}
        </WithLoading>
      )}
    </div>
  );
};

export { GlobalSearchList };
