import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswer } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { updateAnswerId } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { FiltersType } from "@aptedge/lib-ui/src/redux/reduxSlice/useSearchReducer";
import { generateUniqueId } from "@aptedge/lib-ui/src/utils/utils";
import { isEqual } from "lodash";
import { FC, useEffect } from "react";
import {
  AptAnswersContextType,
  AptAnswersSearchContextItem,
  QUERY_PARAMS
} from "../../../../src/types/entities";
import { useFilters } from "../FiltersContext";
import useAptAnswerFilter from "../useAptAnswerFilter";
import { AptAnswerFilterMenu } from "./AptAnswerFilterMenu";

export const QuickFilters: FC = () => {
  const { isEmpty, isError, isLoading, items } = useAptAnswerFilter(
    AptAnswersContextType.QUICK_FILTER
  );
  const { state, dispatch: filterDispatch } = useFilters();

  const dispatch = useAppDispatch();
  const { history, queryParams } = useQueryParams();
  const { quickFilters, searchQuery } = useAppSelector((state) => state.search);
  const selectedFilterIds = state.quickFilter.map((qf) => qf.id);

  const addAnswerId = (): void => {
    const answerId = generateUniqueId();
    queryParams.set(QUERY_PARAMS.ANSWER_ID, answerId);
    dispatch(updateAnswer([]));
    dispatch(updateAnswerId(answerId));
    history.push(`?${queryParams.toString()}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleItemClick = (filterItem: any): void => {
    const quickFilterItem = items.find((item) => item.id === filterItem.id);
    if (!quickFilterItem) {
      return;
    }
    const updatedQuickFilters = selectedFilterIds.includes(filterItem.id)
      ? state.quickFilter.filter((qf) => qf.id !== filterItem.id)
      : [...state.quickFilter, quickFilterItem];
    if (
      searchQuery &&
      !isEqual(state.quickFilter.length, quickFilters.length)
    ) {
      addAnswerId();
    }
    filterDispatch({
      type: FiltersType.SET_QUICK_FILTER,
      payload: updatedQuickFilters
    });
  };

  const handleSelectAll = (): void => {
    const updatedQuickFilters =
      state.quickFilter.length === items.length ? [] : items;
    if (
      searchQuery &&
      !isEqual(state.quickFilter.length, quickFilters.length)
    ) {
      addAnswerId();
    }
    filterDispatch({
      type: FiltersType.SET_QUICK_FILTER,
      payload: updatedQuickFilters
    });
  };

  const handleReset = (): void => {
    if (Boolean(selectedFilterIds.length === 0)) {
      return;
    }
    if (
      searchQuery &&
      !isEqual(state.quickFilter.length, quickFilters.length)
    ) {
      addAnswerId();
    }
    filterDispatch({
      type: FiltersType.SET_QUICK_FILTER,
      payload: [] as AptAnswersSearchContextItem[]
    });
  };

  useEffect(() => {
    if (Boolean(quickFilters.length)) {
      filterDispatch({
        type: FiltersType.SET_QUICK_FILTER,
        payload: quickFilters
      });
    }
  }, [quickFilters, filterDispatch]);

  if (isEmpty || isError || isLoading) {
    return null;
  }

  return (
    <AptAnswerFilterMenu
      contextType={AptAnswersContextType.QUICK_FILTER}
      items={items}
      enableSelectAll={false}
      selectedItems={selectedFilterIds}
      handleItemClick={handleItemClick}
      handleSelectAll={handleSelectAll}
      handleReset={handleReset}
    />
  );
};
