import { createTheme, darken } from "@mui/material/styles";
import {
  BlueColors,
  customColors,
  GreenColors,
  OrangeColors,
  RedColors,
  SlateColors,
  VikingColors
} from "./colors";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    supportApp: true;
    f1: true;
    web: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    default: {
      main: string;
      contrastText: string;
    };
    slate: SlateColors;
    viking: VikingColors;
    red: RedColors;
    orange: OrangeColors;
    green: GreenColors;
    blue: BlueColors;
  }

  interface PaletteOptions {
    default?: {
      main: string;
      light: string;
      contrastText: string;
    };
    slate?: SlateColors;
    viking?: VikingColors;
    red?: RedColors;
    orange?: OrangeColors;
    green?: GreenColors;
    blue?: BlueColors;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    default: true;
  }
}

const theme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: `"Inter", "Helvetica", "Arial", sans-serif`,
    fontSize: 14
  },
  palette: {
    primary: {
      main: "#379AA5",
      light: "#2A767E",
      contrastText: "#FFF"
    },
    secondary: {
      main: "#6E7F99",
      light: "#F6F7F9",
      contrastText: "#48566B"
    },
    default: {
      main: "#FFFFFF",
      light: "#F6F7F9",
      contrastText: "#48566B"
    },
    background: {
      paper: "#f6f7f9"
    },
    divider: "#e5e5e5",
    ...customColors
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shadows: Array(25).fill("none") as any,
  breakpoints: {
    values: {
      supportApp: 320,
      f1: 480,
      web: 1024
    } as const
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: "secondary"
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          const { color } = ownerState;
          const colorKey =
            color && color !== "inherit" && color in theme.palette
              ? color
              : "default";

          const baseStyle = {
            textTransform: "unset" as const
          };
          if (ownerState.variant === "text") {
            return {
              ...baseStyle,
              "&:hover, &:active": {
                color: darken(theme.palette[colorKey].main, 0.3),
                backgroundColor: "transparent"
              }
            };
          }
          if (ownerState.color === "default") {
            return {
              ...baseStyle,
              "&:hover, &:active": {
                backgroundColor: theme.palette.slate.slate10
              }
            };
          }

          return baseStyle;
        }
      }
    }
  }
});

export default theme;
