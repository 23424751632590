/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";

interface FeatureFlagRouteProps extends RouteProps {
  flag: boolean;
  enabledComponent:
    | React.ComponentType<RouteComponentProps<any>> // Copied this type from react-router-dom. Please ignore any
    | React.ComponentType<any>;
  disabledComponent:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const FeatureFlagRoute: FC<FeatureFlagRouteProps> = ({
  flag,
  enabledComponent: EnabledComponent,
  disabledComponent: DisabledComponent,
  ...routeProps
}) => {
  return (
    <Route
      {...routeProps}
      component={flag ? EnabledComponent : DisabledComponent}
    />
  );
};
