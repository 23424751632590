import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { LightTooltip } from "@aptedge/lib-ui/src/components/Tooltip/LightTooltip";
import {
  ArticleTicketInfo,
  GeneratedKnowledgeTicketId
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { DateTime } from "luxon";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { WebCacheKey } from "../../clients/cache";
import { deleteArticle } from "../../clients/GeneratedKnowledge/deleteArticle";
import styles from "./TicketsTableItem.module.scss";

interface TicketsTableItemProps {
  ticket: ArticleTicketInfo;
  idx: number;
}

const TicketsTableItem: React.FC<TicketsTableItemProps> = ({ ticket, idx }) => {
  const queryClient = useQueryClient();

  const deleteArticleMutation = useMutation(deleteArticle, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        WebCacheKey.GENERATED_KNOWLEDGE_UNPUBLISHED
      );
    }
  });

  const handleDeleteArticles = (): void => {
    ticket.articleIds.forEach((articleId) => {
      deleteArticleMutation.mutate({ articleId });
    });
  };

  const handleLink = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
  };

  const isEven = idx % 2 === 0;
  const { ticket_id: ticketId, display_id: displayId } =
    ticket.id || ({} as GeneratedKnowledgeTicketId);

  return (
    <div
      className={classNames(
        styles.container,
        isEven ? styles.containerEven : styles.containerOdd
      )}
    >
      <div
        className={classNames(styles.headerItem, styles.ticketItem, {
          [styles.disable]: !Boolean(ticketId || displayId)
        })}
      >
        <Link
          to={`/tickets/${ticketId}`}
          className={classNames({
            [styles.disableLink]: !Boolean(ticketId)
          })}
          onClick={handleLink}
        >
          {Boolean(displayId) ? `Ticket #${displayId}` : "-"}
        </Link>
      </div>
      <div className={classNames(styles.headerItem, styles.articleItem)}>
        {ticket.articleCount}
      </div>
      <div className={classNames(styles.headerItem, styles.dateItem)}>
        {DateTime.fromSeconds(ticket.createdOn).toFormat("dd MMM yyyy")}
      </div>
      <div className={classNames(styles.headerItem, styles.authorItem)}>
        {ticket.createdBy}
      </div>
      <div className={classNames(styles.headerItem, styles.actionsItem)}>
        <Link to={`/articles/review?ticketId=${ticketId}`}>
          <span className={styles.completeReviewAction}>Complete Review</span>
        </Link>
        <LightTooltip title="Delete" placement="top" arrow>
          <button onClick={handleDeleteArticles}>
            <AeIcons.DeleteIcon className={styles.deleteIcon} />
          </button>
        </LightTooltip>
      </div>
    </div>
  );
};

export { TicketsTableItem };
