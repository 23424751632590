import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { List } from "@aptedge/lib-ui/src/components/List/List";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Spacer } from "@aptedge/lib-ui/src/components/Spacer/Spacer";
import { Search } from "@aptedge/lib-ui/src/features/Search/Search";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  IAdminPasswordResetResponse,
  IUserListData,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import React from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { ToastType } from "react-toastify";
import { fetchUsersList } from "../../../clients/AdminDashboard/fetchUsersList";
import { resetPassword } from "../../../clients/AdminDashboard/resetPassword";
import { updateUser } from "../../../clients/AdminDashboard/updateUser";
import { WebCacheKey } from "../../../clients/cache";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { PaginationBar } from "../../../components/PaginationBar/PaginationBar";
import { Toast } from "../../../components/Toast/Toast";
import { ClientStatus } from "../../../types/clients";
import { UserListItem } from "./UserListItem";
import "./AdminDashboard.scss";

const ADMIN_LIST_HEADER = {
  avatar: "Avatar",
  email: "Email",
  role: "Role",
  enabled: "Enabled",
  resetPassword: "Reset password"
};

const PER_PAGE_OPTIONS = [20, 50, 100];

const AdminDashboard = (): React.ReactElement => {
  const [perPage, setPerPage] = React.useState(PER_PAGE_OPTIONS[0]);
  const [page, setPage] = React.useState<number>(1);

  const { searchQuery } = useAppSelector((state) => state.search);
  const [clientSStatus, setClientStatus] = React.useState<ClientStatus>(
    ClientStatus.PROMPT
  );
  const [toastMsg, setToastMsg] = React.useState<string>("");
  const [
    selectedUpdateUser,
    setSelectedUpdateUser
  ] = React.useState<IUserListData | null>(null);
  const [selectedUserRole, setSelectedUserRole] = React.useState<
    UserRole | undefined
  >();
  const [selectedUserBanned, setSelectedUserBanned] = React.useState<
    boolean | undefined
  >();
  const [resetPasswordClicked, setResetPasswordClicked] = React.useState<
    boolean
  >(false);
  const [resetPasswordUrl, setResetPasswordUrl] = React.useState<
    string | undefined
  >();

  const resetState = (): void => {
    setSelectedUpdateUser(null);
    setSelectedUserRole(undefined);
    setSelectedUserBanned(undefined);
    setResetPasswordUrl(undefined);
    setResetPasswordClicked(false);
  };

  const fetchUsersListQuery = useQuery(
    [WebCacheKey.USER_LIST, searchQuery, page, perPage],
    () =>
      fetchUsersList({
        search: searchQuery,
        page: page,
        paginate: "True",
        "per-page": perPage
      }),
    { enabled: true }
  );
  const isLoading = fetchUsersListQuery.isLoading;
  const usersList = fetchUsersListQuery?.data ?? null;
  const updateUserData = useMutation(updateUser, {
    onError: () => {
      setToastMsg("Unable to update user details. Something went wrong.");
      setClientStatus(ClientStatus.ERROR);
      resetState();
    },
    onSuccess: () => {
      setToastMsg("User updated successfully.");
      setClientStatus(ClientStatus.SUCCESS);
      resetState();
      fetchUsersListQuery.refetch();
    }
  });

  const handleSelectUserRole = (val: IUserListData, role: UserRole): void => {
    setToastMsg("");
    setSelectedUpdateUser(val);
    setSelectedUserRole(role);
  };

  const handleSetUserBanned = (val: IUserListData, banned: boolean): void => {
    setToastMsg("");
    setSelectedUpdateUser(val);
    setSelectedUserBanned(banned);
  };

  const handleResetPasswordClicked = (val: IUserListData): void => {
    setToastMsg("");
    setSelectedUpdateUser(val);
    setResetPasswordClicked(true);
  };

  const handleUserState = (): void => {
    const isAdminSelected = selectedUserRole === UserRole.ADMIN ? true : false;
    const admin = selectedUserRole === undefined ? undefined : isAdminSelected;

    setClientStatus(ClientStatus.LOADING);
    selectedUpdateUser?.id &&
      updateUserData.mutate({
        userId: selectedUpdateUser?.id,
        patchContent: {
          admin,
          banned: selectedUserBanned,
          role: selectedUserRole
        }
      });
    dataLayerPush({ event: GTM_EVENTS.GTM_ARCHIVE_EDGE });
  };

  const doResetPassword = (): void => {
    if (selectedUpdateUser) {
      resetPassword({ userId: selectedUpdateUser?.id }).then(
        (response: IAdminPasswordResetResponse) => {
          setResetPasswordUrl(response.url);
        },
        () => {
          setToastMsg("Unable to reset password. Something went wrong.");
          setClientStatus(ClientStatus.ERROR);
          resetState();
        }
      );
    }
  };

  return (
    <div>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <div className="user-list-header-section">
        <Search className="user-search" placeholder="Search for user" />
        <div
          className="per-page-container"
          onClick={(e) => e.stopPropagation()}
          role="button"
        >
          <Dropdown.Container className="options">
            <Dropdown.Toggle>
              <span className="per-page-header">
                Results per page: {perPage} <Icon icon={faCaretDown} />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {PER_PAGE_OPTIONS.map((val: number) => (
                <Dropdown.Item key={val} onClick={() => setPerPage(val)}>
                  <span>{val}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Container>
        </div>
      </div>

      <div className="edges-content">
        <div className="container-xxl">
          <List>
            <div className="user-list user-list-header">
              <div className="user-header-txt">{ADMIN_LIST_HEADER.avatar}</div>
              <div className="user-header-txt">{ADMIN_LIST_HEADER.email}</div>
              <div className="user-header-txt">{ADMIN_LIST_HEADER.role}</div>
              <div className="user-header-txt">{ADMIN_LIST_HEADER.enabled}</div>
              <div className="user-header-txt">
                {ADMIN_LIST_HEADER.resetPassword}
              </div>
            </div>
            {usersList?.items?.length
              ? usersList?.items?.map(
                  (user: IUserListData, index: number): JSX.Element | null => (
                    <UserListItem
                      key={user.id}
                      user={user}
                      index={index}
                      onBanUser={(banned: boolean) =>
                        handleSetUserBanned(user, banned)
                      }
                      onRoleSelected={(role) =>
                        handleSelectUserRole(user, role)
                      }
                      onResetPassword={() => {
                        handleResetPasswordClicked(user);
                      }}
                    />
                  )
                )
              : !isLoading && <NoData message={NoDataMessage.NO_USERS_FOUND} />}
          </List>
          {usersList?.items && (
            <PaginationBar
              className="mt-3"
              page={usersList?.page}
              totalPages={usersList?.totalPages}
              onChange={(val) => setPage(val?.page ?? 1)}
            />
          )}
        </div>
      </div>

      {fetchUsersListQuery.error && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}

      {toastMsg && <Toast type={ToastType.DEFAULT}>{toastMsg}</Toast>}

      <ConfirmationModal
        title="Update user"
        status={clientSStatus}
        isOpen={Boolean(selectedUpdateUser) && !resetPasswordClicked}
        onClose={resetState}
      >
        {selectedUserBanned !== undefined ? (
          <p>
            {`Are you sure you want to ${
              selectedUserBanned ? "disable" : "enable"
            } the user ${selectedUpdateUser?.email}?`}
          </p>
        ) : (
          <p>
            Are you sure you want to update the user {selectedUpdateUser?.email}{" "}
            from {selectedUpdateUser?.role} to {selectedUserRole}?
          </p>
        )}
        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button color="secondary" onClick={resetState}>
                  Cancel
                </Button>
                <Button data-testid="delete button" onClick={handleUserState}>
                  Update
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>

      <ConfirmationModal
        title="Password reset"
        status={clientSStatus}
        isOpen={
          Boolean(selectedUpdateUser) &&
          resetPasswordClicked &&
          !resetPasswordUrl
        }
        onClose={resetState}
      >
        <p>
          {`Are you sure you want reset the password of the user ${selectedUpdateUser?.email}?`}
        </p>
        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button color="secondary" onClick={resetState}>
                  Cancel
                </Button>
                <Button data-testid="delete button" onClick={doResetPassword}>
                  Reset
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>

      <ConfirmationModal
        title="Password reset"
        status={clientSStatus}
        isOpen={Boolean(resetPasswordUrl)}
        onClose={resetState}
      >
        <p>
          A link to reset {selectedUpdateUser?.email}&apos;s password was sent
          to their email address. Their password must be reset before they can
          log in again.
        </p>
        <p>
          You may also share the link:
          <br />
          <code>{resetPasswordUrl}</code>
          <br />
          with them directly. Be warned that anyone with this link will be able
          to set the user&apos;s password&mdash;please keep it private and
          secure.
        </p>
        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button color="secondary" onClick={resetState}>
                  Close
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>
    </div>
  );
};
export { AdminDashboard };
