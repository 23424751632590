import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswer } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  updateAnswerId,
  updateProductFilters
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  AptAnswersContextType,
  AptAnswersSearchContextItem,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import { FC, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAptAnswersSearchContext } from "../../../hooks/clients/useAptAnswersSearchContext";
import { SearchFilterMenuRoot } from "./components/SearchFilterMenuRoot";

const ProductFiltersMenu: FC = () => {
  const {
    productFiltersIsEmpty: isEmpty,
    productFiltersIsError: isError,
    isLoading,
    productFiltersItems: items
  } = useAptAnswersSearchContext();

  const dispatch = useAppDispatch();
  const { history, queryParams } = useQueryParams();
  const { myProducts, productFilters } = useAppSelector(
    (state) => state.search
  );
  const selectedFilterIds = productFilters.map((pf) => pf.id);

  const addAnswerId = (): void => {
    const answerId = uuidv4();
    queryParams.set(QUERY_PARAMS.ANSWER_ID, answerId);
    dispatch(updateAnswer([]));
    dispatch(updateAnswerId(answerId));
    history.push(`?${queryParams.toString()}`);
  };

  const handleItemClick = (id: string): void => {
    const productFilterItem = items.find((item) => item.id === id);
    if (!productFilterItem) {
      return;
    }
    const updatedProductFilters = selectedFilterIds.includes(id)
      ? productFilters.filter((pf) => pf.id !== id)
      : [...productFilters, productFilterItem];
    addAnswerId();
    dispatch(updateProductFilters(updatedProductFilters));
  };

  const handleSelectAll = (): void => {
    const updatedProductFilters =
      productFilters.length === items.length ? [] : items;
    addAnswerId();
    dispatch(updateProductFilters(updatedProductFilters));
  };

  const handleReset = (): void => {
    if (Boolean(selectedFilterIds.length === 0)) {
      return;
    }
    const updatedProductFilters: AptAnswersSearchContextItem[] = [];
    addAnswerId();
    dispatch(updateProductFilters(updatedProductFilters));
  };

  useEffect(() => {
    if (myProducts.length) {
      dispatch(updateProductFilters(myProducts));
    }
  }, [myProducts, dispatch]);

  if (isEmpty || isError || isLoading) {
    return null;
  }

  return (
    <div>
      <SearchFilterMenuRoot
        contextType={AptAnswersContextType.PRODUCT_FILTER}
        label="Products"
        icon={<AeIcons.ProductFiltersMenu />}
        count={productFilters.length}
        items={items}
        enableSelectAll={true}
        selectedItems={selectedFilterIds}
        handleItemClick={handleItemClick}
        handleSelectAll={handleSelectAll}
        handleReset={handleReset}
      />
    </div>
  );
};

export { ProductFiltersMenu };
