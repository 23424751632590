import {
  AptAnswersSearchContextItem,
  ISearchFilter,
  SearchFilterSubType
} from "../../types/entities";

export enum FiltersType {
  SET_FILTER = "set_filter",
  SET_SUB_FILTER = "set_sub_filter",
  SET_PRODUCT_FILTER = "set_product_filter",
  SET_QUICK_FILTER = "set_quick_filter"
}

export interface FiltersState {
  filter: ISearchFilter;
  subFilter: SearchFilterSubType;
  productFilter: AptAnswersSearchContextItem[];
  quickFilter: AptAnswersSearchContextItem[];
}

export type FiltersAction =
  | { type: FiltersType.SET_FILTER; payload: ISearchFilter }
  | { type: FiltersType.SET_SUB_FILTER; payload: SearchFilterSubType }
  | {
      type: FiltersType.SET_PRODUCT_FILTER;
      payload: AptAnswersSearchContextItem[];
    }
  | {
      type: FiltersType.SET_QUICK_FILTER;
      payload: AptAnswersSearchContextItem[];
    };

export const initialState: FiltersState = {
  filter: ISearchFilter.ALL,
  subFilter: "",
  productFilter: [],
  quickFilter: []
};

export const filtersReducer = (
  state: FiltersState,
  action: FiltersAction
): FiltersState => {
  switch (action.type) {
    case FiltersType.SET_FILTER:
      return { ...state, filter: action.payload };
    case FiltersType.SET_SUB_FILTER:
      return { ...state, subFilter: action.payload };
    case FiltersType.SET_PRODUCT_FILTER:
      return { ...state, productFilter: action.payload };
    case FiltersType.SET_QUICK_FILTER:
      return { ...state, quickFilter: action.payload };
    default:
      return state;
  }
};
