export const fetchSessionReplayData = async (
  blobUrl?: string
): Promise<string | undefined> => {
  if (!blobUrl) {
    throw new Error("Blob URL is required.");
  }
  const res = await fetch(blobUrl, {
    method: "GET"
  });

  if (!res.ok) {
    throw new Error("Failed to fetch session replay data.");
  }

  return res.json();
};
