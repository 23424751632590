import {
  ICreateSharepointPagesSourceRequest,
  ISharepointPagesSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createSharepointPagesSource(
  args: ICreateSharepointPagesSourceRequest
): Promise<ISharepointPagesSource> {
  const url = `${getBackendUrl()}/api/integrations/sharepoint-pages`;
  const options: RequestInit = post(args);
  return request(url, options);
}

export { createSharepointPagesSource };
