import IOSSwitch from "@aptedge/lib-ui/src/components/IOSSwitch/IOSSwitch";
import { FC } from "react";
import NotificationBlock from "../../components/NotificationBlock/NotificationBlock";
import SessionPreview from "./SessionPreview";
import styles from "./styles.module.scss";
import { useSessionReplay } from "./useSessionReplay";

export const SessionReplay: FC = () => {
  const {
    cssConfig,
    isError,
    isFormSubmiting,
    updateError,
    isFormSubmitted,
    handleSumit,
    formRef,
    onTextChange,
    onSessionInfoSharedChange,
    isSessionInfoShared,
    flags,
    user
  } = useSessionReplay();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>{cssConfig?.ticket_creation?.link_text}</h1>
      </div>

      <NotificationBlock
        text={{
          error: "Could not submit ticket, please try again",
          success: "Ticket submitted successfully!"
        }}
        isError={isError}
        updateError={updateError}
        className={styles.notificationBlock}
        isFormSubmitted={isFormSubmitted}
      />

      <form onSubmit={handleSumit} ref={formRef}>
        <div className={styles.form}>
          <div className={styles.field}>
            <label htmlFor="">Email</label>
            <input
              type="email"
              defaultValue={user?.email}
              name="requesterEmail"
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="">Subject</label>
            <input type="text" name="subject" />
          </div>

          <div className={styles.field}>
            <label htmlFor="">Details</label>
            <textarea
              name="description"
              rows={4}
              placeholder="Describe the issue you’re facing"
              onChange={onTextChange}
            />
          </div>

          <div className={styles.switch}>
            <IOSSwitch
              onChange={onSessionInfoSharedChange}
              checked={isSessionInfoShared}
            />
            <p>Share session information</p>
          </div>

          <button type="submit" disabled={isFormSubmiting}>
            {!isFormSubmiting ? "Submit" : "Submitting"}
          </button>
        </div>
      </form>
      <div className={styles.preview}>
        {isSessionInfoShared && flags.f1SessionReplayPreview && (
          <SessionPreview className={styles.sessionReplayPreviewContainer} />
        )}
      </div>
    </div>
  );
};
